import "./FooterContainer.css";
import Select from "react-select";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "../../State";
import apiClient from "../../services/http-common";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import { Link, useHistory } from "react-router-dom";
import logger from "../../Utils/logger";
interface ContainerProps {
  onClickScrollToTop: any;
}

type LanguageListItems = {
  id: number;
  language: string;
  lang_code: string;
  createdAt: string;
  updatedAt: string;
}[];

type FooterContents = {
  provider: string;
  legalNotices: string;
  cookies: string;
  privacy: string;
  contact: string;
  conformityDeclaration: string;
  toTheTop: string;
};

type SelectLanguageItems = {
  value: number;
  label: string;
}[];

const reactSelectStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    borderRadius: 0,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
};

const initialLanguageListItems: LanguageListItems = [
  {
    id: 1,
    language: "English",
    lang_code: "en_GB",
    createdAt: "2022-05-18T07:33:02.591Z",
    updatedAt: "2022-05-18T07:33:02.591Z",
  },
  {
    id: 2,
    language: "Deutsch",
    lang_code: "de_DE",
    createdAt: "2022-05-18T07:33:02.591Z",
    updatedAt: "2022-05-18T07:33:02.591Z",
  },
];

const initialFooterContent: FooterContents = {
  provider: "Provider",
  legalNotices: "Legal Notice",
  cookies: "Cookies",
  privacy: "Privacy",
  contact: "Contact",
  conformityDeclaration: "Declaration of Conformity/FOSS",
  toTheTop: "To the top",
};

const Languages = [{ label: "English (GB)", value: 0 }];

const FooterContainer: React.FC<ContainerProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);
  var history = useHistory();
  var refLink = "https://www.mercedes-benz-trucks.com/";

  useEffect(() => {
    if (state.isAppLangChanged == false) {
      checkBrowserLanguage();
    }
    getLanguageDetails();
  }, [state.isAppLangChanged == false]);

  useEffect(() => {
    getFooterTranslations();
  }, [state.selectedLanguageCode]);

  const [languages, setLanguages]: [
    SelectLanguageItems,
    Dispatch<SetStateAction<SelectLanguageItems>>
  ] = useState(Languages);

  const [footerStrings, setFooterStrings]: [
    FooterContents,
    Dispatch<SetStateAction<FooterContents>>
  ] = useState(initialFooterContent);

  const [languageResponse, setLanguageResponse]: [
    LanguageListItems,
    Dispatch<SetStateAction<LanguageListItems>>
  ] = useState(initialLanguageListItems);

  function checkBrowserLanguage() {
    let browserLang: string = navigator.language;
    var initialLang: any;
    var langCode: string;
    if (browserLang.includes("de")) {
      initialLang = {
        value: 2,
        label: "Deutsch",
        isDisabled: false,
      };
      langCode = "de_DE";
    } else {
      initialLang = {
        value: 1,
        label: "English",
        isDisabled: false,
      };
      langCode = "en_GB";
    }
    dispatch({
      type: "setSelectedLanguageModel",
      payload: {
        selectedLanguageModel: initialLang,
      },
    });
    dispatch({
      type: "setSelectedLanguageCode",
      payload: {
        selectedLanguageCode: langCode,
      },
    });
    localStorage.setItem("languageModelObject", JSON.stringify(initialLang));
    localStorage.setItem("langCode", langCode);
  }

  function getLanguageDetails() {
    var data = initialLanguageListItems;
    var langItems: SelectLanguageItems;
    langItems = data.map((d, index: number) => ({
      value: index,
      label: d.language,
    }));
    setLanguages(langItems);
    setLanguageResponse(data);
  }

  const getFooterTranslations = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Provider%7C$$Legal_Notices%7C$$Cookies%7C$$Privacy%7C$$Contact%7C$$To_the_top%7C$$Declaration_Conformity`
      );
      data = result.data?.footerDetails;
      var footerDetails = footerStrings;
      data?.map((items) => {
        if (items.Variable_Name == "$$Cookies") {
          footerDetails.cookies = items.translated_text;
        } else if (items.Variable_Name == "$$Provider") {
          footerDetails.provider = items.translated_text;
        } else if (items.Variable_Name == "$$Legal_Notices") {
          footerDetails.legalNotices = items.translated_text;
        } else if (items.Variable_Name == "$$Privacy") {
          footerDetails.privacy = items.translated_text;
        } else if (items.Variable_Name == "$$To_the_top") {
          footerDetails.toTheTop = items.translated_text;
        } else if (items.Variable_Name == "$$Declaration_Conformity") {
          footerDetails.conformityDeclaration = items.translated_text;
        } else {
          footerDetails.contact = items.translated_text;
        }
      });
      setFooterStrings({ ...footerDetails });
      logger.info(`Footer API result`, data);
    } catch (err: any) {
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
      logger.info(`Footer Translation API ERROR ${err}`);
    }
  };

  const handleChange = (newValue: any, actionMeta: { action: any }) => {
    logger.info(`action: ${actionMeta.action}`);
    var langCode = languageResponse[newValue.value].lang_code;
    var index: number = newValue.value;
    var currentLang = {
      value: index,
      label: languageResponse[newValue.value].language,
    };
    dispatch({
      type: "setSelectedLanguageModel",
      payload: {
        selectedLanguageModel: currentLang,
      },
    });
    dispatch({
      type: "setIsAppLangChanged",
      payload: {
        isAppLangChanged: true,
      },
    });
    localStorage.setItem("isAppLangChanged", "true");
    localStorage.setItem("languageModelObject", JSON.stringify(currentLang));
    logger.info(`Language Code selected ---- ${langCode}`);
    logger.info(`Saved language model: `, currentLang);
    localStorage.setItem("langCode", langCode);
    dispatch({
      type: "setSelectedLanguageCode",
      payload: {
        selectedLanguageCode: langCode,
      },
    });
  };

  function getLinkContainer() {
    return (
      <div className="footer-center-container">
        <ul className="footer-link-container">
          <li className="footer-link-item">
            <a className="footer-link-element" href={refLink} target="_blank">
              {footerStrings.provider}
            </a>
            <span className="footer-link-item-separator"></span>
          </li>
          <li className="footer-link-item">
            <a className="footer-link-element" href={refLink} target="_blank">
              {footerStrings.legalNotices}
            </a>
            <span className="footer-link-item-separator"></span>
          </li>
          <li className="footer-link-item">
            <a className="footer-link-element" href={refLink} target="_blank">
              {footerStrings.cookies}
            </a>
            <span className="footer-link-item-separator"></span>
          </li>
          <li className="footer-link-item">
            <a className="footer-link-element" href={refLink} target="_blank">
              {footerStrings.privacy}
            </a>
            <span className="footer-link-item-separator"></span>
          </li>
          <li className="footer-link-item">
            <a className="footer-link-element" href={refLink} target="_blank">
              {footerStrings.contact}
            </a>
            <span className="footer-link-item-separator"></span>
          </li>
          <li className="footer-link-item">
            <Link
              className="footer-link-element"
              target="_blank"
              to={"/truckownermanuals/conformity"}
            >
              {footerStrings.conformityDeclaration}
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="footer-parent-container">
      <PhoneBreakpoint>
        <div className="footer-right-container">
          <a
            className="back-to-top"
            onClick={() => {
              props.onClickScrollToTop();
            }}
          >
            {footerStrings.toTheTop}
          </a>
        </div>
        <div className="">
          {/* ----------------------------------------------------- */}
        </div>
      </PhoneBreakpoint>
      <div className="footer-left-container">
        <Select
          className="select-custom-class"
          menuPlacement="top"
          placeholder="English"
          isSearchable={false}
          onChange={handleChange}
          options={languages}
          value={state.selectedLanguageModel}
          styles={reactSelectStyle}
        />
      </div>
      <PhoneBreakpoint>
        <div className="footer-center-container">
          <ul className="footer-link-container">
            <li className="footer-link-item">
              <a className="footer-link-element" href={refLink} target="_blank">
                {footerStrings.provider}
              </a>
              {/* <span className="footer-link-item-separator">
                ---------------------------------------------
              </span> */}
            </li>
            <li className="footer-link-item">
              <a className="footer-link-element" href={refLink} target="_blank">
                {footerStrings.legalNotices}
              </a>
              {/* <span className="footer-link-item-separator">
                ---------------------------------------------
              </span> */}
            </li>
            <li className="footer-link-item">
              <a className="footer-link-element" href={refLink} target="_blank">
                {footerStrings.cookies}
              </a>
              {/* <span className="footer-link-item-separator">
                ----------------------------------------------
              </span> */}
            </li>
            <li className="footer-link-item">
              <a className="footer-link-element" href={refLink} target="_blank">
                {footerStrings.privacy}
              </a>
              {/* <span className="footer-link-item-separator">
                -----------------------------------------------
              </span> */}
            </li>
            <li className="footer-link-item">
              <a className="footer-link-element" href={refLink} target="_blank">
                {footerStrings.contact}
              </a>
              {/* <span className="footer-link-item-separator">
                ------------------------------------------------
              </span> */}
            </li>
            <li className="footer-link-item">
              <Link
                className="footer-link-element"
                // href={refLinkForFoss}
                target="_blank"
                to={"/truckownermanuals/conformity"}
              >
                {footerStrings.conformityDeclaration}
              </Link>
            </li>
          </ul>
        </div>
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        {getLinkContainer()}
        <div className="footer-right-container">
          <a
            className="back-to-top"
            onClick={() => {
              props.onClickScrollToTop();
            }}
          >
            {footerStrings.toTheTop}
          </a>
        </div>
      </DesktopBreakpoint>
      <TabletBreakpoint>
        {getLinkContainer()}
        <div className="footer-right-container">
          <a
            className="back-to-top"
            onClick={() => {
              props.onClickScrollToTop();
            }}
          >
            {footerStrings.toTheTop}
          </a>
        </div>
      </TabletBreakpoint>
    </div>
  );
};

export default FooterContainer;
