// import React, {useEffect, useState} from "react";
// import 'bootstrap/dist/css/bootstrap.min.css'
// import './FlashMessage.css'

// interface FlashMessageProps{
//     message: string
//     duration: number
// }

// const FlashMessage: React.FC<FlashMessageProps> = ({message, duration}) => {
//     const [visible, setVisible] = useState(true)
//     const [show, setShow] = useState(true)

//     useEffect(()=>{
//         const timer = setInterval(()=>{
//             setVisible((prev)=> !prev)
//         }, duration)
//         return () => clearInterval(timer)
//     }, [duration])

//     const handleClose=()=>{
//         setShow(false)
//     }
//     if(!show){
//         return null
//     }

//     return(
//         <div className="flash-message-wrapper">
//         <div className={`flash-message alert alert-info ${visible? 'visible':''}`} role="alert">
//         <button type="button" className="btn-close close-button" aria-label="Close" onClick={handleClose}></button>
//             <div className="message-content">
//             {message}
//             </div>
//             </div>
//         </div>
//     )
// }

// export default FlashMessage;

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FlashMessage.css";

interface FlashMessageProps {
  message: string;
  duration: number;
}

const FlashMessage: React.FC<FlashMessageProps> = ({ message, duration }) => {
  const [visible, setVisible] = useState(true);
  const [show, setShow] = useState(true);

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       setVisible((prev) => !prev);
  //     }, duration);
  //     return () => clearInterval(timer);
  //   }, [duration]);

  const handleClose = () => {
    setShow(false);
  };
  if (!show) {
    return null;
  }

  return (
    visible && (
      <div className={`flash-message`}>
        <button
          type="button"
          className="btn-close close-button"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        {/* <marquee>{message}</marquee> */}
        {message}
      </div>
    )
  );
};

export default FlashMessage;