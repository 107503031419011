import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import "./LeftMenuContainer.css";
import { AppContext } from "../../State";
import apiClient from "../../services/http-common";
import Collapsible from "react-collapsible";
import { useHistory } from "react-router-dom";
import logger from "../../Utils/logger";
interface ContainerProps {
  onClickScrollToTop: any;
}

type MenuListItems = {
  id: number;
  MenuItem: string;
  order: number;
  ParentKey: number;
  createdAt: string;
  updatedAt: string;
  tblVehicleLanguageId: number;
  childItem: any;
}[];

const LeftMenuContainer: React.FC<ContainerProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);

  const scrollToTop = () => {
    props.onClickScrollToTop();
  };

  const history = useHistory();

  const initialMenuListItems: MenuListItems = [
    {
      id: 9,
      MenuItem: "",
      order: 0,
      ParentKey: 0,
      createdAt: "",
      updatedAt: "",
      tblVehicleLanguageId: 1,
      childItem: [],
    },
  ];

  const [leftMenuItems, setLeftMenuItems]: [
    MenuListItems,
    Dispatch<SetStateAction<MenuListItems>>
  ] = useState(initialMenuListItems);

  const [firstLevelOpenControlArray, setFirstLevelOpenControlArray] = useState([
    false,
  ]);

  const [secondLevelOpenControlArray, setSecondLevelOpenControlArray] =
    useState([true]);

  const [thirdLevelOpenControlArray, setThirdLevelOpenControlArray] = useState([
    true,
  ]);

  useEffect(() => {
    setLeftMenuItems(state.childItemsForSelectedID);
    logger.info(
      "Is level one item selected --- ",
      state.isLevelOneItemSelected
    );
    logger.info(
      "Saved tile ID in left menu page is ---- ",
      localStorage.getItem("tileID")
    );
    controlInitialSelectedState();
    setTimeout(function () {
      setFirstLevelOpenControlArray([true]);
    }, 800);
  }, [state.childItemsForSelectedID]);

  function getLeftMenuContents() {
    try {
      getAllMenuContents(state.selectedLanguageCode);
    } catch (err) {
      logger.info(err);
    }
  }

  const getAllMenuContents = async (langCode: String) => {
    try {
      logger.info(
        `Left Menu URL - /getMenuItems/0/${langCode}/${state.selectedVehicleName}/${state.selectedVehicleModel}`
      );

      var result: any;
      var translationLangCode = localStorage.getItem("langCode");
      if (state.isVINAvailable || state.vehicleVIN) {
        result = await apiClient.get(
          `/retrivergetMenuItems/0/0/${localStorage.getItem(
            "hmiSystemLanguage"
          )}/${state.vehicleInfoBasedOnVIN?.vehicleName}/${
            state.vehicleInfoBasedOnVIN?.vehicleModel
          }/${localStorage.getItem(
            "hmiSystemLanguage"
          ) === "de_DE" ? "de_DE" : ""}`
        );
      } else {
        result = await apiClient.get(
          `/retrivergetMenuItems/0/0/${langCode}/${state.selectedVehicleName}/${
            state.selectedVehicleModel
          }/${translationLangCode === "en_GB" ? "" : translationLangCode}`
        );
      }

      logger.info(`Menu item API result = ${result}`);
      result.data.map((item) => {
        if (item.id == state.selectedTileID) {
          setLeftMenuItems(item.childItem);
          controlInitialSelectedState();
          logger.info("Required item is", item);
        }
        logger.info("Selected Tile ID is ---", state.selectedTileID);
        logger.info("Saved left menu items are ----", leftMenuItems);
      });
      logger.info(`Left menu contents: ${result.data}`);
    } catch (err: any) {
      logger.info(`Get left menu items API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
      setLeftMenuItems(initialMenuListItems);
    }
  };

  function dispatchLeftMenuClickEventChange() {
    dispatch({
      type: "setLeftMenuClickFlag",
      payload: {
        isLeftMenuClicked: state.isLeftMenuClicked + 1,
      },
    });
  }

  function dispatchSelectedTileIndex(id: number, menuItem: String) {
    dispatch({
      type: "setSelectedLeftMenuID",
      payload: {
        selectedLeftMenuID: id,
      },
    });
    localStorage.setItem("leftMenuID", `${id}`);
    var fromLocalStrg = localStorage.getItem("leftMenuID") || 0;
    logger.info(`saved left menu id in local storage is ${fromLocalStrg}`);
    dispatchLeftMenuClickEventChange();
    dispatchLeftMenuTitle(menuItem);
    logger.info(`Selected Id -- ${id} and Selected MenuItem -- ${menuItem}`);
  }

  function dispatchLeftMenuTitle(selectedMenuTile: String) {
    dispatch({
      type: "setSelectedItemTitle",
      payload: {
        selectedLeftMenuTitle: selectedMenuTile,
      },
    });
  }

  var results: any;
  results = {
    mainMenuItem: [
      {
        id: 15,
        MenuItem: "Child Item 1",
        order: "1",
        ParentKey: "2",
        createdAt: "2022-05-23T07:02:41.124Z",
        updatedAt: "2022-05-23T07:02:41.124Z",
        tblVehicleLanguageId: 1,
        childItem: [
          {
            id: 17,
            MenuItem: "Sub-Menu Item 1",
            order: "2",
            ParentKey: "15",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [],
          },
        ],
      },
      {
        id: 16,
        MenuItem: "Child Item 2",
        order: "1",
        ParentKey: "2",
        createdAt: "2022-05-23T07:02:41.124Z",
        updatedAt: "2022-05-23T07:02:41.124Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
    ],
  };

  function arrayControl(
    level: number,
    count: number,
    index: number,
    shouldOpen: boolean,
    selectedItemArray?: any
  ) {
    var i: number;
    var tempArray = [false];
    var selectedItemList = ["", "", "", "", ""];
    for (i = 0; i < count; i++) {
      tempArray[i] = false;
    }
    if (shouldOpen) {
      tempArray[index] = true;
    }
    if (level == 1) {
      setFirstLevelOpenControlArray(tempArray);
    } else if (level == 2) {
      setSecondLevelOpenControlArray(tempArray);
    } else if (level == 3) {
      setThirdLevelOpenControlArray(tempArray);
    }

    for (i = 0; i < level; i++) {
      selectedItemList[i] = selectedItemArray[i];
    }

    var item = state.selectedLevelHierarchy;
    var startIndex = state.isLevelOneItemSelected ? 2 : 1;

    for (i = startIndex; i < 5; i++) {
      if (state.isLevelOneItemSelected) {
        item[i] = selectedItemList[i - 2];
      } else {
        item[i] = selectedItemList[i - 1];
      }
    }

    dispatch({
      type: "setSelectedLevelHierarchy",
      payload: {
        selectedLevelHierarchy: item,
      },
    });
    localStorage.setItem("selectedLevelHierarchy", JSON.stringify(item));
    logger.info(
      `level = ${level}, number of items in level ${level} = ${count} and clicked index = ${index}`
    );
  }

  function controlInitialSelectedState() {
    var firstItemID: number;
    var selectedItemList = ["", "", "", "", ""];
    var childLevelPresent: number = 0;
    if (state.childItemsForSelectedID[0].childItem.length == 0) {
      firstItemID = state.childItemsForSelectedID[0].id;
      dispatchSelectedTileIndex(
        firstItemID,
        state.childItemsForSelectedID[0].MenuItem
      );
      childLevelPresent = 1;
    } else {
      if (state.childItemsForSelectedID[0].childItem[0].childItem.length == 0) {
        firstItemID = state.childItemsForSelectedID[0].childItem[0].id;
        childLevelPresent = 2;
        dispatchSelectedTileIndex(
          firstItemID,
          state.childItemsForSelectedID[0].childItem[0].MenuItem
        );
      } else {
        if (
          state.childItemsForSelectedID[0].childItem[0].childItem[0].childItem
            .length == 0
        ) {
          firstItemID =
            state.childItemsForSelectedID[0].childItem[0].childItem[0].id;
          childLevelPresent = 3;
          dispatchSelectedTileIndex(
            firstItemID,
            state.childItemsForSelectedID[0].childItem[0].childItem[0].MenuItem
          );
        } else {
          if (
            state.childItemsForSelectedID[0].childItem[0].childItem[0]
              .childItem[0].childItem.length == 0
          ) {
            firstItemID =
              state.childItemsForSelectedID[0].childItem[0].childItem[0]
                .childItem[0].id;
            childLevelPresent = 4;
            dispatchSelectedTileIndex(
              firstItemID,
              state.childItemsForSelectedID[0].childItem[0].childItem[0]
                .childItem[0].MenuItem
            );
          } else {
            firstItemID =
              state.childItemsForSelectedID[0].childItem[0].childItem[0]
                .childItem[0].childItem[0].id;
            childLevelPresent = 5;
            dispatchSelectedTileIndex(
              firstItemID,
              state.childItemsForSelectedID[0].childItem[0].childItem[0]
                .childItem[0].childItem[0].MenuItem
            );
          }
        }
      }
    }

    if (childLevelPresent >= 1) {
      selectedItemList[0] = state.childItemsForSelectedID[0]?.MenuItem;
    }
    if (childLevelPresent >= 2) {
      selectedItemList[1] =
        state.childItemsForSelectedID[0]?.childItem[0]?.MenuItem;
    }
    if (childLevelPresent >= 3) {
      selectedItemList[2] =
        state.childItemsForSelectedID[0]?.childItem[0]?.childItem[0]?.MenuItem;
    }
    if (childLevelPresent >= 4) {
      selectedItemList[3] =
        state.childItemsForSelectedID[0]?.childItem[0]?.childItem[0]?.childItem[0]?.MenuItem;
    }
    if (childLevelPresent >= 5) {
      selectedItemList[4] =
        state.childItemsForSelectedID[0]?.childItem[0]?.childItem[0]?.childItem[0]?.childItem[0]?.MenuItem;
    }

    var i: number;
    var item = state.selectedLevelHierarchy;
    var startIndex = state.isLevelOneItemSelected ? 2 : 1;

    for (i = startIndex; i < 5; i++) {
      if (state.isLevelOneItemSelected) {
        item[i] = selectedItemList[i - 2];
      } else {
        item[i] = selectedItemList[i - 1];
      }
    }

    dispatch({
      type: "setSelectedLevelHierarchy",
      payload: {
        selectedLevelHierarchy: item,
      },
    });
    localStorage.setItem("selectedLevelHierarchy", JSON.stringify(item));
    logger.info(`First Item to be displayed is with ID --- ${firstItemID}`);
  }

  //   Call API with selected ID of Tiled-page and fetch the sub-items -->
  var results: any;
  results = {
    mainMenuItem: [
      {
        id: 1,
        MenuItem: "Level one",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.195Z",
        updatedAt: "2022-05-23T07:02:41.195Z",
        tblVehicleLanguageId: 1,
        childItem: [
          {
            id: 2,
            MenuItem: "Level Two",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.154Z",
            updatedAt: "2022-05-23T07:02:41.154Z",
            tblVehicleLanguageId: 1,
            childItem: [
              {
                id: 3,
                MenuItem: "Level 3",
                order: "0",
                ParentKey: "0",
                createdAt: "2022-05-23T07:02:41.124Z",
                updatedAt: "2022-05-23T07:02:41.124Z",
                tblVehicleLanguageId: 1,
                childItem: [
                  {
                    id: 4,
                    MenuItem: "Level 4",
                    order: "0",
                    ParentKey: "0",
                    createdAt: "2022-05-23T07:02:41.124Z",
                    updatedAt: "2022-05-23T07:02:41.124Z",
                    tblVehicleLanguageId: 1,
                    childItem: [],
                  },
                ],
              },
            ],
          },
          {
            id: 5,
            MenuItem: "Level 2 -Second Item",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [],
          },
        ],
      },
      {
        id: 6,
        MenuItem: "General notes",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.124Z",
        updatedAt: "2022-05-23T07:02:41.124Z",
        tblVehicleLanguageId: 1,
        childItem: [
          {
            id: 7,
            MenuItem: "Level 2 - First Item",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [],
          },
          {
            id: 8,
            MenuItem: "Level 2 - Second Item",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [],
          },
          {
            id: 9,
            MenuItem: "Level 2 - Third Item",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [
              {
                id: 10,
                MenuItem: "Level 3 - Second Item",
                order: "0",
                ParentKey: "0",
                createdAt: "2022-05-23T07:02:41.124Z",
                updatedAt: "2022-05-23T07:02:41.124Z",
                tblVehicleLanguageId: 1,
                childItem: [],
              },
              {
                id: 11,
                MenuItem: "Level 3 - First Item",
                order: "0",
                ParentKey: "0",
                createdAt: "2022-05-23T07:02:41.124Z",
                updatedAt: "2022-05-23T07:02:41.124Z",
                tblVehicleLanguageId: 1,
                childItem: [
                  {
                    id: 12,
                    MenuItem: "Level 4 - First Item",
                    order: "0",
                    ParentKey: "0",
                    createdAt: "2022-05-23T07:02:41.124Z",
                    updatedAt: "2022-05-23T07:02:41.124Z",
                    tblVehicleLanguageId: 1,
                    childItem: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 13,
        MenuItem: "At a glance",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.130Z",
        updatedAt: "2022-05-23T07:02:41.130Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 14,
        MenuItem: "Occupant safety",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.140Z",
        updatedAt: "2022-05-23T07:02:41.140Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 15,
        MenuItem: "Opening and closing",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.148Z",
        updatedAt: "2022-05-23T07:02:41.148Z",
        tblVehicleLanguageId: 1,
        childItem: [
          {
            id: 16,
            MenuItem: "Opening and closing first item",
            order: "0",
            ParentKey: "0",
            createdAt: "2022-05-23T07:02:41.124Z",
            updatedAt: "2022-05-23T07:02:41.124Z",
            tblVehicleLanguageId: 1,
            childItem: [],
          },
        ],
      },
      {
        id: 17,
        MenuItem: "Driver cockpit",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.154Z",
        updatedAt: "2022-05-23T07:02:41.154Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 18,
        MenuItem: "Climate control",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.160Z",
        updatedAt: "2022-05-23T07:02:41.160Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 19,
        MenuItem: "On-board computer and displays",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.165Z",
        updatedAt: "2022-05-23T07:02:41.165Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 20,
        MenuItem: "Multimedia system",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.170Z",
        updatedAt: "2022-05-23T07:02:41.170Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 21,
        MenuItem: "Driving mode",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.176Z",
        updatedAt: "2022-05-23T07:02:41.176Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 22,
        MenuItem: "Working mode",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.183Z",
        updatedAt: "2022-05-23T07:02:41.183Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      {
        id: 23,
        MenuItem: "Maintenance and care",
        order: "0",
        ParentKey: "0",
        createdAt: "2022-05-23T07:02:41.190Z",
        updatedAt: "2022-05-23T07:02:41.190Z",
        tblVehicleLanguageId: 1,
        childItem: [],
      },
      // {
      //   id: 12,
      //   MenuItem: "Breakdown assistance",
      //   order: "0",
      //   ParentKey: "0",
      //   createdAt: "2022-05-23T07:02:41.195Z",
      //   updatedAt: "2022-05-23T07:02:41.195Z",
      //   tblVehicleLanguageId: 1,
      //   childItem: [],
      // },
      // {
      //   id: 13,
      //   MenuItem: "Wheels and tyres",
      //   order: "0",
      //   ParentKey: "0",
      //   createdAt: "2022-05-23T07:02:41.199Z",
      //   updatedAt: "2022-05-23T07:02:41.199Z",
      //   tblVehicleLanguageId: 1,
      //   childItem: [],
      // },
      // {
      //   id: 14,
      //   MenuItem: "Technical data",
      //   order: "0",
      //   ParentKey: "0",
      //   createdAt: "2022-05-23T07:02:41.205Z",
      //   updatedAt: "2022-05-23T07:02:41.205Z",
      //   tblVehicleLanguageId: 1,
      //   childItem: [],
      // },
    ],
  };

  return (
    <div>
      {/* {results.mainMenuItem.map((levelOneItems, i) => { */}
      {leftMenuItems.map((levelOneItems: any, i) => {
        var isSelectedItem = false;

        // if (levelOneItems.id == state.selectedTileID) {
        if (levelOneItems.id == state.selectedLeftMenuID) {
          isSelectedItem = true;
        }

        var isLevelTwoItemPresent;
        if (levelOneItems.childItem.length != 0) {
          isLevelTwoItemPresent = true;
        } else {
          isLevelTwoItemPresent = false;
        }
        if (isLevelTwoItemPresent) {
          return (
            <Collapsible
              trigger={
                <div className={"header-no-subitems-manual"}>
                  <a
                    className={`
                      ${
                        isSelectedItem
                          ? "left-menu-item-link-selected"
                          : "left-menu-item-link"
                      } ${state.isVINAvailable ? "hmi-font" : ""}
                    `}
                  >
                    {levelOneItems.MenuItem}
                  </a>
                </div>
              }
              triggerWhenOpen={
                <div className="header-no-subitems-manual header-no-subitems-manual-selected">
                  <a
                    className={`left-menu-item-link-selected-arrow-down ${
                      state.isVINAvailable ? "hmi-font" : ""
                    }`}
                  >
                    {levelOneItems.MenuItem}
                  </a>
                </div>
              }
              onTriggerOpening={() => {
                arrayControl(1, levelOneItems.length, i, true, [
                  levelOneItems.MenuItem,
                ]);
              }}
              open={firstLevelOpenControlArray[i]}
              key={i}
            >
              {levelOneItems.childItem.map((levelTwoItems, j) => {
                isSelectedItem = false;
                // if (levelTwoItems.id == state.selectedTileID) {
                if (levelTwoItems.id == state.selectedLeftMenuID) {
                  isSelectedItem = true;
                }
                var isLevelThreeItemPresent = false;
                if (levelTwoItems.childItem.length != 0) {
                  isLevelThreeItemPresent = true;
                }
                if (isLevelThreeItemPresent) {
                  return (
                    <Collapsible
                      trigger={
                        <div
                          className={
                            "header-no-subitems-manual header-no-subitems-manual-level-two"
                          }
                        >
                          <a
                            className={`
                              ${
                                isSelectedItem
                                  ? "left-menu-item-link-selected"
                                  : "left-menu-item-link"
                              } ${state.isVINAvailable ? "hmi-font" : ""}

                            `}
                          >
                            {levelTwoItems.MenuItem}
                          </a>
                        </div>
                      }
                      triggerWhenOpen={
                        <div className="header-no-subitems-manual header-no-subitems-manual-selected-level-two">
                          <a className="left-menu-item-link-selected-arrow-down">
                            {levelTwoItems.MenuItem}
                          </a>
                        </div>
                      }
                      open={secondLevelOpenControlArray[j]}
                      onTriggerOpening={() => {
                        arrayControl(2, levelTwoItems.length, j, true, [
                          levelOneItems.MenuItem,
                          levelOneItems.MenuItem,
                        ]);
                      }}
                      key={j}
                    >
                      {levelTwoItems.childItem.map((levelThreeItems, k) => {
                        isSelectedItem = false;
                        // if (levelThreeItems.id == state.selectedTileID) {
                        if (levelThreeItems.id == state.selectedLeftMenuID) {
                          isSelectedItem = true;
                        }
                        var isLevelFourItemsPresent = false;
                        if (levelThreeItems.childItem.length != 0) {
                          isLevelFourItemsPresent = true;
                        }

                        if (isLevelFourItemsPresent) {
                          return (
                            <Collapsible
                              trigger={
                                <div
                                  className={
                                    "header-no-subitems-manual header-no-subitems-manual-level-three"
                                  }
                                >
                                  <a
                                    className={`
                                      ${
                                        isSelectedItem
                                          ? "left-menu-item-link-selected"
                                          : "left-menu-item-link"
                                      } ${
                                      state.isVINAvailable ? "hmi-font" : ""
                                    }
                                    `}
                                  >
                                    {levelThreeItems.MenuItem}
                                  </a>
                                </div>
                              }
                              triggerWhenOpen={
                                <div className="header-no-subitems-manual header-no-subitems-manual-selected-level-three">
                                  <a className="left-menu-item-link-selected-arrow-down">
                                    {levelThreeItems.MenuItem}
                                  </a>
                                </div>
                              }
                              onTriggerOpening={() => {
                                arrayControl(
                                  3,
                                  levelThreeItems.length,
                                  k,
                                  true,
                                  [
                                    levelOneItems.MenuItem,
                                    levelTwoItems.MenuItem,
                                    levelThreeItems.MenuItem,
                                  ]
                                );
                              }}
                              key={k}
                              open={thirdLevelOpenControlArray[k]}
                            >
                              {levelThreeItems.childItem.map(
                                (levelFourItems, l) => {
                                  isSelectedItem = false;
                                  if (
                                    // levelFourItems.id == state.selectedTileID
                                    levelFourItems.id ==
                                    state.selectedLeftMenuID
                                  ) {
                                    isSelectedItem = true;
                                  }
                                  var isLevelFiveItemPresent = false;
                                  if (levelFourItems.childItem.length != 0) {
                                    isLevelFiveItemPresent = true;
                                  }
                                  if (isLevelFiveItemPresent) {
                                    return (
                                      <Collapsible
                                        trigger={
                                          <div
                                            className={
                                              "header-no-subitems-manual header-no-subitems-manual-level-three"
                                            }
                                          >
                                            <a
                                              className={`
                                            ${
                                              isSelectedItem
                                                ? "left-menu-item-link-selected"
                                                : "left-menu-item-link"
                                            } ${
                                                state.isVINAvailable
                                                  ? "hmi-font"
                                                  : ""
                                              }
                                          `}
                                            >
                                              {levelFourItems.MenuItem}
                                            </a>
                                          </div>
                                        }
                                        triggerWhenOpen={
                                          <div className="header-no-subitems-manual header-no-subitems-manual-selected-level-three">
                                            <a className="left-menu-item-link-selected-arrow-down">
                                              {levelFourItems.MenuItem}
                                            </a>
                                          </div>
                                        }
                                        onTriggerOpening={() => {
                                          arrayControl(
                                            4,
                                            levelFourItems.length,
                                            k,
                                            true,
                                            [
                                              levelOneItems.MenuItem,
                                              levelTwoItems.MenuItem,
                                              levelThreeItems.MenuItem,
                                              levelFourItems.MenuItem,
                                            ]
                                          );
                                        }}
                                        key={k}
                                        open={thirdLevelOpenControlArray[k]}
                                      >
                                        {levelFourItems.childItem.map(
                                          (levelFiveItems, l) => {
                                            isSelectedItem = false;
                                            if (
                                              // levelFourItems.id == state.selectedTileID
                                              levelFiveItems.id ==
                                              state.selectedLeftMenuID
                                            ) {
                                              isSelectedItem = true;
                                            }
                                            var isLevelSixItemPresent = false;
                                            if (
                                              levelFiveItems.childItem.length !=
                                              0
                                            ) {
                                              isLevelSixItemPresent = true;
                                            }
                                            if (isLevelSixItemPresent) {
                                              return <>hi</>;
                                            } else {
                                              return (
                                                <Collapsible
                                                  trigger={
                                                    <div
                                                      className={
                                                        isSelectedItem
                                                          ? "header-no-subitems-manual header-no-subitems-manual-selected-level-four"
                                                          : "header-no-subitems-manual header-no-subitems-manual-level-four"
                                                      }
                                                    >
                                                      <a
                                                        className={`
                                                      ${
                                                        isSelectedItem
                                                          ? "left-menu-item-link-selected"
                                                          : "left-menu-item-link"
                                                      } ${
                                                          state.isVINAvailable
                                                            ? "hmi-font"
                                                            : ""
                                                        }
                                                    `}
                                                        onClick={() => {
                                                          logger.info(
                                                            `ID within collapsible header is ${levelFourItems.id}`
                                                          );
                                                          scrollToTop();
                                                          dispatchSelectedTileIndex(
                                                            levelFiveItems.id,
                                                            levelFiveItems.MenuItem
                                                          );
                                                          arrayControl(
                                                            5,
                                                            levelThreeItems.length,
                                                            k,
                                                            false,
                                                            [
                                                              levelOneItems.MenuItem,
                                                              levelTwoItems.MenuItem,
                                                              levelThreeItems.MenuItem,
                                                              levelFourItems.MenuItem,
                                                              levelFiveItems.MenuItem,
                                                            ]
                                                          );
                                                        }}
                                                      >
                                                        {
                                                          levelFiveItems.MenuItem
                                                        }
                                                      </a>
                                                    </div>
                                                  }
                                                  key={l}
                                                ></Collapsible>
                                              );
                                            }
                                          }
                                        )}
                                      </Collapsible>
                                    );
                                  } else {
                                    return (
                                      <Collapsible
                                        trigger={
                                          <div
                                            className={
                                              isSelectedItem
                                                ? "header-no-subitems-manual header-no-subitems-manual-selected-level-four"
                                                : "header-no-subitems-manual header-no-subitems-manual-level-four"
                                            }
                                          >
                                            <a
                                              className={`
                                                ${
                                                  isSelectedItem
                                                    ? "left-menu-item-link-selected"
                                                    : "left-menu-item-link"
                                                } ${
                                                state.isVINAvailable
                                                  ? "hmi-font"
                                                  : ""
                                              }
                                              `}
                                              onClick={() => {
                                                logger.info(
                                                  `ID within collapsible header is ${levelFourItems.id}`
                                                );
                                                scrollToTop();
                                                dispatchSelectedTileIndex(
                                                  levelFourItems.id,
                                                  levelFourItems.MenuItem
                                                );
                                                arrayControl(
                                                  4,
                                                  levelThreeItems.length,
                                                  k,
                                                  false,
                                                  [
                                                    levelOneItems.MenuItem,
                                                    levelTwoItems.MenuItem,
                                                    levelThreeItems.MenuItem,
                                                    levelFourItems.MenuItem,
                                                  ]
                                                );
                                              }}
                                            >
                                              {levelFourItems.MenuItem}
                                            </a>
                                          </div>
                                        }
                                        key={l}
                                      ></Collapsible>
                                    );
                                  }
                                }
                              )}
                            </Collapsible>
                          );
                        } else {
                          return (
                            <Collapsible
                              trigger={
                                <div
                                  className={
                                    isSelectedItem
                                      ? "header-no-subitems-manual header-no-subitems-manual-selected-level-three"
                                      : "header-no-subitems-manual header-no-subitems-manual-level-three"
                                  }
                                >
                                  <a
                                    className={`
                                      ${
                                        isSelectedItem
                                          ? "left-menu-item-link-selected"
                                          : "left-menu-item-link"
                                      } ${
                                      state.isVINAvailable ? "hmi-font" : ""
                                    }
                                    `}
                                    onClick={() => {
                                      logger.info(
                                        `ID within collapsible header is ${levelThreeItems.id}`
                                      );
                                      scrollToTop();
                                      dispatchSelectedTileIndex(
                                        levelThreeItems.id,
                                        levelThreeItems.MenuItem
                                      );
                                      arrayControl(
                                        3,
                                        levelThreeItems.length,
                                        k,
                                        false,
                                        [
                                          levelOneItems.MenuItem,
                                          levelTwoItems.MenuItem,
                                          levelThreeItems.MenuItem,
                                        ]
                                      );
                                    }}
                                  >
                                    {levelThreeItems.MenuItem}
                                  </a>
                                </div>
                              }
                              key={k}
                            ></Collapsible>
                          );
                        }
                      })}
                    </Collapsible>
                  );
                } else {
                  return (
                    <Collapsible
                      trigger={
                        <div
                          className={
                            isSelectedItem
                              ? "header-no-subitems-manual header-no-subitems-manual-selected-level-two"
                              : "header-no-subitems-manual header-no-subitems-manual-level-two"
                          }
                        >
                          <a
                            className={`
                              ${
                                isSelectedItem
                                  ? "left-menu-item-link-selected"
                                  : "left-menu-item-link"
                              } ${state.isVINAvailable ? "hmi-font" : ""}
                            `}
                            onClick={() => {
                              logger.info(
                                `ID within collapsible header is ${levelTwoItems.id}`
                              );
                              scrollToTop();
                              dispatchSelectedTileIndex(
                                levelTwoItems.id,
                                levelTwoItems.MenuItem
                              );
                              arrayControl(2, levelTwoItems.length, j, false, [
                                levelOneItems.MenuItem,
                                levelTwoItems.MenuItem,
                              ]);
                            }}
                          >
                            {levelTwoItems.MenuItem}
                          </a>
                        </div>
                      }
                      key={j}
                    ></Collapsible>
                  );
                }
              })}
            </Collapsible>
          );
        } else {
          return (
            <Collapsible
              trigger={
                <div
                  className={
                    isSelectedItem
                      ? "header-no-subitems-manual header-no-subitems-manual-selected"
                      : "header-no-subitems-manual"
                  }
                >
                  <a
                    className={`
                      ${
                        isSelectedItem
                          ? "left-menu-item-link-selected"
                          : "left-menu-item-link"
                      } ${state.isVINAvailable ? "hmi-font" : ""}
                    `}
                    onClick={() => {
                      logger.info(
                        `ID within collapsible header is ${levelOneItems.id}`
                      );
                      scrollToTop();
                      arrayControl(1, levelOneItems.length, i, false, [
                        levelOneItems.MenuItem,
                      ]);
                      dispatchSelectedTileIndex(
                        levelOneItems.id,
                        levelOneItems.MenuItem
                      );
                    }}
                  >
                    {levelOneItems.MenuItem}
                  </a>
                </div>
              }
              key={i}
            ></Collapsible>
          );
        }
      })}
    </div>
  );
};

export default LeftMenuContainer;
