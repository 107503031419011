import "./CommonHeaderComponent.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "../../State";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import apiClient from "../../services/http-common";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import logger from "../../Utils/logger";
import FlashMessage from "./FlashMessage";
import { scheduledDowntimeMessageTranslations } from "../../Utils/FlashMessageTranslations";
interface ContainerProps {}

type ManualContainerTranslations = {
  homeScreen: string;
  searchPlaceholder: string;
  noResultsFound: string;
  mercedesBenzTruck: string;
  provider: string;
};

const ManualHeaderContainer: React.FC<ContainerProps> = () => {
  const { state, dispatch } = useContext(AppContext);

  const [translationTexts, setTranslationsText]: [
    ManualContainerTranslations,
    Dispatch<SetStateAction<ManualContainerTranslations>>
  ] = useState({
    homeScreen: "Home Screen",
    searchPlaceholder: "Search in the Owner's Manual",
    noResultsFound: "No results found",
    mercedesBenzTruck: "Mercedes-Benz Trucks",
    provider: "Provider",
  });

  const history = useHistory();

  const [q, setQ] = useState("");

  const [isSearchDataPresent, setIsSearchDataPresent] = useState(false);

  var refLink = "https://www.mercedes-benz-trucks.com/";
  //var common_portal = "https://digital-manuals.tsac-staging.daimlertruck.com/"
  var common_portal = "https://manuals.daimlertruck.com/"

  useEffect(() => {
    getTranslationsManual();
    getTranslationsHome();
  }, [state.selectedLanguageCode]);

  useEffect(() => {
    setQ("");
  }, [state.manualSearchFieldValueControl]);

  const getTranslationsHome = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Provider%7C$$Mercedes-Benz_Guides`
      );
      data = result.data?.footerDetails;
      var translationDetails = translationTexts;
      data?.map((items) => {
        if (items.Variable_Name == "$$Provider") {
          translationDetails.provider = items.translated_text;
        } else if (items.Variable_Name == "$$Mercedes-Benz_Truck") {
          translationDetails.mercedesBenzTruck = items.translated_text;
        }
      });
      // setTranslationInfo({ ...translationDetails });
      setTranslationsText((translationTexts) => ({
        ...translationTexts,
        ...translationDetails,
      }));
      logger.info(`Home Header Translation API result`, data);
    } catch (err: any) {
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
      logger.info(`Home Header Translation API ERROR ${err}`);
    }
  };

  const getTranslationsManual = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Home_screen%7C$$Search_placeholder%7C$$No_result_found`
      );
      data = result.data?.footerDetails;
      var translationInfo = translationTexts;
      data?.map((items) => {
        if (items.Variable_Name == "$$Home_screen") {
          translationInfo.homeScreen = items.translated_text;
        } else if (items.Variable_Name == "$$Search_placeholder") {
          translationInfo.searchPlaceholder = items.translated_text;
        } else {
          translationInfo.noResultsFound = items.translated_text;
        }
      });
      setTranslationsText((translationTexts) => ({
        ...translationTexts,
        ...translationInfo,
      }));
      logger.info(`Translation API result`, data);
    } catch (err: any) {
      logger.info(`Translation API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const location = useLocation();
  const [isVisibleHome, setIsVisibleHome] = useState(false);
  const [isVisibleTiledItems, setIsVisibleTiledItems] = useState(false);
  const [isVisibleManual, setIsVisibleManual] = useState(false);
  const [isVisibleMoreManualContent, setIsVisibleMoreManualContent] =
    useState(false);

  const [activeDowntimeMessage, setActiveDowntimeMessage] = useState<String | null>(null);
    async function fetchActiveDowntime() {
      let res = await apiClient.get("/retrivergetActiveDowntimeDetails");
      res = res.data;
      console.log("downtime", res);
      const activeDowntimeDetails = res.activeDowntimeDetails;
      if(activeDowntimeDetails) {
        const {startDate, startTime, endTime} = activeDowntimeDetails;
        console.log("downtime", state.selectedLanguageCode, startDate, startTime, endTime);
        const message = scheduledDowntimeMessageTranslations[state.selectedLanguageCode].replace(/\[startDate\]|\[startTime\]|\[endTime\]/g, (matched) => {
          return { '[startDate]': startDate, '[startTime]': startTime, '[endTime]': endTime }[matched];
        });
        setActiveDowntimeMessage(message);
      }
      else {
        setActiveDowntimeMessage(null);
      }
    }
  useEffect(() => {
    fetchActiveDowntime();
  },[state.selectedLanguageCode])

  useEffect(() => {
    if (location.pathname.includes("/truckownermanuals/home")) {
      setIsVisibleHome(true);
    } else {
      setIsVisibleHome(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/truckownermanuals/tiled-items") {
      setIsVisibleTiledItems(true);
    } else {
      setIsVisibleTiledItems(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/truckownermanuals/manual") {
      setIsVisibleManual(true);
    } else {
      setIsVisibleManual(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/truckownermanuals/more-content") {
      setIsVisibleMoreManualContent(true);
    } else {
      setIsVisibleMoreManualContent(false);
    }
  }, [location.pathname]);

  return (
    <div className="manual-parent-container" style={isVisibleTiledItems?{width:"100%"}: undefined}>
      <DesktopBreakpoint>
        {state.isVINAvailable ? (
          <div className="manual-benz-logo-container"></div>
        ) : (
          <>
          {activeDowntimeMessage && isVisibleHome && <FlashMessage message={activeDowntimeMessage} duration={1000}/>}
            <div className="manual-benz-logo-container">
              <img
                className="benz-logo-image" style={isVisibleTiledItems?{width:"250px"}: undefined}
                // src="assets/images/Wortmarke_Stern-3.svg"
                src={
                  process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
                }
                alt="Benz Logo"
              ></img>
            </div>
            </>
        )}
        <div className="right-container">
          <div className="right-top-container">
            <div>
              {isVisibleManual && !state.isVINAvailable && (
                <a
                  className="homelink-mercedes-manual-page"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <span>{translationTexts.homeScreen}</span>
                </a>
              )}
            </div>
            <div className="search-box-container-manual">
              {isVisibleManual && (
                <form onKeyDown={onKeyDown}>
                  <input
                    type="text"
                    name="search"
                    placeholder={translationTexts.searchPlaceholder}
                    className="search-box-manual"
                    autoComplete="off"
                    value={q}
                    onChange={(e) => {
                      e.preventDefault();
                      setQ(e.target.value);
                      if (e.target.value.length <= 1) {
                        setIsSearchDataPresent(false);
                      } else {
                        setIsSearchDataPresent(true);
                      }
                    }}
                  ></input>
                  <a
                    className="submit-search-manual"
                    onClick={() => {
                      dispatch({
                        type: "setSearchedQueryInManual",
                        payload: {
                          searchedQueryInManual: q,
                        },
                      });
                      history.goBack();
                    }}
                  ></a>
                </form>
              )}
            </div>
            <div>
              {(isVisibleManual || isVisibleMoreManualContent) && (
                <a
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img
                    // src="assets/images/icon-back.png"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon-back.png"
                    }
                    // className="link-image-in-manual link-image-phone"
                    className={`link-image-in-manual link-image-phone ${
                      state.isVINAvailable ? "hmi-navigation-icons" : ""
                    }`}
                    alt="home-icon"
                  ></img>
                </a>
              )}
            </div>
            <div>
              {(isVisibleTiledItems ||
                isVisibleManual ||
                isVisibleMoreManualContent) && !state.isVINAvailable && (
                <a
                  onClick={() => {
                    history.push("/truckownermanuals");
                  }}
                >
                  <img
                    // src="assets/images/to_home.png"
                    src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                    // className="link-image-in-manual link-image-phone"
                    className={`link-image-in-manual link-image-phone ${
                      state.isVINAvailable ? "hmi-navigation-icons" : ""
                    }`}
                    alt="home-icon"
                  ></img>
                  {isVisibleHome && (
                    <span className="homelink-name">
                      {translationTexts.mercedesBenzTruck}
                    </span>
                  )}
                </a>
              )}
              {isVisibleHome && (
                <a href={common_portal} >
                  <img
                    // src="assets/images/to_home.png"
                    src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                    // className="link-image-in-manual link-image-phone"
                    className={`link-image-in-manual link-image-phone ${
                      state.isVINAvailable ? "hmi-navigation-icons" : ""
                    }`}
                    alt="home-icon"
                  ></img>
                  {isVisibleHome && (
                    <span className="homelink-name">
                      Common Portal
                    </span>
                  )}
                </a>
              )}
            </div>
            <div>
              {isVisibleHome && <span className="link-separator"></span>}
            </div>
            <div>
              {(isVisibleHome ||
                isVisibleTiledItems ||
                isVisibleManual ||
                isVisibleMoreManualContent) && !state.isVINAvailable && (
                <a
                  href={refLink}
                  id="homelink-mercedes"
                  className="homelink-mercedes-manual"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    // src="assets/images/info_icon.png"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/info_icon.png"
                    }
                    className="link-image-in-manual"
                    alt="privacy-icon"
                  ></img>
                  {isVisibleHome && (
                    <span className="homelink-name">
                      {translationTexts.provider}
                    </span>
                  )}
                </a>
              )}
            </div>
            
          </div>
        </div>
      </DesktopBreakpoint>
      <TabletBreakpoint>
        <div className="tab-phone-logo-container">
          {state.isVINAvailable ? (
            <div className="manual-benz-logo-container"></div>
          ) : (
            <>
          {activeDowntimeMessage && isVisibleHome && <FlashMessage message={activeDowntimeMessage} duration={1000}/>}
          <div className="manual-benz-logo-container">
                <img
                  className="benz-logo-image"
                  // src="assets/images/Wortmarke_Stern-3.svg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Wortmarke_Stern-3.svg"
                  }
                  alt="Benz Logo"
                ></img>
              </div>
            </>
          )}
          <div className="tab-phone-menu-container">
            <div className="link-stack-manual">
              <div className="right-container">
                <div>
                  {(isVisibleManual || isVisibleMoreManualContent) && (
                    <a
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <img
                        // src="assets/images/icon-back.png"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icon-back.png"
                        }
                        className="link-image-in-manual link-image-phone"
                        alt="home-icon"
                      ></img>
                    </a>
                  )}
                </div>
                <div>
                  {(isVisibleTiledItems ||
                    isVisibleManual ||
                    isVisibleMoreManualContent) && (
                    <a
                      onClick={() => {
                        history.push("/truckownermanuals");
                      }}
                    >
                      <img
                        // src="assets/images/to_home.png"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/to_home.png"
                        }
                        className="link-image-in-manual link-image-phone"
                        alt="home-icon"
                      ></img>
                      {isVisibleHome && (
                        <span className="homelink-name">
                          {translationTexts.mercedesBenzTruck}
                        </span>
                      )}
                    </a>
                  )}
                  {isVisibleHome && (
                  <a href={common_portal} >
                    <img
                      // src="assets/images/to_home.png"
                      src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                      // className="link-image-in-manual link-image-phone"
                      className={`link-image-in-manual link-image-phone ${
                        state.isVINAvailable ? "hmi-navigation-icons" : ""
                      }`}
                      alt="home-icon"
                    ></img>
                    {isVisibleHome && (
                      <span className="homelink-name">
                        Common Portal
                      </span>
                    )}
                  </a>
                )}
                </div>
                <div>
                  {isVisibleHome && <span className="link-separator"></span>}
                </div>
                <div>
                  {(isVisibleHome ||
                    isVisibleTiledItems ||
                    isVisibleManual ||
                    isVisibleMoreManualContent) && (
                    <a
                      href={refLink}
                      className="homelink-mercedes"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        // src="assets/images/info_icon.png"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/info_icon.png"
                        }
                        className="link-image-in-manual"
                        alt="privacy-icon"
                      ></img>
                      {isVisibleHome && (
                        <span className="homelink-name">
                          {translationTexts.provider}
                        </span>
                      )}
                    </a>
                  )}
                </div>

                <div>
                  {(isVisibleTiledItems || isVisibleManual) && (
                    <a
                      className="homelink-mercedes"
                      onClick={() => {
                        dispatch({
                          type: "setIsLeftMenuHiddenInPhone",
                          payload: {
                            isLeftMenuHiddenInPhone:
                              !state.isLeftMenuHiddenInPhone,
                          },
                        });
                      }}
                    >
                      <img
                        // src="assets/images/menu.png"
                        src={process.env.PUBLIC_URL + "/assets/images/menu.png"}
                        className="link-image-in-manual link-image-phone"
                        alt="privacy-icon"
                      ></img>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-phone-search-box-container-manual">
          {isVisibleManual && (
            <form onKeyDown={onKeyDown}>
              <input
                type="text"
                name="search"
                placeholder={translationTexts.searchPlaceholder}
                className="tab-phone-search-box-manual"
                autoComplete="off"
                value={q}
                onChange={(e) => {
                  e.preventDefault();
                  setQ(e.target.value);
                  if (e.target.value.length <= 1) {
                    setIsSearchDataPresent(false);
                  } else {
                    setIsSearchDataPresent(true);
                  }
                }}
              ></input>
              <a
                className="tab-phone-submit-search-manual"
                onClick={() => {
                  dispatch({
                    type: "setSearchedQueryInManual",
                    payload: {
                      searchedQueryInManual: q,
                    },
                  });
                  history.goBack();
                }}
              ></a>
            </form>
          )}
        </div>
      </TabletBreakpoint>
      <PhoneBreakpoint>
      {activeDowntimeMessage && isVisibleHome && <FlashMessage message={activeDowntimeMessage} duration={1000}/>}
      <div className="tab-phone-logo-container">
          <div className="manual-benz-logo-container">
            <img
              className="benz-logo-image"
              // src="assets/images/Wortmarke_Stern-3.svg"
              src={
                process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
              }
              alt="Benz Logo"
            ></img>
          </div>
          <div className="tab-phone-menu-container">
            <div className="link-stack-manual">
              <div className="right-container">
                <div>
                  {(isVisibleHome ||
                    isVisibleTiledItems ||
                    isVisibleManual ||
                    isVisibleMoreManualContent) && (
                    <a
                      href={refLink}
                      className="homelink-mercedes"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        // src="assets/images/info_icon.png"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/info_icon.png"
                        }
                        className="link-image-in-manual"
                        alt="privacy-icon"
                      ></img>
                    </a>
                  )}
                </div>
                <div>
                  {(isVisibleManual || isVisibleMoreManualContent) && (
                    <a
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <img
                        // src="assets/images/icon-back.png"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icon-back.png"
                        }
                        className="link-image-in-manual link-image-phone"
                        alt="home-icon"
                      ></img>
                    </a>
                  )}
                </div>
                <div>
                  {(isVisibleTiledItems ||
                    isVisibleManual ||
                    isVisibleMoreManualContent) && (
                    <a
                      onClick={() => {
                        history.push("/truckownermanuals");
                      }}
                    >
                      <img
                        // src="assets/images/to_home.png"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/to_home.png"
                        }
                        className="link-image-in-manual link-image-phone"
                        alt="home-icon"
                      ></img>
                    </a>
                  )}
                  {isVisibleHome && (
                  <a href={common_portal} >
                    <img
                      // src="assets/images/to_home.png"
                      src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                      // className="link-image-in-manual link-image-phone"
                      className={`link-image-in-manual link-image-phone ${
                        state.isVINAvailable ? "hmi-navigation-icons" : ""
                      }`}
                      alt="home-icon"
                    ></img>
                  </a>
                )}
                </div>

                <div>
                  {(isVisibleHome ||
                    isVisibleTiledItems ||
                    isVisibleManual) && (
                    <a
                      className="homelink-mercedes"
                      onClick={() => {
                        dispatch({
                          type: "setIsLeftMenuHiddenInPhone",
                          payload: {
                            isLeftMenuHiddenInPhone:
                              !state.isLeftMenuHiddenInPhone,
                          },
                        });
                      }}
                    >
                      <img
                        // src="assets/images/menu.png"
                        src={process.env.PUBLIC_URL + "/assets/images/menu.png"}
                        className="link-image-in-manual link-image-phone"
                        alt="privacy-icon"
                      ></img>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isVisibleManual && (
        <div className="tab-phone-search-box-container-manual">
          
            <form onKeyDown={onKeyDown}>
              <input
                type="text"
                name="search"
                placeholder={translationTexts.searchPlaceholder}
                className="tab-phone-search-box-manual"
                autoComplete="off"
                value={q}
                onChange={(e) => {
                  e.preventDefault();
                  setQ(e.target.value);
                  if (e.target.value.length <= 1) {
                    setIsSearchDataPresent(false);
                  } else {
                    setIsSearchDataPresent(true);
                  }
                }}
              ></input>
              <a
                className="tab-phone-submit-search-manual"
                onClick={() => {
                  dispatch({
                    type: "setSearchedQueryInManual",
                    payload: {
                      searchedQueryInManual: q,
                    },
                  });
                  history.goBack();
                }}
              ></a>
            </form>
          
        </div>)}
      </PhoneBreakpoint>
    </div>
  );
};

export default ManualHeaderContainer;