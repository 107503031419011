const vehicleVINDetails = {
  "00": {
    vehicleVIN: "W1T98300310628787",
    vehicleName: "Actros",
    vehicleModel: "Multimedia Cockpit (Current version)",
    supportedLanguages: {
      en: "en_GB",
      de: "de_DE",
      fr: "fr_FR",
      nl: "nl_NL",
      it: "it_IT",
      es: "es_ES",
    },
  },
  "04": {
    vehicleVIN: "ACT98300310628787",
    vehicleName: "eActros",
    vehicleModel: "Current version",
    supportedLanguages: {
      el: "el_GR",
      he: "he_IL",
      en: "en_GB",
      ar: "ar_EG",
      bg: "bg_BG",
      cs: "cs_CZ",
      da: "da_DK",
      de: "de_DE",
      es: "es_ES",
      et: "et_EE",
      fi: "fi_FI",
      fr: "fr_FR",
      hr: "hr_HR",
      hu: "hu_HU",
      it: "it_IT",
      ko: "ko_KR",
      lt: "lt_LT",
      lv: "lv_LV",
      nl: "nl_NL",
      no: "no_NO",
      pl: "pl_PL",
      zh: "zh_TW",
      pt: "pt_PT",
      ro: "ro_RO",
      ru: "ru_RU",
      sr: "sr_RS",
      tr: "tr_TR",
      zh: "zh_CN",
      sk: "sk_SK",
      sv: "sv_SE",
      sl: "sl_SI",
      uk: "uk_UA",
    },
  },
};

const browserLanugages = {
  en: "en_GB",
  de: "de_DE",
  "en-US": "en_GB",
};

export { vehicleVINDetails, browserLanugages };
