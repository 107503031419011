import "./ManualParentContainer.css";
import LeftMenuContainer from "../LeftMenuContainer/LeftMenuContainer";
import ManualContent from "../ManualContent/ManualContent";
import { AppContext } from "../../State";
import {
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useReactToPrint } from "react-to-print";
import apiClient from "../../services/http-common";
import { useHistory } from "react-router-dom";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import logger from "../../Utils/logger";
interface ContainerProps {
  onClickScrollToTop: any;
}

const ManualParentContainer: React.FC<ContainerProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);

  const history = useHistory();

  const [printText, setPrintText] = useState("Print");

  const [initialLevelHierarchyItems, setInitialLevelHierarchyItems] = useState([
    "",
  ]);

  const [currentElement, setCurrentElement] = useState("");

  var arrowSymbol = ">";

  const manualCompRef = useRef();

  useEffect(() => {
    getTranslations();
  }, [state.selectedLanguageCode]);

  useEffect(() => {
    setLevelSelctionHierarchyToDisplay();
  }, [state.isLeftMenuClicked]);

  useEffect(() => {
    setLevelSelctionHierarchyToDisplay();
  }, [state.selectedSearchedParents]);

  function setLevelSelctionHierarchyToDisplay() {
    var initialContentSet = [];
    var finalContent = "";
    var selectedItemList = state.isNavigatedFromSearch
      ? state.selectedSearchedParents
      : state.selectedLevelHierarchy.filter((item) => item != "");
    if (selectedItemList.length == 1) {
      finalContent = selectedItemList[0];
    } else {
      var levelHierarchyLength = selectedItemList.length;
      initialContentSet = selectedItemList.filter(
        (item, index) => index !== levelHierarchyLength - 1
      );
      finalContent = selectedItemList[levelHierarchyLength - 1];
    }
    setInitialLevelHierarchyItems(initialContentSet);
    setCurrentElement(finalContent);
  }

  const getTranslations = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Print`
      );
      data = result.data?.footerDetails;

      data?.map((items) => {
        if (items.Variable_Name == "$$Print") {
          setPrintText(items.translated_text);
        }
      });
      logger.info(`Tiled Page API result`, data);
    } catch (err) {
      logger.info(`Tiled Page Translation API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
    }
  };

  function getLeftMenuForMobileScreenWidth(isChildItemPresent: Boolean) {
    if (isChildItemPresent) {
      if (state.isLeftMenuHiddenInPhone) {
        return (
          <div className="manual-left-side-item">
            <LeftMenuContainer onClickScrollToTop={props.onClickScrollToTop} />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function getManualContentDiv(isPhoneScreen: Boolean) {
    if (isPhoneScreen) {
      return (
        <div className="manual-main-container">
          {getLeftMenuForMobileScreenWidth(state.isChildItemPresent)}
          <div
            className={
              state.isChildItemPresent
                ? "manual-right-side-item"
                : "manual-right-side-item manual-child-absent"
            }
            id="printContent"
          >
            <div className="selected-left-item-title-container">
              <h3 className="selected-left-item-title" id="selectedItemTitle">
                {state.selectedLeftMenuTitle}
              </h3>

              {state.isVINAvailable ? null : (
                <a
                  className="print-button"
                  id="printBtn"
                  onClick={() => {
                    manualCompRef.current.printContent();
                  }}
                >
                  {printText}
                </a>
              )}
            </div>
            <span className="title-content-separator" id="separatorLine"></span>
            <div className="manual-right-content" id="selectedManualContent">
              <ManualContent ref={manualCompRef} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`manual-main-container ${
            state.isVINAvailable ? "hmi-width" : ""
          }`}
        >
          {state.isChildItemPresent ? (
            <div className="manual-left-side-item">
              <LeftMenuContainer
                onClickScrollToTop={props.onClickScrollToTop}
              />
            </div>
          ) : null}
          <div
            className={
              state.isChildItemPresent
                ? "manual-right-side-item"
                : "manual-right-side-item manual-child-absent"
            }
            id="printContent"
          >
            <div className="selected-left-item-title-container">
              <h3 className="selected-left-item-title" id="selectedItemTitle">
                {state.selectedLeftMenuTitle}
              </h3>

              {state.isVINAvailable ? null : (
                <a
                  className="print-button"
                  id="printBtn"
                  onClick={() => {
                    manualCompRef.current.printContent();
                  }}
                >
                  {printText}
                </a>
              )}
            </div>
            <span className="title-content-separator" id="separatorLine"></span>
            <div className="manual-right-content" id="selectedManualContent">
              <ManualContent ref={manualCompRef} />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="manual-parent-block">
      <div className="menu-item-heirarchy-container">
        {initialLevelHierarchyItems.map((item, index) => {
          return (
            <span key={index}>
              <span
                className={`left-item-title ${
                  state.isVINAvailable ? "hmi-font" : ""
                }`}
              >
                {item}
              </span>
              <span className="left-item-title left-item-title-arrow">
                {arrowSymbol}
              </span>
            </span>
          );
        })}
        <span
          className={`left-last-item-title ${
            state.isVINAvailable ? "hmi-font" : ""
          }`}
        >
          {currentElement}
        </span>
      </div>
      <DesktopBreakpoint>{getManualContentDiv(false)}</DesktopBreakpoint>
      <TabletBreakpoint>{getManualContentDiv(false)}</TabletBreakpoint>
      <PhoneBreakpoint>{getManualContentDiv(true)}</PhoneBreakpoint>
    </div>
  );
};

export default ManualParentContainer;
