import "./ManualContent.css";
import "../ManualParentContainer/ManualParentContainer.css";
import "./css/common.css";
import "./sass/screen.scss";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AppContext } from "../../State";
import { IFrame } from "./iframe";
import apiClient from "../../services/http-common";
import { useHistory } from "react-router-dom";
import logger from "../../Utils/logger";
interface MoreContainerProps {
  parentCallback: any;
}

const MoreContent: React.FC<MoreContainerProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);

  const [printText, setPrintText] = useState("Print");

  const [contentTitle, setContentTitle] = useState("");

  const [divContent, setDivContent] = useState("");

  const history = useHistory();

  const printIframe = (id) => {
    const iframe = document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    var iframeCW = iframe.contentWindow || iframe.contentDocument;

    if (iframeCW.document) iframeCW = iframeCW.document;
    logger.info(iframeCW);
    iframe.focus();
    iframeWindow.print();
    return false;
  };

  window.toggle_visibility = (titleID, contentID, boxType) => {
    var content = document.getElementById(contentID);
    if (content.className == "cos-box-content") {
      content.className = "cos-box-content cos-box-hidden";
    } else {
      content.className = "cos-box-content";
    }
    var title = document.getElementById(titleID);
    if (boxType == "box-type-env") {
      if (
        title.className == "cos-box-title cos-box-environment-title expanded"
      ) {
        title.className = "cos-box-title cos-box-environment-title";
      } else {
        title.className = "cos-box-title cos-box-environment-title expanded";
      }
    } else if (boxType == "box-type-warning") {
      if (title.className == "cos-box-title cos-box-warning-title expanded") {
        title.className = "cos-box-title cos-box-warning-title";
      } else {
        title.className = "cos-box-title cos-box-warning-title expanded";
      }
    } else if (boxType == "box-type-damage") {
      if (title.className == "cos-box-title cos-box-damage-title") {
        title.className = "cos-box-title cos-box-damage-title expanded";
      } else {
        title.className = "cos-box-title cos-box-damage-title";
      }}
      else if (boxType == "box-type-note") {
        if (title.className == "cos-box-title cos-box-note-title") {
          title.className = "cos-box-title cos-box-note-title expanded";
        } else {
          title.className = "cos-box-title cos-box-note-title";
        }
    }
  };

  useEffect(() => {
    let selectedContentInfo = {
      selectedMoreContent: state.selectedMoreContent,
      vehicleName: localStorage.getItem("vehicleName"),
      vehicleModel: localStorage.getItem("vehicleModel"),
      manualLangCode: localStorage.getItem("manualLangCode"),
    };
    callMoreContentAPI(state.selectedMoreContent, selectedContentInfo);
  }, []);

  useEffect(() => {
    getPrintTranslationText();
  }, [state.selectedLanguageCode]);

  window.getMoreContent = (contentName) => {
    let selectedContentInfo = {
      selectedMoreContent: localStorage.getItem("selectedMoreContent"),

      vehicleName: localStorage.getItem("vehicleName"),

      vehicleModel: localStorage.getItem("vehicleModel"),

      manualLangCode: localStorage.getItem("manualLangCode"),
    };
    dispatch({
      type: "setSelectedMoreContent",
      payload: {
        selectedMoreContent: contentName,
      },
    });
    localStorage.setItem("selectedMoreContent", contentName);

    callMoreContentAPI(contentName, selectedContentInfo);
  };

  const callMoreContentAPI = async (
    contentName: String,
    selectedContentInfo: Object
  ) => {
    props.parentCallback(true);
    try {
      logger.info("selectedContentInfo", selectedContentInfo);
      let result: any;
      var translationLangCode = localStorage.getItem("langCode");

      if (state.isVINAvailable || state.vehicleVIN) {
        result = await apiClient.get(
          `/retrivergetMenucontentByContentName/${encodeURIComponent(
            selectedContentInfo?.selectedMoreContent
          )}/${state.vehicleInfoBasedOnVIN?.vehicleName}/${
            state.vehicleInfoBasedOnVIN?.vehicleModel
          }/${localStorage.getItem("hmiSystemLanguage")}/${
            localStorage.getItem(
              "hmiSystemLanguage"
            ) === "de_DE" ? "de_DE" : ""
          }`
        );
      } else {
        result = await apiClient.get(
          `/retrivergetMenucontentByContentName/${encodeURIComponent(
            selectedContentInfo?.selectedMoreContent
          )}/${selectedContentInfo?.vehicleName}/${
            selectedContentInfo?.vehicleModel
          }/${selectedContentInfo?.manualLangCode}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
      }
      setContentTitle(result.data?.contentDetils?.contentName);
      setDivContent(result.data?.contentDetils?.content);
      props.parentCallback(false);
    } catch (err) {
      props.parentCallback(false);
      history.push("/truckownermanuals/error");
    }
  };

  const getPrintTranslationText = async () => {
    try {
      const result: any = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Print`
      );
      var data = result.data?.footerDetails;

      data?.map((items) => {
        if (items.Variable_Name == "$$Print") {
          setPrintText(items.translated_text);
        }
      });
    } catch (err) {
      history.push("/truckownermanuals/error");
    }
  };

  var testString = `<div class="cos-box" onclick='toggle_visibility("6197", "13798", "box-type-damage");'>
  <div class="cos-box-title cos-box-damage-title expanded" data-content="13798" id="6197"> <span class="cos-box-title-heading">NOTE </span> <span class="cox-box-title-text">Damage to the vehicle due to driving too fast and due to impacts to the vehicle underbody or suspension components</span> </div>
  <div class="cos-box-content" id="13798">
    <div class="cos-box-source cos-box-damage-source">
      <div class="cos-ul-title">In the following situations, in particular, there is a risk of damage to the vehicle:</div>
      <ul class="cos-ul">
        <li class="cos-li">the vehicle becomes grounded, e.g. on a high kerb or an unpaved road</li>
        <li class="cos-li">the vehicle is driven too fast over an obstacle, e.g. a kerb, speed bump or pothole</li>
        <li class="cos-li">a heavy object strikes the underbody or suspension components</li>
      </ul>
    </div>
    <div class="cos-box-defense cos-box-damage-defense">
      <div class="cos-step">
        <div class="cos-action">Have the vehicle checked and repaired immediately at a qualified specialist workshop.</div>
      </div>
      <div class="cos-step">
        <div class="cos-action">If driving safety is impaired while continuing your journey, pull over and stop the vehicle immediately, while paying attention to road and traffic conditions, and contact a qualified specialist workshop.</div>
      </div>
    </div>
  </div>
</div>`;

  return (
    <div className="more-div-1">
      <div className="manual-right-side-item manual-child-absent">
        <div className="selected-left-item-title-container">
          <h3 className="selected-left-item-title">{contentTitle}</h3>
          {state.isVINAvailable ? null : (
            <a
              className="print-button"
              id="printBtn"
              onClick={() => {
                printIframe("manual-iframe-more");
              }}
            >
              {printText}
            </a>
          )}
        </div>
        <span className="title-content-separator" id="separatorLine"></span>
        <div className="manual-right-content" id="selectedManualContent">
          <IFrame id="manual-iframe-more" className="iframe-content">
            <link
              href="css/ManualContent.css"
              rel="stylesheet"
              type="text/css"
            />
            <h1 className="manual-h1-title">{contentTitle}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: divContent }}
              className="manual-content-main-container"
            ></div>
          </IFrame>
          <div
            dangerouslySetInnerHTML={{ __html: divContent }}
            // className="manual-content-main-container content-in-more"
            className={
              state.isVINAvailable
                ? "manual-content-main-container-hmi content-in-more"
                : "manual-content-main-container content-in-more"
            }
            id="htmlContent"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MoreContent;
