import { IonContent, IonPage, IonFooter } from "@ionic/react";
import FooterContainer from "../../components/FooterContainer/FooterContainer";
import "./MoreManualContent.css";
import { useContext, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { AppContext } from "../../State";
import "../../components/ManualContent/css/common.css";
import "../../components/ManualContent/sass/screen.scss";
import MoreContent from "../../components/ManualContent/MoreContent";

import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import { useHistory } from "react-router-dom";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import CommonHeaderComponent from "../../components/CommonHeaderComponent/CommonHeaderComponent";
const MoreManualContent: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { state } = useContext(AppContext);

  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  var testString = `<div class="cos-box" onclick='toggle_visibility("6197", "13798", "box-type-damage");'>
  <div class="cos-box-title cos-box-damage-title expanded" data-content="13798" id="6197"> <span class="cos-box-title-heading">NOTE </span> <span class="cox-box-title-text">Damage to the vehicle due to driving too fast and due to impacts to the vehicle underbody or suspension components</span> </div>
  <div class="cos-box-content" id="13798">
    <div class="cos-box-source cos-box-damage-source">
      <div class="cos-ul-title">In the following situations, in particular, there is a risk of damage to the vehicle:</div>
      <ul class="cos-ul">
        <li class="cos-li">the vehicle becomes grounded, e.g. on a high kerb or an unpaved road</li>
        <li class="cos-li">the vehicle is driven too fast over an obstacle, e.g. a kerb, speed bump or pothole</li>
        <li class="cos-li">a heavy object strikes the underbody or suspension components</li>
      </ul>
    </div>
    <div class="cos-box-defense cos-box-damage-defense">
      <div class="cos-step">
        <div class="cos-action">Have the vehicle checked and repaired immediately at a qualified specialist workshop.</div>
      </div>
      <div class="cos-step">
        <div class="cos-action">If driving safety is impaired while continuing your journey, pull over and stop the vehicle immediately, while paying attention to road and traffic conditions, and contact a qualified specialist workshop.</div>
      </div>
    </div>
  </div>
</div>`;

  // function MoreHeader() {
  //   const history = useHistory();
  //   return (
     
  //     <div className="manual-parent-container">
          
  //       {/* <div className="img-container-more">
  //         <img
  //           className="benz-logo-image-more"
  //           // src="assets/images/Wortmarke_Stern-3.svg"
  //           src={
  //             process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
  //           }
  //           alt="Benz Logo"
  //         ></img>
  //       </div> */}

  //       <DesktopBreakpoint>
  //         {state.isVINAvailable ? (
  //           <div className="manual-benz-logo-container"></div>
  //         ) : (
  //           <div className="manual-benz-logo-container">
  //             <img
  //               className="benz-logo-image"
  //               src={
  //                 process.env.PUBLIC_URL +
  //                 "/assets/images/Wortmarke_Stern-3.svg"
  //               }
  //               alt="Benz Logo"
  //             ></img>
  //           </div>
  //         )}
  //         <div className="right-container">
  //           <div className="right-top-container">
  //             <a
  //               // href={refLink}
  //               id="homelink-mercedes"
  //               className="homelink-mercedes-manual"
  //               target="_blank"
  //               rel="noopener"
  //             >
  //               <img
  //                 src={process.env.PUBLIC_URL + "/assets/images/info_icon.png"}
  //                 // src="assets/images/info_icon.png"
  //                 className="link-image-in-manual"
  //                 alt="privacy-icon"
  //               ></img>
  //               <span className="homelink-name-manual"></span>
  //             </a>
  //             <a
  //               onClick={() => {
  //                 history.goBack();
  //               }}
  //             >
  //               <img
  //                 // src="assets/images/icon-back.png"
  //                 src={process.env.PUBLIC_URL + "/assets/images/icon-back.png"}
  //                 // className="link-image-in-manual link-image-phone"
  //                 className={`link-image-in-manual link-image-phone ${
  //                   state.isVINAvailable ? "hmi-navigation-icons" : ""
  //                 }`}
  //                 alt="home-icon"
  //               ></img>
  //             </a>
  //             <a
  //               onClick={() => {
  //                 state.isVINAvailable
  //                   ? history.push("/truckownermanuals/tiled-items")
  //                   : history.push("/truckownermanuals");
  //               }}
  //             >
  //               <img
  //                 // src="assets/images/to_home.png"
  //                 src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
  //                 // className="link-image-in-manual link-image-phone"
  //                 className={`link-image-in-manual link-image-phone ${
  //                   state.isVINAvailable ? "hmi-navigation-icons" : ""
  //                 }`}
  //                 alt="home-icon"
  //               ></img>
  //             </a>
  //           </div>
  //         </div>
  //       </DesktopBreakpoint>
  //       <TabletBreakpoint>
  //         {state.isVINAvailable ? (
  //           <div className="tab-phone-logo-container"></div>
  //         ) : (
  //           <div className="tab-phone-logo-container">
  //             <div className="manual-benz-logo-container">
  //               <img
  //                 className="benz-logo-image"
  //                 src={
  //                   process.env.PUBLIC_URL +
  //                   "/assets/images/Wortmarke_Stern-3.svg"
  //                 }
  //                 alt="Benz Logo"
  //               ></img>
  //             </div>
  //           </div>
  //         )}
  //         <div className="tab-phone-menu-container">
  //           <div className="link-stack-manual">
  //             <div>
  //               <a
  //                 href="https://www.evobus.com/de-de/menus/footer/anbieter/"
  //                 className="homelink-mercedes"
  //                 target="_blank"
  //                 rel="noopener"
  //               >
  //                 <img
  //                   src={
  //                     process.env.PUBLIC_URL + "/assets/images/info_icon.png"
  //                   }
  //                   className="link-image-tiled-page"
  //                   alt="privacy-icon"
  //                 ></img>
  //               </a>
  //               <a
  //                 onClick={() => {
  //                   history.goBack();
  //                 }}
  //               >
  //                 <img
  //                   src={
  //                     process.env.PUBLIC_URL + "/assets/images/icon-back.png"
  //                   }
  //                   className="link-image-in-manual link-image-phone"
  //                   alt="home-icon"
  //                 ></img>
  //               </a>
  //               <a
  //                 onClick={() => {
  //                   history.push("/truckownermanuals");
  //                 }}
  //               >
  //                 <img
  //                   src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
  //                   className="link-image-in-manual link-image-phone"
  //                   alt="home-icon"
  //                 ></img>
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </TabletBreakpoint>
  //       <PhoneBreakpoint>
  //         <div className="tab-phone-logo-container">
  //           <div className="manual-benz-logo-container">
  //             <img
  //               className="benz-logo-image"
  //               src={
  //                 process.env.PUBLIC_URL +
  //                 "/assets/images/Wortmarke_Stern-3.svg"
  //               }
  //               alt="Benz Logo"
  //             ></img>
  //           </div>
  //         </div>
  //         <div className="tab-phone-menu-container">
  //           <div className="link-stack-manual">
  //             <div>
  //               <a
  //                 href="https://www.evobus.com/de-de/menus/footer/anbieter/"
  //                 className="homelink-mercedes"
  //                 target="_blank"
  //                 rel="noopener"
  //               >
  //                 <img
  //                   src={
  //                     process.env.PUBLIC_URL + "/assets/images/info_icon.png"
  //                   }
  //                   className="link-image-tiled-page"
  //                   alt="privacy-icon"
  //                 ></img>
  //               </a>
  //               <a
  //                 onClick={() => {
  //                   history.goBack();
  //                 }}
  //               >
  //                 <img
  //                   src={
  //                     process.env.PUBLIC_URL + "/assets/images/icon-back.png"
  //                   }
  //                   className="link-image-in-manual link-image-phone"
  //                   alt="home-icon"
  //                 ></img>
  //               </a>
  //               <a
  //                 onClick={() => {
  //                   history.push("/truckownermanuals");
  //                 }}
  //               >
  //                 <img
  //                   src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
  //                   className="link-image-in-manual link-image-phone"
  //                   alt="home-icon"
  //                 ></img>
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </PhoneBreakpoint>
  //     </div>
  //   );
  // }

  function handleCallback(isActive: any) {
    setIsLoaderActive(isActive);
  }

  return (
    <IonPage>
      <IonContent
        className="more-manual-content"
        fullscreen
        ref={contentRef}
        scrollEvents={true}
      >
        <LoadingOverlay active={isLoaderActive} spinner>
         <CommonHeaderComponent />
          <MoreContent parentCallback={handleCallback} />
          {state.isVINAvailable ? (
            ""
          ) : (
            <IonFooter class="ionic-footer-in-more-content">
              <span className="footer-separator-in-more"></span>
              <div className="more-content-footer-container">
                <FooterContainer onClickScrollToTop={scrollToTop} />
              </div>
            </IonFooter>
          )}
        </LoadingOverlay>
      </IonContent>
    </IonPage>
  );
};

export default MoreManualContent;
