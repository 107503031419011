import { IonContent, IonPage, IonFooter, IonButton } from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../State";
import { hmiErrorTranslations } from "../../Utils/HMIErrorTranslations";
import "./ErrorPage.css";

const ErrorPage: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(AppContext);
  const isHMIError = localStorage.getItem("HMIError");
  var error = `Error 500`; 
  let hmiError="";
  if(isHMIError){
    const hmiLang = localStorage.getItem("hmiSystemLanguage")?.split('_')[0];
    hmiError = hmiErrorTranslations[hmiLang].split('.');
    console.log("hmiError", hmiLang, hmiError);
  }

  return (
    <IonPage>
      <IonContent
        className="error-page"
        fullscreen
        scrollEvents={true}
      ></IonContent>
      <div className="error-parent-container">
        <div className="error-centre-container">
          <h1 className="error-title">{error}</h1>
          <img
            className="repair-img"
            src={process.env.PUBLIC_URL + "/assets/images/error.png"}
            // src="assets/images/error.png"
          ></img>
          <h3 className="error-h3">{isHMIError ? hmiError[0] : "Ooops... Something went wrong."}</h3>
          <h5 className="error-h5">{isHMIError ? hmiError[1] : "Sorry for the inconvenience, we're working on it."}</h5>
          {isHMIError && <h5 className="error-h5">{hmiError[2]}</h5>}
          {!isHMIError && <IonButton
            className="go-back-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </IonButton>}
        </div>
      </div>
    </IonPage>
  );
};

export default ErrorPage;