import React, { createContext, useReducer } from "react";

interface ContextType {
  state?: any;
  dispatch?: any;
}

const initialAppContext: ContextType = {};

let AppContext = createContext(initialAppContext);

const initialState = {
  selectedManualIndex: 0,
  selectedTruckModelIndex: 0,
  isLeftMenuClicked: 0,
  selectedLeftMenuTitle: "Title",
  selectedLanguageCode: localStorage.getItem("langCode") || "en_GB",
  selectedTileID: parseInt(localStorage.getItem("tileID"), 10) || 0,
  isChildItemPresent:
    localStorage.getItem("isChildItemPresent") == "true" || false,
  isModelAvailable: localStorage.getItem("isModelAvailable") == "true" || false,
  isOldModel: localStorage.getItem("isOldModel") == "true" || false,
  numberOfLevelsForItem: 2,
  orderOfSelectedItem: 0,
  selectedVehicleName: localStorage.getItem("vehicleName") || "",
  selectedVehicleModel: localStorage.getItem("vehicleModel") || "NA",
  selectedConstructionPeriod:
    JSON.parse(localStorage.getItem("constructionPeriod")) || "",
  selectedCockpitValue:
    JSON.parse(localStorage.getItem("cockpit")) || "",
  selectedLeftMenuID: +localStorage.getItem("leftMenuID") || 0,
  selectedLanguageModel: JSON.parse(
    localStorage.getItem("languageModelObject")
  ) || {
    value: 0,
    label: "English (GB)",
    isDisabled: false,
  },

  selectedFamilyModel: JSON.parse(localStorage.getItem("familyObject")) || null,

  selectedVehicleTypeModel: localStorage.getItem("vehicleTypeObject")
    ? JSON.parse(localStorage.getItem("vehicleTypeObject")) || null
    : "",

  selectedDocumentTypeModel:
    JSON.parse(localStorage.getItem("documentTypeObject")) || null,

  selectedDocumentLanguageModel:
    JSON.parse(localStorage.getItem("documentLanguageObject")) || null,

  childItemsForSelectedID:
    JSON.parse(localStorage.getItem("childItemObj")) || [],

  selectedManualLanguageCode: localStorage.getItem("manualLangCode") || "en_GB",

  isPDFSelected: localStorage.getItem("isPDFSelected") == "true" || false,

  selectedActualPDFFileName: localStorage.getItem("actualPDFFileName") || "",

  searchedQueryInManual: "",

  manualSearchFieldValueControl: 0,

  isLevelOneItemSelected:
    localStorage.getItem("isLevelOneItemSelected") == "true" || false,

  selectedLevelHierarchy: JSON.parse(
    localStorage.getItem("selectedLevelHierarchy")
  ) || ["", "", "", "", ""],

  fossPDFDetail: {
    id: 0,
    fileName: "",
    fileFormat: "",
    fileSize: "",
    createdAt: "",
    updatedAt: "",
    filePath: "",
  },

  radioPDFDetail: {
    id: 0,
    fileName: "",
    fileFormat: "",
    fileSize: "",
    createdAt: "",
    updatedAt: "",
    filePath: "",
  },

  batteryPDFDetail: {
    id: 0,
    fileName: "",
    fileFormat: "",
    fileSize: "",
    createdAt: "",
    updatedAt: "",
    filePath: "",
  },

  declarationPDFDetail: {
    id: 0,
    fileName: "",
    fileFormat: "",
    fileSize: "",
    createdAt: "",
    updatedAt: "",
    filePath: "",
  },

  tiledItemsData: JSON.parse(localStorage.getItem("tiledItemsData")) || [],

  apiErrorCode: "500",

  isManualLoaderActive: false,

  isHomeLoaderActive: false,

  isAppLangChanged: localStorage.getItem("isAppLangChanged") == "true" || false,

  selectedMoreContent: localStorage.getItem("selectedMoreContent") || "",

  isNavigatedFromSearch:
    localStorage.getItem("isNavigatedFromSearch") == "true" || false,

  selectedSearchedParents:
    JSON.parse(localStorage.getItem("selectedSearchedParents")) || [],

  selectedSearchID: parseInt(localStorage.getItem("searchID"), 10) || 0,

  documentSetObject: localStorage.getItem("documentSetObject")
    ? localStorage.getItem("documentSetObject") || []
    : "",
  isLeftMenuHiddenInPhone: true,

  isVINAvailable: false,
  vehicleVIN: "",
  vehicleInfoBasedOnVIN: {},
};

let reducer = (state, action) => {
  switch (action.type) {
    case "setSelectedManualIndex": {
      return {
        ...state,
        selectedManualIndex: action.payload.selectedManualIndex,
      };
    }

    case "setSelectedTruckModel": {
      return {
        ...state,
        selectedTruckModelIndex: action.payload.selectedTruckModelIndex,
      };
    }

    case "setLeftMenuClickFlag": {
      return { ...state, isLeftMenuClicked: action.payload.isLeftMenuClicked };
    }

    case "setSelectedItemTitle": {
      return {
        ...state,
        selectedLeftMenuTitle: action.payload.selectedLeftMenuTitle,
      };
    }

    case "setSelectedLanguageCode": {
      return {
        ...state,
        selectedLanguageCode: action.payload.selectedLanguageCode,
      };
    }

    case "setSelectedTileID": {
      return { ...state, selectedTileID: action.payload.selectedTileID };
    }

    case "setIsChildItemsPresent": {
      return {
        ...state,
        isChildItemPresent: action.payload.isChildItemPresent,
      };
    }

    case "setNumberOfLevelsForItem": {
      return {
        ...state,
        numberOfLevelsForItem: action.payload.numberOfLevelsForItem,
      };
    }

    case "setOrderOfSelectedItem": {
      return {
        ...state,
        orderOfSelectedItem: action.payload.orderOfSelectedItem,
      };
    }

    case "setSelectedVehicleName": {
      return {
        ...state,
        selectedVehicleName: action.payload.selectedVehicleName,
      };
    }

    case "setSelectedVehicleModel": {
      return {
        ...state,
        selectedVehicleModel: action.payload.selectedVehicleModel,
      };
    }

    case "setSelectedConstructionPeriod": {
      return {
        ...state,
        selectedConstructionPeriod: action.payload.selectedConstructionPeriod,
      };
    }

    case "setSelectedCockpitValue": {
      return {
        ...state,
        selectedCockpitValue: action.payload.selectedCockpitValue,
      };
    }

    case "setSelectedLeftMenuID": {
      return {
        ...state,
        selectedLeftMenuID: action.payload.selectedLeftMenuID,
      };
    }

    case "setSelectedLanguageModel": {
      return {
        ...state,
        selectedLanguageModel: action.payload.selectedLanguageModel,
      };
    }

    case "setChildItemForSelectedTileID": {
      return {
        ...state,
        childItemsForSelectedID: action.payload.childItemsForSelectedID,
      };
    }

    case "setSelectedManualLanguageCode": {
      return {
        ...state,
        selectedManualLanguageCode: action.payload.selectedManualLanguageCode,
      };
    }

    case "setSelectedFamilyModel": {
      return {
        ...state,
        selectedFamilyModel: action.payload.selectedFamilyModel,
      };
    }

    case "setSelectedVehicleTypeModel": {
      return {
        ...state,
        selectedVehicleTypeModel: action.payload.selectedVehicleTypeModel,
      };
    }

    case "setSelectedDocumentTypeModel": {
      return {
        ...state,
        selectedDocumentTypeModel: action.payload.selectedDocumentTypeModel,
      };
    }

    case "setSelectedDocumentLanguageModel": {
      return {
        ...state,
        selectedDocumentLanguageModel:
          action.payload.selectedDocumentLanguageModel,
      };
    }

    case "setIsPDFSelected": {
      return { ...state, isPDFSelected: action.payload.isPDFSelected };
    }

    case "setSelectedActualPDFFileName": {
      return {
        ...state,
        selectedActualPDFFileName: action.payload.selectedActualPDFFileName,
      };
    }

    case "setSearchedQueryInManual": {
      return {
        ...state,
        searchedQueryInManual: action.payload.searchedQueryInManual,
      };
    }

    case "setManualSearchFieldValueControl": {
      return {
        ...state,
        manualSearchFieldValueControl:
          action.payload.manualSearchFieldValueControl,
      };
    }

    case "setIsLevelOneItemSelected": {
      return {
        ...state,
        isLevelOneItemSelected: action.payload.isLevelOneItemSelected,
      };
    }

    case "setSelectedLevelHierarchy": {
      return {
        ...state,
        selectedLevelHierarchy: action.payload.selectedLevelHierarchy,
      };
    }

    case "setFossPDFDetail": {
      return { ...state, fossPDFDetail: action.payload.fossPDFDetail };
    }

    case "setRadioPDFDetail": {
      return { ...state, radioPDFDetail: action.payload.radioPDFDetail };
    }

    case "setBatteryPDFDetail": {
      return { ...state, batteryPDFDetail: action.payload.batteryPDFDetail };
    }

    case "setDeclarationPDFDetail": {
      return { ...state, declarationPDFDetail: action.payload.declarationPDFDetail };
    }


    case "setTiledItemsData": {
      return { ...state, tiledItemsData: action.payload.tiledItemsData };
    }

    case "setApiErrorCode": {
      return { ...state, apiErrorCode: action.payload.apiErrorCode };
    }
         
    case "setIsManualLoaderActive": {
      return {
        ...state,
        isManualLoaderActive: action.payload.isManualLoaderActive,
      };
    }

    case "setIsHomeLoaderActive": {
      return {
        ...state,
        isHomeLoaderActive: action.payload.isHomeLoaderActive,
      };
    }

    case "setIsAppLangChanged": {
      return { ...state, isAppLangChanged: action.payload.isAppLangChanged };
    }

    case "setSelectedMoreContent": {
      return {
        ...state,
        selectedMoreContent: action.payload.selectedMoreContent,
      };
    }

    case "setIsNavigatedFromSearch": {
      return {
        ...state,
        isNavigatedFromSearch: action.payload.isNavigatedFromSearch,
      };
    }

    case "setSelectedSearchID": {
      return { ...state, selectedSearchID: action.payload.selectedSearchID };
    }

    case "setSelectedSearchedParents": {
      return {
        ...state,
        selectedSearchedParents: action.payload.selectedSearchedParents,
      };
    }

    case "setIsModelAvailable": {
      return { ...state, isModelAvailable: action.payload.isModelAvailable };
    }

    case "setIsOldModel": {
      return { ...state, isOldModel: action.payload.isOldModel };
    }

    case "setDocumentSetObject": {
      return { ...state, documentSetObject: action.payload.documentSetObject };
    }

    case "setIsLeftMenuHiddenInPhone": {
      return {
        ...state,
        isLeftMenuHiddenInPhone: action.payload.isLeftMenuHiddenInPhone,
      };
    }
    case "setisVINAvailable": {
      return { ...state, isVINAvailable: action.payload.isVINAvailable };
    }
    case "setVehicleVIN": {
      return { ...state, vehicleVIN: action.payload.vehicleVIN };
    }
    case "setVehicleInfoBasedOnVIN": {
      return {
        ...state,
        vehicleInfoBasedOnVIN: action.payload.vehicleInfoBasedOnVIN,
      };
    }

    default:
      break;
  }
};

function AppContextProvider(props) {
  const appState = {
    ...initialState,
  };

  let [state, dispatch] = useReducer(reducer, appState);

  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

export { AppContext, AppContextProvider };
