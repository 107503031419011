import "./HmiPopup.css";
import { poupTranslations } from "../../Utils/HmiPopupTranslations";
// import Cookies from 'js-cookie';
// import { useEffect, useState } from "react";

const HmiPopup = ({setIsPopupVisible, lang}) => {
  console.log("popup translation", poupTranslations[lang]);
  // const [cookieVal, setCookieVal] = useState('');

  // useEffect(() => {
  //   let cookie = Cookies.get('timestamp');
  //   if(!cookie) {
  //     cookie = Date.now();
  //     Cookies.set('timestamp', cookie, { expires: 7 });
  //   }  
  //   setCookieVal(cookie);
  // },[])

  return (
    <div className="popupContainer">
      <div className="popupDailogBox">
        <div className="popupText">{poupTranslations[lang] || poupTranslations["en"]}</div>
        <button className="popupButton" onClick={() => (setIsPopupVisible(false))}>Okay</button>
      </div>
    </div>
  );
};

export default HmiPopup;