import Collapsible from "react-collapsible";
import "./PDFCollapsiblePanel.css";
import { tableData } from "../../Helpers/ConformityTableData";

interface ContainerProps {}

const PDFCollapsiblePanel: React.FC<ContainerProps> = () => {
  return (
    <div className="container-pdf-collap">
      <Collapsible
        trigger={
          <div className="pdf-collap-header">
            Installation and use of RF transmitters
            <div className="pdf-collap-header-close"></div>
          </div>
        }
        triggerWhenOpen={
          <div className="pdf-collap-header">
            Installation and use of RF transmitters
            <div className="pdf-collap-header-close pdf-collap-header-open"></div>
          </div>
        }
      >
        <div className="pdf-collap-content">
          <div className="pdf-collap-t1">
            Tabelle für Installation und Gebrauch von RF-Sendern im (in den)
            Fahrzeug(en), falls zutreffend (siehe Anhang I, 3.1.8.):
          </div>
          <div className="pdf-collap-t1">
            Table of installation and use of RF transmitters in the vehicle(s),
            if applicable (see Annex I, 3.1.8.):
          </div>
          <div className="collap-tabular-content">
            {tableData.map((item, index) => {
              var tableDataLength = tableData.length;
              var tableCellClassName =
                index != 0
                  ? "container-t3"
                  : "container-t3 container-t3-header";
              return (
                <div className="table-main-container" key={index}>
                  <div className="container-t2">
                    <div className={tableCellClassName}>{item.frequency}</div>
                    <div className={tableCellClassName}>{item.power}</div>
                    <div className={tableCellClassName}>{item.description}</div>
                  </div>
                  {index != tableDataLength - 1 ? (
                    <span className="table-separator"></span>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default PDFCollapsiblePanel;
