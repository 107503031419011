import "./TruckModelsParentContainer.css";
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from "react-select";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import apiClient from "../../services/http-common";
import { AppContext } from "../../State";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router-dom";
import Linkify from "react-linkify";
import logger from "../../Utils/logger";
interface ContainerProps {}

interface ComboBoxProps {
  isLoading: boolean;
  onChange: any;
}

type DropDownControl = {
  isOpenButtonVisible: boolean;
  isModelAvailable: boolean;
  isPDFSelected: boolean;
  isLanguageSelectionBoxVisible: boolean;
  isDocumentTypeDisabled: boolean;
  isRightContentHidden: boolean;
  isConstructionPeriodBoxVisible: boolean;
  isDocumentFieldVisible: boolean;
  isCockpitAvailable: boolean;
}[];

type SelectedDropDownItems = {
  family: string;
  model: string;
  documentType: string;
  language: string;
  pdfFileName: string;
  pdfLangCode: string;
  constructionPeriod: string;
  cockpit: string;
};

type SelectMenuListItems = {
  label: string;
  value: number;
  isDisabled: boolean;
}[];

type TitleAndDescriptionTranslationInfo = {
  truckGuides: string;
  guideDescription: string;
  mobileDescriptionOne: string;
  mobileDescriptionTwo: string;
  noteText: string;
};

type ContainerTranslationInfo = {
  familyPlaceholder: string;
  modelPlaceholder: string;
  documentTypePlaceholder: string;
  languagePlaceholder: string;
  openBtnText: string;
  constructionPeriod: string;
  cockpit: string,
  vechicleNameTranslations: object;
  vechicleModelTranslations: object;
  vechileDocTypeTranslations: object;
  vehicleLanguageTranslations: object;
};

const initialDropDownDisableControl = [
  {
    isOpenButtonVisible: false,
    isModelAvailable: false,
    isPDFSelected: false,
    isLanguageSelectionBoxVisible: false,
    isDocumentTypeDisabled: true,
    isRightContentHidden: true,
    isConstructionPeriodBoxVisible: false,
    isDocumentFieldVisible: true,
    isCockpitAvailable: false,
  },
];

const initialSelectedDropDownItems: SelectedDropDownItems = {
  family: "",
  model: "",
  documentType: "",
  language: "",
  pdfFileName: "",
  pdfLangCode: "",
  constructionPeriod: "",
  cockpit: "",
};

const initialTranslationContent: ContainerTranslationInfo = {
  familyPlaceholder: "Family",
  modelPlaceholder: "Version",
  documentTypePlaceholder: "Document Type",
  languagePlaceholder: "Language",
  openBtnText: "Open",
  constructionPeriod: "Construction Period",
  cockpit: "Cockpit",
  vechicleNameTranslations: {},
  vechicleModelTranslations: {},
  vechileDocTypeTranslations: {},
  vehicleLanguageTranslations: {},
};

const initialVehicleData = [
  { vehicleName: "ZETROS", vehicleModel: ["HYBRID"] },
];

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 50,
    minHeight: 50,
    borderRadius: 2,
    boxShadow: "none",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  dropdownIndicator: (styles: any) => ({
    ...styles,
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 20,
  }),

  menu: (styles: any) => ({
    ...styles,
    padding: "0px 0px  0px 10px",
    scrollPadding: "50px 0 0 50px",
  }),

  option: (styles: any, { isDisabled, isSelected }) => ({
    ...styles,
    borderBottom: "1px solid #a8a8a8",
    fontSize: "18px",
    fontFamily: "MBCorpoSText-Regular",
    borderSpacing: "100px",
    color: isSelected ? "#ffffff" : "#000000",
    opacity: isDisabled ? "0.55" : "1",
    fontStyle: isDisabled ? "italic" : "regular",
  }),

  placeholder: (styles: any, { isDisabled }) => ({
    ...styles,
    fontSize: "18px",
    fontFamily: "MBCorpoSText-Regular",
    color: isDisabled ? "#e1dddd" : "black",
  }),
};

const Family = [{ label: "Family", value: 0, isDisabled: true }];

const DocumentType = [{ label: "Document Type", value: 0, isDisabled: true }];

const ModelType = [{ label: "Model", value: 0, isDisabled: true }];

const Cockpit = [
  { label: "Cockpit", value: 0, isDisabled: true },
];

const ConstructionPeriod = [
  { label: "Construction Period", value: 0, isDisabled: true },
];

const Language = [{ label: "Language", value: 0, isDisabled: true }];

// API response model of Document Details
// let docTypeResponse = [
//   {
//     filename: "Maintenance booklet",
//     lang_code: ["de_DE", "fr_FR", "en_GB"],
//     language: ["Deutsch", "French", "English(GB)"],
//     actualFileName: [
//       "WH_Zetros_de_DE_Content.pdf",
//       "WH_Zetros_fr_FR_Content.pdf",
//       "WH_Zetros_en_GB_Content.pdf",
//     ],
//   },
//   {
//     filename: "Operating instructions",
//     lang_code: ["en_GB", "fr_FR", "de_DE"],
//     language: ["English(GB)", "French", "Deutsch"],
//     actualFileName: [
//       "BA_Zetros_en_GB_Content.pdf",
//       "BA_Zetros_fr_FR_Content.pdf",
//       "BA_Zetros_de_DE_Content.pdf",
//     ],
//   },
//   {
//     filename: "Truck Guide(HTML)",
//     lang_code: ["en_GB", "de_DE", "fr_FR"],
//     language: ["English(GB)", "Deutsch", "French"],
//     actualFileName: ["None", "None", "None"],
//   },
// ];

const ModelSelectionContainer: React.FC<ComboBoxProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    handlePageRefresh();
  }, []);

  useEffect(() => {
    getTranslations();
  }, [state.selectedLanguageCode]);

  function changeloadingState(value: boolean) {
    // Here, we invoke the callback with the new value
    props.onChange(value);
  }

  const [dropDownDisableControl, setDropDownDisableControl]: [
    DropDownControl,
    Dispatch<SetStateAction<DropDownControl>>
  ] = useState(initialDropDownDisableControl);

  const [selectedDropDownItem, setSelectedDropDownItem]: [
    SelectedDropDownItems,
    Dispatch<SetStateAction<SelectedDropDownItems>>
  ] = useState(initialSelectedDropDownItems);
  const [selectedItemExists, setSelectedItemExists] = useState(true);
  const [familyListItems, setFamilyListItems]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(Family);

  const [modelListItems, setModelListItems]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(ModelType);

  const [cockpitValues, setCockpitValues]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(Cockpit);

  const [constructionPeriodListItems, setConstructionPeriodListItems]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(ConstructionPeriod);

  const [documentTypeListItems, setDocumentTypeListItems]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(DocumentType);

  const [languageListItems, setLanguageListItems]: [
    SelectMenuListItems,
    Dispatch<SetStateAction<SelectMenuListItems>>
  ] = useState(Language);

  const [selectedDocIndex, setSelectedDocIndex] = useState(0);

  const [vehicleFamilyData, setVehicleFamilyData] =
    useState(initialVehicleData);

  const [translationInfo, setTranslationInfo]: [
    ContainerTranslationInfo,
    Dispatch<SetStateAction<ContainerTranslationInfo>>
  ] = useState(initialTranslationContent);

  const [documentObj, setDocumentObj]: [any, any] = useState([
    {
      filename: "Truck Guide(HTML)",
      actualFileName: "None",
      lang_code: "en_GB",
      language: "English",
    },
  ]);

  const [selectedDocObjOrder, setSelectedDocObjOrder]: [any, any] = useState(0);
  const [pdfFileName, setPdfFileName] = useState<any>("");
  const [newLabel, setNewLabel] = useState<any>("");
  useEffect(() => {
    if (
      state.documentSetObject == "undefined" ||
      state.documentSetObject == undefined
    ) {
      logger.info("documentSetObject", state.documentSetObject);
      localStorage.clear();
    }
  }, [state.documentSetObject == "undefined"]);
  function handlePageRefresh() {
    var dropDownControl = dropDownDisableControl;
    var selectedItems = selectedDropDownItem;

    var docType =
      state.documentSetObject != undefined ? state.documentSetObject : [];
    dropDownControl[0].isPDFSelected = state.isPDFSelected;
    if (localStorage.getItem("familyObject") != null) {
      selectedItems.family = state.selectedFamilyModel?.label || "";
      if (state.isModelAvailable) {
        if (state.isOldModel) {
          dropDownControl[0].isCockpitAvailable = true
          dropDownControl[0].isConstructionPeriodBoxVisible = true;

          selectedItems.cockpit = state.selectedCockpitValue.label;
          getCockpitValues(
            state.selectedFamilyModel?.label,
            state?.selectedVehicleTypeModel?.label
          )
          if (selectedItems.cockpit != "") {
            dispatch({
              type: "setSelectedCockpitValue",
              payload: {
                selectedCockpitValue: state.selectedCockpitValue,
              },
            });
          }

          selectedItems.constructionPeriod =
            state.selectedConstructionPeriod.label;
          getConstructionPeriods(
            state.selectedFamilyModel?.label,
            state?.selectedVehicleTypeModel?.label
          );
          if (selectedItems.constructionPeriod != "") {
            dispatch({
              type: "setSelectedConstructionPeriod",
              payload: {
                selectedConstructionPeriod: state.selectedConstructionPeriod,
              },
            });
          }
        }

        dropDownControl[0].isModelAvailable = true;
        if (localStorage.getItem("vehicleTypeObject") != null) {
          selectedItems.model = state?.selectedVehicleTypeModel?.label || "";
          dropDownControl[0].isDocumentTypeDisabled = false;
          // getDocumentDetails(selectedItems.family, selectedItems.model);
          setDocumentObj(docType);
        } else {
          dropDownControl[0].isDocumentTypeDisabled = true;
        }
      } else {
        dropDownControl[0].isDocumentTypeDisabled = false;
        selectedItems.model = "NA";
        setDocumentObj(docType);
        // getDocumentDetails(selectedItems.family, "NA");
      }
    }
    if (state.isOldModel && selectedItems.constructionPeriod == undefined) {
      dropDownControl[0].isDocumentTypeDisabled = true;
    }
    if (localStorage.getItem("documentTypeObject") != null) {
      dropDownControl[0].isRightContentHidden = false;
      dropDownControl[0].isLanguageSelectionBoxVisible = true;
      selectedItems.documentType = state.selectedDocumentTypeModel.label || "";
      selectedItems.pdfFileName = state.selectedActualPDFFileName || "";
      var selectedDocIndex =
        JSON.parse(localStorage.getItem("documentTypeObject") || "").value - 1;
      logger.info("sdi", selectedDocIndex, "docType", docType);
      docType = JSON.parse(docType);
      if (docType != undefined) {
        var langModel = docType[selectedDocIndex]?.language?.map(
          (data: any, index: number) => ({
            value: index + 1,
            label: data,
            isDisabled: false,
          })
        );
        langModel = langModel.sort((i1, i2) =>
          i1.label > i2.label ? 1 : -1
        );
        if (langModel) {
          setLanguageListItems([
            {
              value: 0,
              label: translationInfo.languagePlaceholder,
              isDisabled: true,
            },
            ...langModel,
          ]);
        }
      }
    }

    if (localStorage.getItem("documentLanguageObject") != null) {
      dropDownControl[0].isOpenButtonVisible = true;
      selectedItems.pdfLangCode = state.selectedManualLanguageCode || "";
      selectedItems.language = state.selectedDocumentLanguageModel.label || "";
    }

    setDropDownDisableControl({ ...dropDownControl });

    setSelectedDropDownItem({ ...selectedItems });
    logger.info("Saved selected dropdown items", selectedDropDownItem);
  }

  async function getDocumentDetails(
    family: string,
    model: string,
    constructionPeriod: string = "",
    cockpit: string="",
  ) {
    changeloadingState(true);
    try {
      let res;
      var translationLangCode = localStorage.getItem("langCode");
      if (
        model.indexOf("Current") !== -1 ||
        model.indexOf("Aktuelle") !== -1 ||
        model === "NA"
      ) {
        res = await apiClient.get(
          `/retrivergetDocumentType/${family}/${encodeURIComponent(model)}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
        // } else if (constructionPeriod == "") {
        //   logger.info("Construction Period---->", constructionPeriod);
      }else if(model==="963-964"){
        res = await apiClient.get(
          `/retrivergetDocumentTypeForCockpit/${family}/${encodeURIComponent(
            model
          )}/${encodeURIComponent(constructionPeriod)}/${encodeURIComponent(cockpit)}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
      } else {
        //api call for document type
        res = await apiClient.get(
          `/retrivergetDocumentTypeForOldModel/${family}/${encodeURIComponent(
            model
          )}/${encodeURIComponent(constructionPeriod)}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
      }
      var data: any;
      data = res.data?.documentType;
      logger.info("getDocumentType: ", data);
      console.log("EnglishVehicleName", res.data.vehicleName);
      localStorage.setItem("EnglishVehicleName", res.data.vehicleName);
      localStorage.setItem("EnglishVehicleModel", res.data.vehicleModel);
      // data = docTypeResponse;
      setDocumentObj(data);
      dispatch({
        type: "setDocumentSetObject",
        payload: {
          documentSetObject: data ? data : "",
        },
      });
      // console.log("SELECTED INDEXXXXXXXX OUTSIDE", selectedDocIndex);
      localStorage.setItem(
        "actualPDFFileName",
        data[selectedDocIndex].actualFileName[
          newLabel.value || localStorage.getItem("familyName")
        ]
      );
      if (model) {
        let match = data[selectedDocIndex].actualFileName.filter((pdflang) =>
          pdflang.includes(selectedDropDownItem.pdfLangCode)
        );
        logger.info("match", match);
        let newObj = {
          ...selectedDropDownItem,
          pdfFileName: data[selectedDocIndex].actualFileName.filter((pdflang) =>
            pdflang.includes(selectedDropDownItem.pdfLangCode)
          ),
        };
        logger.info("newObj", newObj);
        setSelectedDropDownItem(newObj);
      }
      setPdfFileName(data[selectedDocIndex].actualFileName[newLabel.value]);
      localStorage.setItem("documentSetObject", JSON.stringify(data));
      var responseItems: any;
      responseItems = data.map((d, index: number) => ({
        value: index + 1,
        label: d.filename,
        isDisabled: false,
      }));
      setDocumentTypeListItems([
        {
          value: 0,
          label: translationInfo.documentTypePlaceholder,
          isDisabled: true,
        },
        ...responseItems,
      ]);

      //language DropDown
      logger.info("Language1: ", languageListItems);
      setLanguageListItems([
        {
          value: 0,
          label: translationInfo.languagePlaceholder,
          isDisabled: true,
        },
        ...languageListItems,
      ]);
      changeloadingState(false);
      logger.info(`Document details ---- `, documentTypeListItems);
    } catch (err: any) {
      changeloadingState(false);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      //       history.push("/truckownermanuals/error");
      logger.info(`Document Details API error ${err}`);
    }
    // extracting version from the actual filename
    let actualFileNameArray;
    data.forEach((element) => {
      if (
        element.filename != "Truck Guide(HTML)" 
      ){
        actualFileNameArray = element.actualFileName;
        return;
      }
    });
    console.log("FILENAME ARRAY", actualFileNameArray)
    let actualFileName = actualFileNameArray.find((actualFileName) =>
      /^[^.]*\.[^.]*\.[^.]*$/.test(actualFileName)
    );
    actualFileName = actualFileName ? actualFileName : "";
    let version =
      actualFileName.split("_")[3]?.indexOf(".") != -1
        ? actualFileName.split("_")[3]
        : actualFileName.split("_")[4];
    // version.indexOf('.') != -1 ? localStorage.setItem("version", `Version: ${version}`) : localStorage.setItem("version", "");
    const monthYearRegex = /^\d{2}\.\d{4}$/;
    if (monthYearRegex.test(version)) {
      localStorage.setItem("version", `Version: ${version}`);
    } else {
      localStorage.setItem("version", "");
    }
    // localStorage.setItem("version", "05.2023");
    return data;
  }

  // fetches the construction periods based on the family and old model
  // async function getConstructionPeriods(family: string, model: string) {
  //   changeloadingState(true);

  //   // api call
  //   let res;

  //   let vechileModel;
  //   if (model == "ältere modell") {
  //     vechileModel = "Older model";
  //   }
  //   if (family == "Unimog Geräteträger") {
  //     res = await apiClient.get(
  //       `/retrivergetCondtructionPeriodiData/Unimog%20implement%20carrier/${encodeURIComponent(
  //         "Older model"
  //       )}`
  //     );
  //   } else {
  //     if (state.selectedLanguageCode == "de_DE" && vechileModel != undefined) {
  //       // debugger;
  //       res = await apiClient.get(
  //         `/retrivergetCondtructionPeriodiData/${family}/${encodeURIComponent(
  //           vechileModel
  //         )}`
  //       );
  //     } else {
  //       let vModel;
  //       if (model == "ältere modell") {
  //         vModel = "Older model";
  //       } else {
  //         vModel = model;
  //       }
  //       // debugger;
  //       res = await apiClient.get(
  //         `/retrivergetCondtructionPeriodiData/${family}/${encodeURIComponent(
  //           vModel
  //         )}`
  //       );
  //     }
  //   }
  //   // const res = {vehicleFamilyData:[
  //   //   {constructionPeriod: "2014/11-2015/01"},
  //   //   {constructionPeriod: "2015/02-2016/02"},
  //   //   {constructionPeriod: "2016/03-2016/09"}]};
  //   logger.info("construction periods: ", res.data.vehicleFamilyData);
  //   const constructionPeriod = res.data.vehicleFamilyData.map(
  //     (item) => item.constructionPeriod
  //   );
  //   var responseItems: any;
  //   responseItems = constructionPeriod.map((item, index: number) => ({
  //     value: index + 1,
  //     label: item,
  //     isDisabled: false,
  //   }));
  //   responseItems = responseItems.sort((i1, i2) =>
  //     i1.label > i2.label ? 1 : -1
  //   );
  //   setConstructionPeriodListItems([
  //     {
  //       value: 0,
  //       label: translationInfo.constructionPeriod,
  //       isDisabled: true,
  //     },
  //     ...responseItems,
  //   ]);
  //   changeloadingState(false);
  //   return constructionPeriod;
  // }

  async function getCockpitValues(family: string, model: string) {
    changeloadingState(true);
    // api call
    var translationLangCode = localStorage.getItem("langCode");
    let cockpitValues
    const res = await apiClient.get(
      `/retrivergetCockpitDetails/${family}/${encodeURIComponent(
        model
      )}/${translationLangCode === "en_GB" ? "" : translationLangCode}`
    );
    // const res = {vehicleFamilyData:[
    //   {constructionPeriod: "2014/11-2015/01"},
    //   {constructionPeriod: "2015/02-2016/02"},
    //   {constructionPeriod: "2016/03-2016/09"}]};
    logger.info("construction periods: ", res.data.cockpitDetails);
    if(translationLangCode==="en_GB"){
    cockpitValues = res.data.cockpitDetails.map(
      (item) => item.cockpit
    );}
    else{
      cockpitValues = res.data.vehicleFamilyData.map(
        (item) => item.cockpit_de_DE
      )
    }
    var responseItems: any;
    responseItems = cockpitValues.map((item, index: number) => ({
      value: index + 1,
      label: item,
      isDisabled: false,
    }));
    responseItems = responseItems.sort((i1, i2) =>
      i1.label > i2.label ? 1 : -1
    );
    setCockpitValues([
      {
        value: 0,
        label: translationInfo.cockpit,
        isDisabled: true,
      },
      ...responseItems,
    ]);
    changeloadingState(false);
    return cockpitValues;
  }

  async function getConstructionPeriods(family: string, model: string, cockpit?: string) {
    changeloadingState(true);
    // api call
    let res
    var translationLangCode = localStorage.getItem("langCode");
    if(cockpit!== undefined){
      res = await apiClient.get(
        `/retrivergetCondtructionPeriodiDataForCockpit/${family}/${encodeURIComponent(
          model
        )}/${cockpit}/${translationLangCode === "en_GB" ? "" : translationLangCode}`
      ); 
    }
    else{
      res = await apiClient.get(
      `/retrivergetCondtructionPeriodiData/${family}/${encodeURIComponent(
        model
      )}/${translationLangCode === "en_GB" ? "" : translationLangCode}`
    );
  }
    // const res = {vehicleFamilyData:[
    //   {constructionPeriod: "2014/11-2015/01"},
    //   {constructionPeriod: "2015/02-2016/02"},
    //   {constructionPeriod: "2016/03-2016/09"}]};
    logger.info("construction periods: ", res.data.vehicleFamilyData);
    const constructionPeriod = res.data.vehicleFamilyData.map(
      (item) => item.constructionPeriod
    );
    var responseItems: any;
    responseItems = constructionPeriod.map((item, index: number) => ({
      value: index + 1,
      label: item,
      isDisabled: false,
    }));
    responseItems = responseItems.sort((i1, i2) =>
      i1.label > i2.label ? 1 : -1
    );
    setConstructionPeriodListItems([
      {
        value: 0,
        label: translationInfo.constructionPeriod,
        isDisabled: true,
      },
      ...responseItems,
    ]);
    changeloadingState(false);
    return constructionPeriod;
  }

  // clear selected dropdown data after reload
  function verifyAndClearCurrentData(familyDataSet: any) {
    // debugger;
    if (localStorage.getItem("familyObject") != null) {
      var familyList: string[] = [];
      var dropDownControl = dropDownDisableControl;
      var selectedFamily = state.selectedFamilyModel?.label || "";
      // if (selectedFamily == "Unimog implement carrier") {
      //   selectedFamily = "Unimog Geräteträger";
      // }
      if (selectedFamily != "") {
        logger.info("familyDataSet", familyDataSet);

        familyDataSet.map((d) => familyList.push(d.label));
        // logger.info("familyDataSet List", familyList);
        // logger.info("selectedFamily", selectedFamily);
        // if (selectedFamily == "Unimog Geräteträger") {
        //   dropDownControl[0].isModelAvailable = false;
        // }
        if (!familyList.includes(selectedFamily)) {
          // localStorage.clear();
          dispatch({
            type: "setSelectedFamilyModel",
            payload: {
              selectedFamilyModel: "",
            },
          });

          localStorage.setItem("familyObject", JSON.stringify(""));

          setSelectedDropDownItem(initialSelectedDropDownItems);
          setSelectedItemExists(false);
          dropDownControl[0].isModelAvailable = false;

          dropDownControl[0].isDocumentFieldVisible = false;
          setDropDownDisableControl({ ...dropDownControl });
        }
      }

      logger.info(
        "selected family is ----->",

        selectedFamily,

        "family list is ----->",

        familyList
      );
    }
  }

  //Call this function to get Combo Box DropDown Items
  async function getMenuListAPI(selectedType: string, url: string) {
    changeloadingState(true);
    var translationLangCode = localStorage.getItem("langCode");
    try {
      const res: any = await apiClient.get(
        `${url}/${translationLangCode === "en_GB" ? "" : translationLangCode}`
      );
      var data: any;

      if (selectedType == "Family") {
        data = res.data?.vehicleFamilyData;
        var responseItems: SelectMenuListItems;

        // logger.info("translationInfo", translationInfo);

        // logger.info("CheckResp", data);

        responseItems = data.map((d, index: number) => ({
          value: index + 1,
          label: d.vehicleName,
          isDisabled: false,
        }));

        // unimog name change to german
        // let vech = {
        //   "Unimog implement carrier": "Unimog implement carrier",
        // Actros: "Actros",
        // };

        // logger.info("translationInfo", translationInfo);
        // responseItems.map((item) => {
        //   if (item.label == vech[item.label]) {
        //     // updating the german value to model dropdown
        //     item.label = translationInfo?.vechicleNameTranslations[item.label];
        //   }
        // });

        // responseItems.forEach((item) => {
        //   if (item.label == vech[item.label]) {
        //     // updating the german value to model dropdown
        //     item.label = translationInfo?.vechicleNameTranslations[item.label];
        //   }
        //   logger.info("selectedDropDownItem", selectedDropDownItem);
        //   if (selectedDropDownItem?.family == "Unimog implement carrier") {
        //     localStorage.setItem("familyObject", JSON.stringify(item));
        //     dispatch({
        //       type: "setSelectedFamilyModel",
        //       payload: {
        //         selectedFamilyModel: item.label ? item.label : null,
        //       },
        //     });
        //   }
        // });

        // logger.info("Unimog", responseItems);

        //sorting vehicle families
        responseItems = responseItems.sort((i1, i2) =>
          i1.label.toLowerCase() > i2.label.toLowerCase() ? 1 : -1
        );

        setFamilyListItems([
          {
            value: 0,
            label: translationInfo.familyPlaceholder,
            isDisabled: true,
          },
          ...responseItems,
        ]);
        setVehicleFamilyData(data);
        //preselection of dropdowns
        const urlParams = new URLSearchParams(history.location.search);
        // console.log("urlParams", urlParams.get('family'), urlParams.get('version'));
        if(urlParams.get('family')){
          const veh = responseItems.filter(item => item.label === urlParams.get('family'))[0];
          veh ? handleChange("Family", veh) : window.alert("Vehicle not found. Please select manually.");
        }
        verifyAndClearCurrentData(responseItems);
        logger.info(`Vehicle Family data ---- ${vehicleFamilyData}`);
        changeloadingState(false);
        logger.info(`Get vehicle family list API response ${data}`);
        if (selectedDropDownItem.family != "") {
          data.map((d) => {
            if (d.vehicleName == selectedDropDownItem.family) {
              if (d.vehicleModel.length != 0) {
                var model;
                var data = d.vehicleModel;
                model = data.map((d, index: number) => ({
                  value: index + 1,
                  label: d,
                  isDisabled: false,
                }));

                logger.info("Model", model);

                //sorting vehicle models
                model.sort((a, b) => {
                  if (
                    isNaN(a.label.split("-")[0]) &&
                    isNaN(b.label.split("-")[0])
                  ) {
                    return a.label.localeCompare(b.label);
                  } else if (isNaN(a.label.split("-")[0])) {
                    return -1;
                  } else if (isNaN(b.label.split("-")[0])) {
                    return 1;
                  } else {
                    return a.label.split("-")[0] - b.label.split("-")[0];
                  }
                });
                logger.info("sorted model: ", model);

                setModelListItems([
                  {
                    value: 0,
                    label: translationInfo.modelPlaceholder,
                    isDisabled: true,
                  },
                  ...model,
                ]);
              }
            }
          });
        }
      }
    } catch (err: any) {
      changeloadingState(false);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      // history.push("/truckownermanuals/error");
      logger.info(`${selectedType} API error ${err.response?.status}`);
    }
  }

  const setLanguages = (documentTypeData, selectedDocType) => {
    var langModel = documentTypeData
      .find((item) => item.filename === selectedDocType)
      .language?.map((data: any, index: number) => ({
        value: index + 1,
        label: data,
        isDisabled: false,
      }));
    logger.info(
      "getTranslations languages",
      translationInfo.languagePlaceholder
    );
    logger.info("getTranslations languages", langModel);
    langModel = langModel.sort((i1, i2) =>
      i1.label > i2.label ? 1 : -1
    );
    setLanguageListItems([
      {
        value: 0,
        label: translationInfo.languagePlaceholder,
        isDisabled: true,
      },
      ...langModel,
    ]);
  };

  const getTranslations = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Family_placeholder%7C$$Model_placeholder%7C$$Document_type_placeholder%7C$$Document_language_placeholder%7C$$Open%7C$$Construction_Period%7C$$Unimog%20implement%20carrier%7C$$Current%20model%7C$$Operating%20instructions%7C$$Hungarian`
      );
      data = result.data?.footerDetails;
      var translationDetails = translationInfo;
      data?.map((items) => {
        if (items.Variable_Name == "$$Family_placeholder") {
          translationDetails.familyPlaceholder = items.translated_text;
        } else if (items.Variable_Name == "$$Model_placeholder") {
          translationDetails.modelPlaceholder = items.translated_text;
        } else if (items.Variable_Name == "$$Document_type_placeholder") {
          translationDetails.documentTypePlaceholder = items.translated_text;
        } else if (items.Variable_Name == "$$Document_language_placeholder") {
          translationDetails.languagePlaceholder = items.translated_text;
        } else if (items.Variable_Name == "$$Construction_Period") {
          translationDetails.constructionPeriod = items.translated_text;
        }
        // else if (items.Variable_Name == "$$Unimog implement carrier") {
        //   translationDetails.vechicleNameTranslations[
        //     "Unimog implement carrier"
        //   ] = items.translated_text;
        // }
        // else if (items.Variable_Name == "$$Current model") {
        //   translationDetails.vechicleModelTranslations["Current model"] =
        //     items.translated_text;
        // }
        else if (items.Variable_Name == "$$Operating instructions") {
          translationDetails.vechileDocTypeTranslations[
            "Operating instructions"
          ] = items.translated_text;
        }
        //  else if (items.Variable_Name == "$$Hungarian") {
        //   translationDetails.vehicleLanguageTranslations[
        //     "Hungarian (Hungary)"
        //   ] = items.translated_text;
        // }
        // else {
        //   translationDetails.vechicleNameTranslations["Actros"] = "ActGerman";
        // }
        else if (items.Variable_Name == "$$Open") {
          translationDetails.openBtnText = items.translated_text;
        }
      });
      setTranslationInfo({ ...translationDetails });

      var dropDownControl = dropDownDisableControl;
      dropDownControl[0].isOpenButtonVisible = false;
      dropDownControl[0].isModelAvailable = false;
      dropDownControl[0].isPDFSelected = false;
      dropDownControl[0].isLanguageSelectionBoxVisible = false;
      dropDownControl[0].isDocumentTypeDisabled = true;
      dropDownControl[0].isRightContentHidden = true;
      dropDownControl[0].isConstructionPeriodBoxVisible = false;
      dropDownControl[0].isDocumentFieldVisible = false;
      dropDownControl[0].isCockpitAvailable=false
      setDropDownDisableControl({ ...dropDownControl });
      logger.info("Transsssss", modelListItems);
      localStorage.setItem("familyObject", JSON.stringify(""));
      dispatch({
        type: "setSelectedFamilyModel",
        payload: {
          selectedFamilyModel: null,
        },
      });

      setSelectedDropDownItem({ ...initialSelectedDropDownItems });
      dispatch({
        type: "setSelectedVehicleTypeModel",
        payload: {
          selectedVehicleTypeModel: null,
        },
      });
      localStorage.setItem(
        "vehicleTypeObject",
        JSON.stringify({
          value: 0,
          label: "Modell",
        })
      );

      await getMenuListAPI("Family", "/retrivergetVehicleFamilyData");
      logger.info("getTranslations", selectedDropDownItem);
      logger.info(
        "getTranslations state",
        "family",
        state.selectedFamilyModel,
        "model",
        state.selectedVehicleTypeModel,
        "doctype",
        state.selectedDocumentTypeModel,
        "language",
        state.selectedDocumentLanguageModel,
        "cp",
        state.selectedConstructionPeriod,
        "cockpit",
        state.selectedCockpitValue,
      );

      //calling dropdown apis for new models
      if (localStorage.getItem("isOldModel") !== "true") {
        logger.info("getTranslations for new model");
        let data;
        if (
          state.selectedFamilyModel != "" &&
          state.selectedVehicleTypeModel != null &&
          state.selectedVehicleTypeModel !== ""
        ) {
          data = await getDocumentDetails(
            selectedDropDownItem.family,
            selectedDropDownItem.model
          );
          logger.info("getTranslations new data", data);
          if (state.selectedDocumentTypeModel !== null) {
            setLanguages(data, selectedDropDownItem.documentType);
          }
        }
      }
      //calling dropdown apis for old models
      else if (localStorage.getItem("isOldModel") == "true") {
        logger.info("getTranslations for old model");
        let data;
        if (
          state.selectedFamilyModel != "" &&
          state.selectedVehicleTypeModel != null &&
          state.selectedVehicleTypeModel !== ""
        ) {
          await getConstructionPeriods(
            selectedDropDownItem.family,
            selectedDropDownItem.model
          );
          if (state.selectedConstructionPeriod) {
            let data = await getDocumentDetails(
              selectedDropDownItem.family,
              selectedDropDownItem.model,
              selectedDropDownItem.constructionPeriod
            );
            logger.info("getTranslations old data", data);
            if (state.selectedDocumentTypeModel !== null) {
              setLanguages(data, selectedDropDownItem.documentType);
            }
          }
        }
      }

      // if (
      //   selectedDropDownItem.family != "" &&
      //   selectedDropDownItem.model != "" &&
      //   localStorage.getItem("isOldModel") !== "true"
      // ) {
      //   await getDocumentDetails(
      //     selectedDropDownItem.family,
      //     selectedDropDownItem.model
      //   );
      // } else if (
      //   selectedDropDownItem.family != "" &&
      //   selectedDropDownItem.model != "" &&
      //   selectedDropDownItem.constructionPeriod != undefined &&
      //   localStorage.getItem("isOldModel") == "true"
      // ) {
      //   // add for should not call when we select old model and change translation

      //   // add for langugage dropdown placeholder changes

      //   if (
      //     (selectedDropDownItem.family,
      //     selectedDropDownItem.model,
      //     selectedDropDownItem.constructionPeriod !== "")
      //   ) {
      //     await getDocumentDetails(
      //       selectedDropDownItem.family,
      //       selectedDropDownItem.model,
      //       selectedDropDownItem.constructionPeriod
      //     );
      //   }

      //   await getConstructionPeriods(
      //     selectedDropDownItem.family,
      //     selectedDropDownItem.model
      //   );
      // }

      logger.info(`Combo Box Translation API result`, data);
    } catch (err: any) {
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      // history.push("/truckownermanuals/error");
      logger.info(`Combo Box API ERROR ${err}`);
    }
  };

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => {
    const {
      selectProps: { isDisabled },
    } = props;
    return (
      <components.DropdownIndicator {...props}>
        {!isDisabled ? (
          <img
            src="https://www.busguides.mercedes-benz.com/AnwenderInfo/resources/img/expand.png"
            className="dropdown-img"
            alt=""
          />
        ) : (
          <img
            src="https://www.busguides.mercedes-benz.com/AnwenderInfo/resources/img/expand-disabled.png"
            className="dropdown-img"
            alt=""
          />
        )}
      </components.DropdownIndicator>
    );
  };

  const OpenButton: React.FC = () => {
    function saveVehicleFamilyAndModel() {
      dispatch({
        type: "setSelectedVehicleName",
        payload: {
          selectedVehicleName: selectedDropDownItem.family,
        },
      });
      dispatch({
        type: "setSelectedVehicleModel",
        payload: {
          selectedVehicleModel: selectedDropDownItem.model,
        },
      });
      localStorage.setItem("vehicleName", selectedDropDownItem.family);
      localStorage.setItem("vehicleModel", selectedDropDownItem.model);
      callManualContentAPI();
    }

    // This API call is to store whole manual content in Redis for the selected Vehicle details
    async function callManualContentAPI() {
      var translationLangCode = localStorage.getItem("langCode");
      try {
        var result;
        if (state.isVINAvailable || state.vehicleVIN) {
          result = await apiClient.get(
            `/retrivergetMenuContent/0/${
              state.vehicleInfoBasedOnVIN?.vehicleName
            }/${
              state.vehicleInfoBasedOnVIN?.vehicleModel
            }/${localStorage.getItem("hmiSystemLanguage")}/${
              localStorage.getItem(
                "hmiSystemLanguage"
              ) === "de_DE" ? "de_DE" : ""
            }`
          );
        } else {
          result = await apiClient.get(
            `/retrivergetMenuContent/0/${selectedDropDownItem.family}/${
              selectedDropDownItem.model
            }/${state.selectedManualLanguageCode}/${
              translationLangCode === "en_GB" ? "" : translationLangCode
            }`
          );
        }
      } catch (err: any) {
        logger.info("Error in calling Manual content API");
      }
    }
    console.log("selectedDropDownItem", selectedDropDownItem);

    // For actros filename changes - ClassicCockpit and MultimediaCockpit
    let selectedModelEnglish = localStorage.getItem("EnglishVehicleModel");

    if (selectedModelEnglish == "Classic Cockpit (Current version)") {
      selectedModelEnglish = "ClassicCockpit (Current version)";
    } else if (selectedModelEnglish == "Multimedia Cockpit (Current version)") {
      selectedModelEnglish = "MultimediaCockpit (Current version)";
    }
    // else {
    //   if (selectedDropDownItem?.model == "ältere modell") {
    //     selectedModelForActros = "Older model";
    //   } else if (selectedDropDownItem?.model == "Aktuelle modell") {
    //     selectedModelForActros = "Current model";
    //   } else {
    //     selectedModelForActros = selectedDropDownItem?.model;
    //   }
    // }

    // for Unimog name changes
    let selectedFamilyEnglish = localStorage.getItem("EnglishVehicleName");

    if (selectedFamilyEnglish == "Unimog implement carrier") {
      selectedFamilyEnglish = "UGE";
    } else if (selectedFamilyEnglish == "Extreme off-road Unimog Euro 3-5") {
      selectedFamilyEnglish = "UHN";
    } else if (selectedFamilyEnglish == "Extreme off-road Unimog") {
      selectedFamilyEnglish = "UHE";
    }

    if (dropDownDisableControl[0].isPDFSelected) {
      var pdfLocation: string = `/truckownermanuals/assets/${
        selectedDropDownItem?.model == "NA"
          ? selectedFamilyEnglish
          : selectedFamilyEnglish + "_" + selectedModelEnglish
      }/pdf/${selectedDropDownItem.pdfFileName}`;
      return (
        // <div className="open-btn-container">
        //   <a href={pdfLocation} target="_blank" rel="noopener noreferrer">
        //     <input
        //       type="submit"
        //       value={translationInfo.openBtnText}
        //       className={
        //         dropDownDisableControl[0].isOpenButtonVisible
        //           ? "open-btn open-btn-visible"
        //           : "open-btn"
        //       }
        //       onClick={() => {}}
        //     ></input>
        //   </a>
        // </div>
        <div className="open-btn-container">
          <IonButton
            className={
              dropDownDisableControl[0].isOpenButtonVisible
                ? "open-btn open-btn-visible"
                : "open-btn"
            }
            href={pdfLocation}
            target="_blank"
            rel="noopener noreferrer"
            color="--ion-color-open-button"
            onClick={() => {}}
          >
            {translationInfo.openBtnText}
          </IonButton>
        </div>
      );
    } else {
      return (
        <div className="open-btn-container">
          <IonButton
            className={
              dropDownDisableControl[0].isOpenButtonVisible
                ? "open-btn open-btn-visible"
                : "open-btn"
            }
            href="/truckownermanuals/tiled-items"
            target="_blank"
            color="--ion-color-open-button"
            onClick={() => {
              saveVehicleFamilyAndModel();
            }}
          >
            {translationInfo.openBtnText}
          </IonButton>
        </div>
      );
    }
  };

  // Call this function to clear all vehicle related information
  function clearPreviousSelection() {
    // debugger;
    dispatch({
      type: "setSelectedVehicleTypeModel",
      payload: {
        selectedVehicleTypeModel: null,
      },
    });
    localStorage.removeItem("vehicleTypeObject");
    dispatch({
      type: "setSelectedDocumentTypeModel",
      payload: {
        selectedDocumentTypeModel: null,
      },
    });
    localStorage.removeItem("documentTypeObject");
    dispatch({
      type: "setSelectedActualPDFFileName",
      payload: {
        selectedActualPDFFileName: null,
      },
    });
    localStorage.removeItem("actualPDFFileName");
    dispatch({
      type: "setSelectedDocumentLanguageModel",
      payload: {
        selectedDocumentLanguageModel: null,
      },
    });
    localStorage.removeItem("documentLanguageObject");
    dispatch({
      type: "setIsModelAvailable",
      payload: {
        isModelAvailable: "false",
      },
    });
    localStorage.removeItem("isModelAvailable");
    dispatch({
      type: "setIsOldModel",
      payload: {
        isOldModel: "false",
      },
    });
    localStorage.removeItem("isOldModel");
    dispatch({
      type: "setDocumentSetObject",
      payload: {
        documentSetObject: null,
      },
    });
    localStorage.removeItem("documentSetObject");
    dispatch({
      type: "setSelectedConstructionPeriod",
      payload: {
        selectedConstructionPeriod: null,
      },
    });
    localStorage.removeItem("constructionPeriod");
    dispatch({
      type: "setSelectedCockpitValue",
      payload: {
        selectedCockpitValue: null,
      },
    });
    localStorage.removeItem("cockpit");
    var dropDownControl = dropDownDisableControl;
    dropDownControl[0].isOpenButtonVisible = false;
    dropDownControl[0].isModelAvailable = false;
    dropDownControl[0].isPDFSelected = false;
    dropDownControl[0].isLanguageSelectionBoxVisible = false;
    dropDownControl[0].isDocumentTypeDisabled = true;
    dropDownControl[0].isRightContentHidden = true;
    dropDownControl[0].isConstructionPeriodBoxVisible = false;
    dropDownControl[0].isDocumentFieldVisible = true;
    dropDownControl[0].isCockpitAvailable = false
    // if (state.selectedFamilyModel.label == "Custom Tailored Truck Codes") {
    //   dropDownControl[0].isDocumentFieldVisible = true;
    // } else {
    //   dropDownControl[0].isDocumentFieldVisible = false;
    // }
    setDropDownDisableControl({ ...dropDownControl });
  }

  const handleModelSelection = (
    newValue,
    dropDownControl,
    selectedValue,
    selectedData
  ) => {
    if (newValue.label != selectedData.model) {
      dispatch({
        type: "setSelectedDocumentTypeModel",
        payload: {
          selectedDocumentTypeModel: null,
        },
      });
      dispatch({
        type: "setSelectedConstructionPeriod",
        payload: {
          selectedConstructionPeriod: null,
        },
      });
      dispatch({
        type: "setSelectedCockpitValue",
        payload: {
          selectedCockpitValue: null,
        },
      });
      dispatch({
        type: "setSelectedDocumentLanguageModel",
        payload: {
          selectedDocumentLanguageModel: null,
        },
      });
      localStorage.removeItem("documentTypeObject");
      localStorage.removeItem("cockpit")
      localStorage.removeItem("constructionPeriod");
      localStorage.removeItem("documentLanguageObject");
      dropDownControl[0].isOpenButtonVisible = false;
      dropDownControl[0].isRightContentHidden = true;
      dropDownControl[0].isDocumentFieldVisible = true;
    }

    selectedData.model = newValue.label;

    setSelectedDropDownItem({ ...selectedData });
    if (
      selectedDropDownItem.model.indexOf("Current") !== -1 ||
      selectedDropDownItem.model.indexOf("Aktuelle") !== -1
    ) {
      //current model
      dispatch({
        type: "setIsOldModel",
        payload: {
          isOldModel: false,
        },
      });
      localStorage.setItem("isOldModel", "false");
      dropDownControl[0].isDocumentTypeDisabled = false;
      dropDownControl[0].isConstructionPeriodBoxVisible = false;
      dropDownControl[0].isDocumentFieldVisible = true;
      dropDownControl[0].isCockpitAvailable = false
      getDocumentDetails(
        selectedDropDownItem.family,
        selectedDropDownItem.model
      );
    }else if(selectedDropDownItem.model === "963-964"){
      dropDownControl[0].isDocumentTypeDisabled = false;
      dropDownControl[0].isCockpitAvailable = true
      // dropDownControl[0].isConstructionPeriodBoxVisible = true;
      dispatch({
        type: "setIsOldModel",
        payload: {
          isOldModel: true,
        },
      });
      localStorage.setItem("isOldModel", "true");
      getCockpitValues(
          selectedDropDownItem.family,
          selectedDropDownItem.model
      )
      // const constructionPeriods = getConstructionPeriods(
      //   selectedDropDownItem.family,
      //   selectedDropDownItem.model
      // );
    } 
    else {
      //old model
      dropDownControl[0].isCockpitAvailable = false
      dropDownControl[0].isDocumentTypeDisabled = true;
      dropDownControl[0].isConstructionPeriodBoxVisible = true;
      dispatch({
        type: "setIsOldModel",
        payload: {
          isOldModel: true,
        },
      });
      localStorage.setItem("isOldModel", "true");
      const constructionPeriods = getConstructionPeriods(
        selectedDropDownItem.family,
        selectedDropDownItem.model
      );
    }

    dispatch({
      type: "setSelectedVehicleTypeModel",
      payload: {
        selectedVehicleTypeModel: selectedValue,
      },
    });

    localStorage.setItem("vehicleTypeObject", JSON.stringify(selectedValue));
    logger.info("Model Type");
  };

  const handleChange = (selector: string, newValue: any) => {
    logger.info(newValue.label);
    logger.info("newValue", newValue);
    setNewLabel(newValue);
    var dropDownControl = dropDownDisableControl;
    var selectedData = selectedDropDownItem;
    var selectedValue = {
      value: newValue.value,
      label: newValue.label,
    };

    if (selector == "Family") {
      // debugger;
      if (newValue.label != selectedData.family) {
        clearPreviousSelection();
      }

      // for CTT changes
      if (newValue.label == "Custom Tailored Truck Codes") {
        localStorage.setItem("familyName", newValue.value);
        dropDownControl[0].isDocumentFieldVisible = true;
        dropDownControl[0].isLanguageSelectionBoxVisible = true;
        dispatch({
          type: "setSelectedDocumentLanguageModel",
          payload: {
            selectedDocumentLanguageModel: null,
          },
        });
      }

      dispatch({
        type: "setSelectedFamilyModel",
        payload: {
          selectedFamilyModel: selectedValue ? selectedValue : null,
        },
      });
      localStorage.setItem("familyObject", JSON.stringify(selectedValue));
      selectedData.family = newValue.label;
      if (vehicleFamilyData[newValue.value - 1] && vehicleFamilyData[newValue.value - 1].vehicleModel[0] != "") {
        dropDownControl[0].isModelAvailable = true;
        var model;
        var data = vehicleFamilyData[newValue.value - 1].vehicleModel;
        model = data.map((d, index: number) => ({
          value: index + 1,
          label: d,
          isDisabled: false,
        }));

        // unimog name change to german for Model

        // model.forEach((item) => {
        //   if (item.label == "Current model") {
        //     logger.info("CUrrent", item);
        //     if (state.selectedLanguageCode == "de_DE") {
        //       item.label = "Aktuelle modell";
        //     } else if (state.selectedLanguageCode == "en_GB") {
        //       item.label = "Current model";
        //     }
        //     // item.label = translationInfo?.vechicleModelTranslations[item.label];
        //     // item.label = "German Current here";
        //   } else if (item.label == "Older model") {
        //     if (state.selectedLanguageCode == "de_DE") {
        //       item.label = "ältere modell";
        //     } else if (state.selectedLanguageCode == "en_GB") {
        //       item.label = "Older model";
        //     }
        //     // item.label = translationInfo?.unimogVehcile;
        //     // item.label = "German Older here";
        //   }
        // });
        logger.info("Model", model);
        console.log("Model", model);

        //implementation for only one model
        if (model.length === 1) {
          handleModelSelection(
            model[0],
            dropDownControl,
            model[0],
            selectedData
          );
        }

        //sorting vehicle models
        model.sort((a, b) => {
          if (isNaN(a.label.split("-")[0]) && isNaN(b.label.split("-")[0])) {
            return a.label.localeCompare(b.label);
          } else if (isNaN(a.label.split("-")[0])) {
            return -1;
          } else if (isNaN(b.label.split("-")[0])) {
            return 1;
          } else {
            return a.label.split("-")[0] - b.label.split("-")[0];
          }
        });
        logger.info("sorted model: ", model);

        setModelListItems([
          {
            value: 0,
            label: translationInfo.modelPlaceholder,
            isDisabled: true,
          },
          ...model,
        ]);
      } else {
        selectedData.model = "NA";
        getDocumentDetails(selectedDropDownItem.family, "NA").then((data) => {
          // automatically selecting if there is only one document type
          if (data.length === 1) {
            logger.info("Only one document type");
            dropDownControl[0].isModelAvailable = false;
            dropDownControl[0].isDocumentTypeDisabled = false;
            selectedData.documentType = data[0].filename;
            setSelectedDocIndex(0);
            dropDownControl[0].isPDFSelected = true;
            localStorage.setItem("isPDFSelected", "true");

            dropDownControl[0].isRightContentHidden = false;
            var langModel = data[0].language?.map(
              (data: any, index: number) => ({
                value: index + 1,
                label: data,
                isDisabled: false,
              })
            );
            langModel = langModel.sort((i1, i2) =>
              i1.label > i2.label ? 1 : -1
            );

            setLanguageListItems([
              {
                value: 0,
                label: translationInfo.languagePlaceholder,
                isDisabled: true,
              },
              ...langModel,
            ]);
            logger.info("Languagesssss model --->", langModel);
            dispatch({
              type: "setSelectedDocumentTypeModel",
              payload: {
                selectedDocumentTypeModel: {
                  value: 1,
                  label: data[0].filename,
                },
              },
            });
            localStorage.setItem("documentTypeObject", JSON.stringify(data));
            // handleChange("Document Type", {value: 1, label: data[0].filename, isDisabled: false});
            // dropDownControl[0].isDocumentTypeDisabled = true;
            return;
          }
        });
        dropDownControl[0].isModelAvailable = false;
        dropDownControl[0].isDocumentTypeDisabled = false;
      }
      dispatch({
        type: "setIsModelAvailable",
        payload: {
          isModelAvailable: `${dropDownControl[0].isModelAvailable}`,
        },
      });
      localStorage.setItem(
        "isModelAvailable",
        `${dropDownControl[0].isModelAvailable}`
      );

      setSelectedDropDownItem({ ...selectedData });
    } else if (selector == "Model") {
      setSelectedDocIndex(0);
      handleModelSelection(
        newValue,
        dropDownControl,
        selectedValue,
        selectedData
      );
    } else if(selector == "Cockpit"){
      setSelectedDocIndex(0);
      if (newValue.label != selectedData.cockpit) {
        dispatch({
          type: "setSelectedDocumentTypeModel",
          payload: {
            selectedDocumentTypeModel: null,
          },
        });
        dispatch({
          type: "setSelectedDocumentLanguageModel",
          payload: {
            selectedDocumentLanguageModel: null,
          },
        });
        dispatch({
          type: "setSelectedConstructionPeriod",
          payload: {
            selectedConstructionPeriod: null,
          },
        });
        localStorage.removeItem("documentTypeObject");
        localStorage.removeItem("documentLanguageObject");
        dropDownControl[0].isOpenButtonVisible = false;
        dropDownControl[0].isRightContentHidden = true;
        dropDownControl[0].isLanguageSelectionBoxVisible = true;
        dropDownControl[0].isDocumentFieldVisible = true;
      }

      selectedData.cockpit = newValue.label;

      setSelectedDropDownItem({ ...selectedData });
      getConstructionPeriods(
        selectedDropDownItem.family,
        selectedDropDownItem.model,
        selectedDropDownItem.cockpit
      );
      dropDownControl[0].isDocumentTypeDisabled = true;
      dropDownControl[0].isDocumentFieldVisible = false;
      dropDownControl[0].isConstructionPeriodBoxVisible = true
      dispatch({
        type: "setSelectedCockpitValue",
        payload: {
          selectedCockpitValue: selectedValue,
        },
      });
      localStorage.setItem("cockpit", JSON.stringify(selectedValue));
    }else if (selector == "Construction Period") {
      setSelectedDocIndex(0);
      if (newValue.label != selectedData.constructionPeriod) {
        dispatch({
          type: "setSelectedDocumentTypeModel",
          payload: {
            selectedDocumentTypeModel: null,
          },
        });
        dispatch({
          type: "setSelectedDocumentLanguageModel",
          payload: {
            selectedDocumentLanguageModel: null,
          },
        });
        localStorage.removeItem("documentTypeObject");
        localStorage.removeItem("documentLanguageObject");
        dropDownControl[0].isOpenButtonVisible = false;
        dropDownControl[0].isRightContentHidden = true;
        dropDownControl[0].isLanguageSelectionBoxVisible = true;
        dropDownControl[0].isDocumentFieldVisible = true;
      }

      selectedData.constructionPeriod = newValue.label;

      setSelectedDropDownItem({ ...selectedData });
      if(state.selectedCockpitValue !== null){
        getDocumentDetails(
          selectedDropDownItem.family,
          selectedDropDownItem.model,
          selectedDropDownItem.constructionPeriod,
          selectedDropDownItem.cockpit
        );
      }
      else{
        //getDocument
        getDocumentDetails(
          selectedDropDownItem.family,
          selectedDropDownItem.model,
          selectedDropDownItem.constructionPeriod
        );
      }
      dropDownControl[0].isDocumentTypeDisabled = false;
      dropDownControl[0].isDocumentFieldVisible = true;
      dispatch({
        type: "setSelectedConstructionPeriod",
        payload: {
          selectedConstructionPeriod: selectedValue,
        },
      });
      localStorage.setItem("constructionPeriod", JSON.stringify(selectedValue));
    } else if (selector == "Document Type") {
      // This condition is to clear previously selected pdf Filenames/supported languages when user changes the document type
      if (newValue.label != selectedData.documentType) {
        dispatch({
          type: "setSelectedActualPDFFileName",
          payload: {
            selectedActualPDFFileName: null,
          },
        });
        localStorage.removeItem("actualPDFFileName");
        dispatch({
          type: "setSelectedDocumentLanguageModel",
          payload: {
            selectedDocumentLanguageModel: null,
          },
        });
        localStorage.removeItem("documentLanguageObject");
        dropDownControl[0].isOpenButtonVisible = false;
        dropDownControl[0].isPDFSelected = false;
      }

      selectedData.documentType = newValue.label;
      // console.log("NEWWWWWWWWWWWWWWWW VALUEEEEEEEEEEEE", newValue);
      setSelectedDocIndex(newValue.value - 1);
      if (newValue.label == "Truck Guide(HTML)") {
        dropDownControl[0].isPDFSelected = false;
        localStorage.setItem("isPDFSelected", "false");
      } else {
        dropDownControl[0].isPDFSelected = true;
        localStorage.setItem("isPDFSelected", "true");
      }
      dropDownControl[0].isRightContentHidden = false;
      dropDownControl[0].isLanguageSelectionBoxVisible = true;
      var langModel = documentObj[newValue.value - 1].language?.map(
        (data: any, index: number) => ({
          value: index + 1,
          label: data,
          isDisabled: false,
        })
      );
      langModel = langModel.sort((i1, i2) =>
        i1.label > i2.label ? 1 : -1
      );
      // if (state.selectedLanguageCode == "de_DE") {
      //   langModel.forEach((item) => {
      //     if (item.label == "Hungarian (Hungary)") {
      //       item.label = "Ungarisch";
      //     } else if (item.label == "Dutch") {
      //       item.label = "Niederländisch";
      //     } else if (item.label == "Spanish") {
      //       item.label = "Spanisch (Europäisch)";
      //     } else if (item.label == "French") {
      //       item.label = "Französisch (Europäisch)";
      //     } else if (item.label == "Italian") {
      //       item.label = "Italienisch";
      //     }
      //   });
      // } else {
      //   langModel.forEach((item) => {
      //     if (item.label == "Deutsch") {
      //       item.label = "German";
      //     }
      //   });
      // }
      setLanguageListItems([
        {
          value: 0,
          label: translationInfo.languagePlaceholder,
          isDisabled: true,
        },
        ...langModel,
      ]);

      logger.info("Language model --->", langModel);
      dispatch({
        type: "setSelectedDocumentTypeModel",
        payload: {
          selectedDocumentTypeModel: selectedValue,
        },
      });
      localStorage.setItem("documentTypeObject", JSON.stringify(selectedValue));
    } else {
      logger.info(`Changed ${selector}`);
      logger.info("documentObj", documentObj);
      logger.info("selectedDocIndex", newValue);
      logger.info("BeforepdfLangCode", selectedDropDownItem.pdfLangCode);
      logger.info(
        "first",
        documentObj[selectedDocIndex].actualFileName.filter((pdflang) =>
          pdflang.includes(selectedDropDownItem.pdfLangCode)
        )
      );
      dropDownControl[0].isOpenButtonVisible = true;
      selectedData.language = newValue.label;
      selectedData.pdfFileName =
        documentObj[selectedDocIndex].actualFileName[newValue.value - 1];
      selectedData.pdfLangCode =
        documentObj[selectedDocIndex].lang_code[newValue.value - 1];

      setSelectedDropDownItem({ ...selectedData });
      dispatch({
        type: "setSelectedDocumentLanguageModel",
        payload: {
          selectedDocumentLanguageModel: selectedValue,
        },
      });
      localStorage.setItem(
        "documentLanguageObject",
        JSON.stringify(selectedValue)
      );
      dispatch({
        type: "setSelectedManualLanguageCode",
        payload: {
          selectedManualLanguageCode:
            documentObj[selectedDocIndex].lang_code[newValue.value - 1],
        },
      });
      localStorage.setItem(
        "manualLangCode",
        documentObj[selectedDocIndex].lang_code[newValue.value - 1]
      );
      dispatch({
        type: "setSelectedActualPDFFileName",
        payload: {
          selectedActualPDFFileName:
            documentObj[selectedDocIndex].actualFileName[newValue.value - 1],
        },
      });
      localStorage.setItem(
        "actualPDFFileName",
        documentObj[selectedDocIndex].actualFileName[newValue.value - 1]
      );
      logger.info("Vehicle language");
    }
    logger.info("Selected doc obj order", selectedDocObjOrder);
    setDropDownDisableControl({ ...dropDownControl });
  };
  logger.info(
    "dropDownDisableControl[0].isDocumentFieldVisible ",
    dropDownDisableControl[0].isDocumentFieldVisible
  );
  return (
    <div className="model-selection-parent-container">
      <div className="combo-box-left">
        <div className="dropdown-box">
          <Select
            maxMenuHeight={220}
            menuPlacement="auto"
            className="react-select"
            isDisabled={false}
            placeholder={translationInfo.familyPlaceholder}
            isSearchable={false}
            // components={{ DropdownIndicator }}
            onChange={(event) => handleChange("Family", event)}
            // options={Family}
            options={familyListItems}
            value={state.selectedFamilyModel}
            styles={customStyles}
          />
        </div>
        {dropDownDisableControl[0].isModelAvailable ? (
          <div className="dropdown-box">
            <Select
              maxMenuHeight={220}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              placeholder={translationInfo.modelPlaceholder}
              isSearchable={false}
              // components={{ DropdownIndicator }}
              onChange={(event) => handleChange("Model", event)}
              // options={ModelType}
              value={state.selectedVehicleTypeModel}
              options={modelListItems}
              styles={customStyles}
            />
          </div>
        ) : null}
        {dropDownDisableControl[0].isCockpitAvailable ? (
          <div className="dropdown-box">
            <Select
              maxMenuHeight={220}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              placeholder={translationInfo.cockpit}
              isSearchable={false}
              // components={{ DropdownIndicator }}
              onChange={(event) => handleChange("Cockpit", event)}
              // options={ModelType}
              value={state.selectedCockpitValue}
              options={cockpitValues}
              styles={customStyles}
            />
          </div>
        ) : null}
        {dropDownDisableControl[0].isConstructionPeriodBoxVisible ? (
          <div className="dropdown-box">
            <Select
              maxMenuHeight={170}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              placeholder={translationInfo.constructionPeriod}
              isSearchable={false}
              // components={{ DropdownIndicator }}
              onChange={(event) => handleChange("Construction Period", event)}
              value={state.selectedConstructionPeriod}
              options={constructionPeriodListItems}
              styles={customStyles}
            />
          </div>
        ) : null}
        {dropDownDisableControl[0].isDocumentFieldVisible ? (
          <div className="dropdown-box">
            <Select
              maxMenuHeight={220}
              menuPlacement="auto"
              className="react-select"
              isDisabled={dropDownDisableControl[0].isDocumentTypeDisabled}
              placeholder={translationInfo.documentTypePlaceholder}
              isSearchable={false}
              // components={{ DropdownIndicator }}
              onChange={(event) => handleChange("Document Type", event)}
              // options={DocumentType}
              value={state.selectedDocumentTypeModel}
              options={documentTypeListItems}
              styles={customStyles}
            />
          </div>
        ) : null}
      </div>

      {dropDownDisableControl[0].isLanguageSelectionBoxVisible ? (
        <div
          className={
            dropDownDisableControl[0].isRightContentHidden
              ? "combo-box-right"
              : "combo-box-right combo-box-right-visible"
          }
        >
          <div className="dropdown-box">
            <Select
              maxMenuHeight={220}
              menuPlacement="auto"
              className="react-select"
              isDisabled={false}
              placeholder={translationInfo.languagePlaceholder}
              isSearchable={false}
              // components={{ DropdownIndicator }}
              onChange={(event) => handleChange("Language", event)}
              value={state.selectedDocumentLanguageModel}
              options={languageListItems}
              styles={customStyles}
            />
          </div>
          <OpenButton />
        </div>
      ) : null}
    </div>
  );
};

const TruckModelsParentContainer: React.FC<ContainerProps> = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [translationText, setTranslationText]: [
    TitleAndDescriptionTranslationInfo,
    Dispatch<SetStateAction<TitleAndDescriptionTranslationInfo>>
  ] = useState({
    truckGuides: "Truck Guides",
    guideDescription:
      "Here, you can download operating instructions, supplements and maintenance Booklet in PDF format. Please make your selection:",
    mobileDescriptionOne:
      "Here, you can download operating instructions, supplements and maintenance Booklet in PDF format.",
    mobileDescriptionTwo: "Please make your selection:",
    noteText: `Note: The content of this website is currently under construction.
    Further operating instructions can be found on the`,
  });

  const history = useHistory();

  const { state, dispatch } = useContext(AppContext);

  function handleChange(newValue: boolean) {
    setIsLoading(newValue);
    dispatch({
      type: "setIsHomeLoaderActive",
      payload: {
        isHomeLoaderActive: newValue,
      },
    });
  }

  useEffect(() => {
    getTranslations();
  }, [state.selectedLanguageCode]);

  let browser = "teset";

  useEffect(() => {
    logger.info("window.navigator.userAgent", window.navigator.userAgent);
    logger.info("window.navigator.platform", window.navigator.platform);
  }, []);
  browser = (function () {
    var test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "Microsoft Edge";
      case test(/trident/i):
        return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i):
        return "Mozilla Firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC Browser";
      case test(/samsungbrowser/i):
        return "Samsung Browser";
      case test(/chrome|chromium|crios/i):
        return "Google Chrome";
      case test(/safari/i):
        return "Apple Safari";
      default:
        return "Other";
    }
  })();
  logger.info("browser", browser);
  const getTranslations = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Truck_guides%7C$$Guide_description%7C$$Mobile_description_text_one%7C$$Mobile_description_text_two%7C$$Note_text`
      );
      data = result.data?.footerDetails;
      var translationDetails = translationText;
      data?.map((items) => {
        if (items.Variable_Name == "$$Truck_guides") {
          translationDetails.truckGuides = items.translated_text;
        } else if (items.Variable_Name == "$$Mobile_description_text_one") {
          translationDetails.mobileDescriptionOne = items.translated_text;
        } else if (items.Variable_Name == "$$Mobile_description_text_two") {
          translationDetails.mobileDescriptionTwo = items.translated_text;
        } else if (items.Variable_Name == "$$Guide_description") {
          translationDetails.guideDescription = items.translated_text;
        } else if (items.Variable_Name == "$$Note_text") {
          translationDetails.noteText = items.translated_text;
        }
      });
      setTranslationText({ ...translationDetails });
      logger.info(`Title & Description Translation API result`, data);
    } catch (err: any) {
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
      logger.info(`Title & Description Translation API ERROR ${err}`);
    }
  };

  return (
    <div className="parent-container">
      {/* {isLoading ? <div className="mejs-overlay-loading"></div> : null} */}
      <DesktopBreakpoint>
        <div className="model-main-container-desktop-new">
          <div className="truck-header">{translationText.truckGuides}</div>
          <p className="truck-description">
            {translationText.guideDescription}
          </p>
          {/* <ModelSelectionContainer
            isLoading={isLoading}
            onChange={handleChange}
          /> */}
          <div className="note-label-container">
            <p>
              <Linkify>{translationText.noteText}</Linkify>

              {/* <span style={{ fontSize: 6 }}>
                {window.navigator.userAgent} <p>{window.navigator.platform}</p>
              </span> */}
            </p>
          </div>
        </div>
        <ModelSelectionContainer
          isLoading={isLoading}
          onChange={handleChange}
        />
      </DesktopBreakpoint>
      <TabletBreakpoint>
        <div className="model-main-container-desktop-new model-main-container-tablet">
          <div className="truck-header">{translationText.truckGuides}</div>
          <p className="truck-description">
            {translationText.guideDescription}
          </p>

          <div className="note-label-container">
            <p>
              <Linkify>{translationText.noteText}</Linkify>

              {/* <span style={{ fontSize: 6 }}>
                {window.navigator.userAgent} <p>{window.navigator.platform}</p>
              </span> */}
            </p>
          </div>
        </div>
        <ModelSelectionContainer
          isLoading={isLoading}
          onChange={handleChange}
        />
      </TabletBreakpoint>
      <PhoneBreakpoint>
        <div className="model-main-container-desktop-new model-main-container-tablet">
          <div className="truck-header">{translationText.truckGuides}</div>
        </div>
        <div className="search-container search-phone">
          <p className="truck-description">
            {translationText.guideDescription}
          </p>
          {/* <div className="make-selection-label">
            {translationText.mobileDescriptionTwo}
          </div> */}

          <div className="note-label-container">
            <p>
              <Linkify>{translationText.noteText}</Linkify>

              {/* <span style={{ fontSize: 6 }}>
                {window.navigator.userAgent} <p>{window.navigator.platform}</p>
              </span> */}
            </p>
          </div>
        </div>
        <ModelSelectionContainer
          isLoading={isLoading}
          onChange={handleChange}
        />
      </PhoneBreakpoint>
    </div>
  );
};

export default TruckModelsParentContainer;
