import { IonContent, IonPage, IonFooter, IonButton } from "@ionic/react";
import "../TiledItems/TiledItems.css";
import FooterContainer from "../../components/FooterContainer/FooterContainer";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import { Card, Container, Row, Col, Button } from "reactstrap";
import { AppContext } from "../../State";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import apiClient from "../../services/http-common";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Highlighter from "react-highlight-words";
import { Device } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import logger from "../../Utils/logger";
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from "react-select";
import { SupportedLanguages } from "../../Utils/HMISupportedLanguages";
import CommonHeaderComponent from "../../components/CommonHeaderComponent/CommonHeaderComponent";
import HmiPopup from "../../components/HmiPopup/HmiPopup";
import { vehicleVINDetails } from "../../Utils/VehicleVINDetails";
interface ContainerProps {
  searchPlaceholder: string;
  noResultsFoundText: string;
  tiledItems: MenuListItems;
  searchLoader: boolean;
}

type TiledItemProps = {
  numberOfRows: number;
  menuItems: MenuListItems;
  parentCallback: any;
};

type MenuListItems = {
  id: number;
  MenuItem: string;
  order: number;
  ParentKey: number;
  createdAt: string;
  updatedAt: string;
  LanguageId: number;
  childItem: any;
}[];

type SearchResultModel = {
  id: number;
  MenuItem: string;
  childElements: {
    menuMasterId: number;
    contentId: number;
    contentName: string;
  }[];
}[];

type TiledContainerTranslations = {
  homeScreen: string;
  searchPlaceholder: string;
  noResultsFound: string;
};

const initialSearchResult: SearchResultModel = [
  {
    id: 17046,
    MenuItem: "No result",
    childElements: [
      {
        menuMasterId: 17047,
        contentId: 460,
        contentName: "WR 03-01-010 Warranty exclusion",
      },
    ],
  },
];

const initialTranslations: TiledContainerTranslations = {
  homeScreen: "Home Screen",
  searchPlaceholder: "Search in the Owner's Manual",
  noResultsFound: "No results found",
};
let info;
const logDeviceInfo = async () => {
  info = await Device.getInfo();

  // logger.info("info", info);
};
logDeviceInfo();
let isnativePlatform;
if (Capacitor.isNativePlatform) {
  isnativePlatform = Capacitor.isNativePlatform() ? "true" : "false";
  // logger.info("isNativePlatform", Capacitor.isNativePlatform());
}
// logger.info("window.navigator.userAgent", window.navigator.userAgent);
// logger.info("window.navigator.platform", window.navigator.platform);
// logger.info("Language", navigator.language);
let browser = "teset";
browser = (function () {
  var test = function (regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
})();
let findFromWhere = "";
if (isnativePlatform == true) {
  // logger.info("Browser", browser);
  if (browser == "Other") {
    logger.info("Running From Mobile APK or HMI");
    findFromWhere = "Running From Mobile APK or HMI";
  } else {
    findFromWhere = "Running From Mobile Web Browser";
    logger.info("Running From Mobile Web Browser");
  }
} else {
  findFromWhere = "Running From WEB";
  logger.info("Running From WEB");
}

logger.info("browser", browser);
// const TabletPhoneHeaderComponent: React.FC = () => {
//   const history = useHistory();
//   const { state } = useContext(AppContext);
//   return (
//     <div className="tiled-header">
//       {state.isVINAvailable ? (
//         <div className="header-left-common-hmi"></div>
//       ) : (
//         <div className="header-left-common">
//           <img
//             className="benz-tiled-page-logo-common"
//             // src="assets/images/Image2.svg"
//             // src="assets/images/Wortmarke_Stern-3.svg"
//             src={
//               process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
//             }
//             alt="Logo"
//           />
//         </div>
//       )}
//       <div className="header-right-common">
//         <div>
//           <a
//             href="https://www.evobus.com/de-de/menus/footer/anbieter/"
//             className="homelink-mercedes"
//             target="_blank"
//             rel="noopener"
//           >
//             <img
//               // src="assets/images/info_icon.png"
//               src={process.env.PUBLIC_URL + "/assets/images/info_icon.png"}
//               className="link-image-tiled-page"
//               alt="privacy-icon"
//             ></img>
//           </a>
//           <a
//             onClick={() => {
//               history.push("/truckownermanuals");
//             }}
//           >
//             <img
//               // src="assets/images/to_home.png"

//               src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
//               className={`link-image-in-manual link-image-phone ${
//                 state.isVINAvailable ? "hmi-width" : ""
//               }`}
//               alt="home-icon"
//             ></img>
//           </a>
//         </div>
//         <a
//           className="homelink-mercedes"
//           onClick={() => {
//             window.location.reload();
//           }}
//         >
//           <img
//             // src="assets/images/mobile-button.jpg"\
//             src={process.env.PUBLIC_URL + "/assets/images/mobile-button.jpg"}
//             className="menu-button-tiled-page"
//             alt="menu-icon"
//           ></img>
//         </a>
//       </div>
//     </div>
//   );
// };

const formatResponse = (res: JSON) => {
  const jsonString = JSON.stringify(res);
  var jsObject = JSON.parse(jsonString);
  logger.info(`Right item API response ${jsObject}`);
  return jsObject;
};

let SearchLoading = false;
const SearchBox: React.FC<ContainerProps> = (props) => {
  const initialMenuListItems: MenuListItems = [];

  const [searchLoader, setSearchLoader] = useState(false);
  const history = useHistory();

  const [q, setQ] = useState("");
  const [validSearch, setValidSearch] = useState(0);
  const { state, dispatch } = useContext(AppContext);
  const [tiledItems, setTiledItems]: [
    MenuListItems,
    Dispatch<SetStateAction<MenuListItems>>
  ] = useState(initialMenuListItems);

  const [searchResult, setSearchResult]: [
    SearchResultModel,
    Dispatch<SetStateAction<SearchResultModel>>
  ] = useState(initialSearchResult);

  const callSearchAPI = async (input: string) => {
    setSearchLoader(true);
    SearchLoading = true;
    var translationLangCode = localStorage.getItem("langCode");
    try {
      let result: any;
      if (state.isVINAvailable || state.vehicleVIN) {
        result = await apiClient.get(
          `/retrivergetSearchedItem/${input}/${
            state.vehicleInfoBasedOnVIN?.vehicleName
          }/${state.vehicleInfoBasedOnVIN?.vehicleModel}/${localStorage.getItem(
            "hmiSystemLanguage"
          )}/${
            localStorage.getItem("hmiSystemLanguage") === "de_DE" ? "de_DE" : ""
          }`
        );
      } else {
        result = await apiClient.get(
          `/retrivergetSearchedItem/${input}/${state.selectedVehicleName}/${
            state.selectedVehicleModel
          }/${state.selectedManualLanguageCode}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
      }
      // setSearchResult(formatResponse(result.data?.menuContent));

      // previous search implementation
      // setSearchResult(formatResponse(result.data));

      // new search updates
      setSearchResult(result?.data);
      setSearchLoader(false);
      SearchLoading = false;
      logger.info(`Search items API response ${result.data}`);
      dispatch({
        type: "setManualSearchFieldValueControl",
        payload: {
          manualSearchFieldValueControl:
            state.manualSearchFieldValueControl + 1,
        },
      });
    } catch (err: any) {
      logger.info(`Search items API error ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      // history.push("/truckownermanuals/error");
      setSearchResult(initialSearchResult);
    }
  };

  useEffect(() => {
    // Condition to prevent search api call on page load
    //     if (validSearch != 0) {
    //       callSearchAPI(q);
    //     }
    //     setTiledItems(props.tiledItems);

    // Throttling added for search functionality

    // wait 500ms before executing search
    let timeoutID = setTimeout(() => {
      // do not search if input is empty
      if (validSearch != 0) {
        callSearchAPI(q);
      }
    }, 500);

    //  clear current timer
    return () => {
      clearTimeout(timeoutID);
    };
  }, [validSearch]);

  useEffect(() => {
    if (state.searchedQueryInManual != "") {
      setQ(state.searchedQueryInManual);
      setValidSearch(validSearch + 1);
    } else {
      setValidSearch(0);
    }
  }, [state.searchedQueryInManual]);

  function checkIfChildPresentForSelectedItem(
    id: number,
    level: number,
    childItemId?: number
  ) {
    var levelHierarchy = ["", "", "", "", ""];
    state.tiledItemsData.map((items) => {
      if (items.id == id) {
        levelHierarchy[0] = items.MenuItem;
        if (level == 0) {
          levelHierarchy[1] = "";
          if (items.childItem.length != 0) {
            dispatch({
              type: "setChildItemForSelectedTileID",
              payload: {
                childItemsForSelectedID: items.childItem,
              },
            });
            localStorage.setItem(
              "childItemObj",
              JSON.stringify(items.childItem)
            );
            dispatch({
              type: "setIsChildItemsPresent",
              payload: {
                isChildItemPresent: true,
              },
            });
            localStorage.setItem("isChildItemPresent", "true");
          }
        } else {
          items.childItem.map((levelTwoItem) => {
            if (levelTwoItem.id == childItemId) {
              if (levelTwoItem.childItem.length != 0) {
                dispatch({
                  type: "setChildItemForSelectedTileID",
                  payload: {
                    childItemsForSelectedID: levelTwoItem.childItem,
                  },
                });
                localStorage.setItem(
                  "childItemObj",
                  JSON.stringify(levelTwoItem.childItem)
                );
                dispatch({
                  type: "setIsChildItemsPresent",
                  payload: {
                    isChildItemPresent: true,
                  },
                });
                localStorage.setItem("isChildItemPresent", "true");
              } else {
                dispatch({
                  type: "setIsChildItemsPresent",
                  payload: {
                    isChildItemPresent: false,
                  },
                });
                localStorage.setItem("isChildItemPresent", "false");
              }
              levelHierarchy[1] = levelTwoItem.MenuItem;
            }
          });
        }
      }
    });
    dispatch({
      type: "setSelectedLevelHierarchy",
      payload: {
        selectedLevelHierarchy: levelHierarchy,
      },
    });
    localStorage.setItem(
      "selectedLevelHierarchy",
      JSON.stringify(levelHierarchy)
    );
  }

  function SearchResult() {
    const updatedRes = [
      {
        id: 17046,
        MenuItem: "W 03 Warranty Distinction -",
        childElements: [
          {
            menuMasterId: 17047,
            contentId: 460,
            contentName: "WR 03-01-010 Warranty exclusion",
          },
        ],
      },
    ];

    if (searchLoader) {
      return (
        <>
          <LoadingOverlay active={searchLoader} spinner></LoadingOverlay>
        </>
      );
    }

    if (searchResult.length == 0 && q.length >= 3) {
      return <div className="search-unfound">{props.noResultsFoundText}</div>;
    } else if (
      searchResult.length == 1 &&
      searchResult[0].MenuItem == "No result"
    ) {
      return null;
    } else if (q.length < 3) {
      return null;
    } else {
      return (
        <div>
          {searchResult.map((items, index) => {
            return (
              <Collapsible
                trigger={
                  <div className="header">
                    <Highlighter
                      highlightClassName="highlighted-text"
                      searchWords={[q]}
                      autoEscape={false}
                      textToHighlight={items.MenuItem}
                    />
                  </div>
                }
                key={index}
                triggerWhenOpen={
                  <div className="header header-open">
                    <Highlighter
                      highlightClassName="highlighted-text"
                      searchWords={[q]}
                      autoEscape={false}
                      textToHighlight={items.MenuItem}
                    />
                  </div>
                }
              >
                <ul className="left-menu-list-container-collapse">
                  {items.childElements.map((levelOneItem: any, index) => (
                    <li className="left-menu-list-item-collapse" key={index}>
                      <Link
                        className="left-menu-item-link-collapse"
                        to="/truckownermanuals/manual"
                        onClick={() => {
                          dispatch({
                            type: "setIsNavigatedFromSearch",
                            payload: {
                              isNavigatedFromSearch: true,
                            },
                          });
                          localStorage.setItem("isNavigatedFromSearch", "true");
                          dispatch({
                            type: "setIsChildItemsPresent",
                            payload: {
                              isChildItemPresent: false,
                            },
                          });
                          localStorage.setItem("isChildItemPresent", "false");
                          dispatch({
                            type: "setSelectedSearchID",
                            payload: {
                              selectedSearchID: levelOneItem.contentId,
                            },
                          });
                          localStorage.setItem(
                            "searchID",
                            `${levelOneItem.contentId}`
                          );
                        }}
                      >
                        {levelOneItem.contentName}
                      </Link>
                      <span className="left-menu-item-separator-collapse"></span>
                    </li>
                  ))}
                </ul>
              </Collapsible>
            );
          })}
        </div>
      );
    }
  }

  const handleChange = (valueString: string) => {
    setQ(valueString);
    if (valueString.length >= 3) {
      setValidSearch(validSearch + 1);
    } else {
      if (q.length >= 3) {
        setSearchResult([]);
      }
    }
  };

  //   function onKeyDown(keyEvent) {
  //     if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
  //       keyEvent.preventDefault();
  //     }
  //   }
  function myFunction(event) {
    event.preventDefault();
    //code here
  }

  return (
    <div>
      <div className="search-box-tiled-page">
        <div className="search-box-container">
          {/* <form  onsubmit={(e) => myFunction(e)}> */}
          <div>
            <input
              type="text"
              name="search"
              placeholder={props.searchPlaceholder}
              className="search-box"
              autoComplete="off"
              value={q}
              onChange={(e) => {
                setQ(e.target.value);
                handleChange(e.target.value);
              }}
            ></input>
          </div>
          <div>
            <input type="submit" className="submit-search"></input>
          </div>
          {/* </form> */}
        </div>
      </div>
      <div className="collapsible-container">{SearchResult()}</div>
    </div>
  );
};

const TileParentContainer: React.FC<TiledItemProps> = (props) => {
  const history = useHistory();
  return (
    <div className="tile-parent-container">
      <Container className="tiled-container">
        <Row xs={props.numberOfRows} className="row-tiled-items">
          {[...Array(props.menuItems.length)].map((e, i) => {
            return (
              <Col className="col-tiled-items" key={i}>
                <Card
                  // onClick={() => {logger.info("clicked"); history.push("/truckownermanuals/manual")}}
                  className="card border-0"
                >
                  <div
                    className="kachel-layout__item"
                    onClick={() => {
                      props.parentCallback(
                        props.menuItems[i].id,
                        props.menuItems[i].MenuItem
                      );
                      logger.info(`test id ${props.menuItems[i].id}`);
                    }}
                  >
                    <Link
                      className="tile-item-link"
                      to={{
                        pathname: "/truckownermanuals/manual",
                      }}
                    >
                      <div className="kachel-layout__item__text">
                        <p className="p-layout-item-text">
                          {props.menuItems[i].MenuItem}
                        </p>
                      </div>
                    </Link>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

const TiledItems: React.FC = (props) => {
  // logger.info("TiledPages Props", props);
  // console.log("Tilesss", props?.match);
  // console.log(
  //   "SupportedLanguages",
  //   SupportedLanguages[props?.match?.params?.lang]
  // );

  const { state, dispatch } = useContext(AppContext);
  // console.log("Check for isHMI", state.isVINAvailable);
  // storing HMI system Language from URL params
  useEffect(() => {
    if (state.isVINAvailable) {
      let vehicleDetails;
      let VINNumberFromVehicle = props?.match?.params?.vehline + props?.match?.params?.drivesys;
      Object.keys(vehicleVINDetails).map((vehicle) => {
        if (vehicle == VINNumberFromVehicle) {
          vehicleDetails = vehicleVINDetails[vehicle];
        }
      });
      // debugger;
      if(vehicleDetails) {
        dispatch({
          type: "setVehicleVIN",
          payload: {
            vehicleVIN: vehicleDetails?.vehicleVIN
              ? vehicleDetails?.vehicleVIN
              : "",
          },
        });
        dispatch({
          type: "setVehicleInfoBasedOnVIN",
          payload: {
            vehicleInfoBasedOnVIN: vehicleDetails ? vehicleDetails : {},
          },
        });
        localStorage.setItem(
          "hmiSystemLanguage",
          vehicleDetails.supportedLanguages[props?.match?.params?.lang] || "en_GB"
        );
      }
      else{
        localStorage.setItem("HMIError", "true");
      }
    } else {
      localStorage.removeItem("hmiSystemLanguage");
    }
  }, []);

  const history = useHistory();

  const [translationTexts, setTranslationsText]: [
    TiledContainerTranslations,
    Dispatch<SetStateAction<TiledContainerTranslations>>
  ] = useState(initialTranslations);

  const initialMenuListItems: MenuListItems = [
    // {
    //   id: 1,
    //   MenuItem: "No items found",
    //   order: 0,
    //   ParentKey: 0,
    //   createdAt: "",
    //   updatedAt: "",
    //   LanguageId: 1,
    //   childItem: [],
    // },
  ];

  const [tiledMenuItems, setTiledMenuItems]: [
    MenuListItems,
    Dispatch<SetStateAction<MenuListItems>>
  ] = useState(initialMenuListItems);

  const [isLoaderActive, setIsLoaderActive] = useState(false);

  // var imgLocation: string = `assets/images/truck_images_tiled/atego_tiled.png`;
  var imgLocation: string = `/truckownermanuals/assets/images/truck_images_tiled/${localStorage.getItem(
    "EnglishVehicleName"
  )}_tiled.png`;

  useEffect(() => {
    logger.info("Manual Lang code is ---", state.selectedManualLanguageCode);
    getTiledtMenuContents();
  }, [state.selectedManualLanguageCode]);

  useEffect(() => {
    logger.info(
      "current saved lang model in the tiled page",
      state.selectedLanguageModel,
      "Lang code ---",
      state.selectedLanguageCode
    );
    getTranslations();
  }, [state.selectedLanguageCode]);

  // default leftMenu ID - first Time
  useEffect(() => {
    dispatch({
      type: "setSelectedLeftMenuID",

      payload: {
        selectedLeftMenuID: 1,
      },
    });

    localStorage.setItem("leftMenuID", `${1}`);

    logger.info("Setting default left menu id: 1");
  }, []);

  const formatResponse = (res: JSON) => {
    const jsonString = JSON.stringify(res);
    var jsObject = JSON.parse(jsonString);
    logger.info(`JSObject: ${jsObject}`);
    return jsObject;
  };

  function getTiledtMenuContents() {
    try {
      getAllMenuContents(state.selectedManualLanguageCode, 0, 0);
    } catch (err) {
      logger.info(err);
    }
  }

  let HMILangugageCode = localStorage.getItem("hmiSystemLanguage");
  const getTranslations = async () => {
    try {
      var result: any;
      var data: any;

      result = await apiClient.get(
        `/retrivergetTranslatedFooter/${state.selectedLanguageCode}/$$Home_screen%7C$$Search_placeholder%7C$$No_result_found`
      );
      data = result.data?.footerDetails;
      var translationInfo = translationTexts;
      data?.map((items) => {
        if (items.Variable_Name == "$$Home_screen") {
          translationInfo.homeScreen = items.translated_text;
        } else if (items.Variable_Name == "$$Search_placeholder") {
          translationInfo.searchPlaceholder = items.translated_text;
        } else {
          translationInfo.noResultsFound = items.translated_text;
        }
      });
      setTranslationsText({ ...translationInfo });
      logger.info(`Tiled Page API result`, data);
    } catch (err: any) {
      logger.info(`Tiled Page Translation API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
    }
  };

  const getAllMenuContents = async (
    langCode: String,
    order: number,
    parentKey: number
  ) => {
    setIsLoaderActive(true);
    try {
      logger.info(
        `Tiled Menu URL - /getMenuItems/${order}/${parentKey}/${langCode}/${state.selectedVehicleName}/${state.selectedVehicleModel}`
      );

      var result: any;
      var translationLangCode = localStorage.getItem("langCode");
      // console.log("vehicleInfoBasedOnVIN----", state.vehicleInfoBasedOnVIN);
      // console.log("HMI LANG-->", HMILangugageCode);
      // debugger;
      if (state.isVINAvailable || state.vehicleVIN) {
        let vehicleDetails;
        let VINNumberFromVehicle = props?.match?.params?.vehline + props?.match?.params?.drivesys;
        Object.keys(vehicleVINDetails).map((vehicle) => {
          if (vehicle == VINNumberFromVehicle) {
            vehicleDetails = vehicleVINDetails[vehicle];
          }
        });

        result = await apiClient.get(
          `/retrivergetMenuItems/0/0/${localStorage.getItem(
            "hmiSystemLanguage"
          )}/${vehicleDetails.vehicleName}/${vehicleDetails.vehicleModel}/${
            localStorage.getItem("hmiSystemLanguage") === "de_DE" ? "de_DE" : ""
          }`
        );
        // console.log("HMIERROR", typeof result.data);
        if(typeof result.data === 'string') {
          localStorage.setItem("HMIError", "true");
          history.push("/truckownermanuals/error");
        }
        
      } else {
        result = await apiClient.get(
          `/retrivergetMenuItems/0/0/${langCode}/${state.selectedVehicleName}/${
            state.selectedVehicleModel
          }/${translationLangCode === "en_GB" ? "" : translationLangCode}`
        );
      }
      // } else {
      //   // debugger;
      //   result = await apiClient.get(
      //     `/retrivergetMenuItems/0/0/${langCode}/${
      //       state.selectedVehicleName == "Unimog Geräteträger"
      //         ? "Unimog implement carrier"
      //         : state.selectedVehicleName
      //     }/${
      //       state.selectedVehicleModel == "aktuelle modell"
      //         ? "Current model"
      //         : state.selectedVehicleModel
      //     }`
      //   );
      // }

      if (result.data?.length == 0) {
        setIsLoaderActive(false);
        history.push("/truckownermanuals/error");
        setTiledMenuItems(initialMenuListItems);
      }
      setTiledMenuItems(formatResponse(result.data));
      dispatch({
        type: "setTiledItemsData",
        payload: {
          tiledItemsData: formatResponse(result.data),
        },
      });
      localStorage.setItem(
        "tiledItemsData",
        JSON.stringify(formatResponse(result.data))
      );
      setIsLoaderActive(false);
      logger.info(`Tiled menu contents: `, result.data);
    } catch (err: any) {
      logger.info(`Get Tiled menu items API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      setIsLoaderActive(false);
      history.push("/truckownermanuals/error");
      setTiledMenuItems(initialMenuListItems);
    }
  };

  function handleCallback(id: number, menuItem: string) {
    dispatch({
      type: "setSelectedLeftMenuID",
      payload: {
        selectedLeftMenuID: 0,
      },
    });
    dispatch({
      type: "setSelectedTileID",
      payload: {
        selectedTileID: id,
      },
    });
    dispatch({
      type: "setIsChildItemsPresent",
      payload: {
        isChildItemPresent: false,
      },
    });

    dispatch({
      type: "setIsLevelOneItemSelected",
      payload: {
        isLevelOneItemSelected: false,
      },
    });

    dispatch({
      type: "setIsNavigatedFromSearch",
      payload: {
        isNavigatedFromSearch: false,
      },
    });

    localStorage.setItem("isNavigatedFromSearch", "false");
    localStorage.setItem("isChildItemPresent", "false");
    localStorage.setItem("tileID", `${id}`);
    localStorage.setItem("isLevelOneItemSelected", "false");
    checkIfChildItemPresent(id);
    // getAllMenuContents(state.selectedLanguageCode, 1, id, true);

    var levelHierarchy = ["", "", "", "", ""];
    levelHierarchy[0] = menuItem;
    dispatch({
      type: "setSelectedLevelHierarchy",
      payload: {
        selectedLevelHierarchy: levelHierarchy,
      },
    });
    localStorage.setItem(
      "selectedLevelHierarchy",
      JSON.stringify(levelHierarchy)
    );

    logger.info(`Selected Id -- ${id} and Selected MenuItem -- ${menuItem}`);
  }

  function checkIfChildItemPresent(id: number) {
    tiledMenuItems.map((items, index) => {
      if (items.id == id) {
        if (items.childItem.length != 0) {
          dispatch({
            type: "setIsChildItemsPresent",
            payload: {
              isChildItemPresent: true,
            },
          });
          localStorage.setItem("childItemObj", JSON.stringify(items.childItem));
          dispatch({
            type: "setChildItemForSelectedTileID",
            payload: {
              childItemsForSelectedID: items.childItem,
            },
          });
          localStorage.setItem("isChildItemPresent", "true");
        }
      }
    });
  }

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  useEffect(() => {
    setTimeout(function () {
      scrollIntoView();
    }, 1000);
  }, [state.searchedQueryInManual]);

  function scrollIntoView() {
    document.getElementById("search-bookmark")?.scrollIntoView();
  }

  logger.info("state", state);
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 50,
      minHeight: 50,
      borderRadius: 2,
      boxShadow: "none",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    dropdownIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      paddingRight: 20,
    }),

    menu: (styles: any) => ({
      ...styles,
      padding: "0px 0px  0px 10px",
      scrollPadding: "50px 0 0 50px",
    }),

    option: (styles: any, { isDisabled, isSelected }) => ({
      ...styles,
      borderBottom: "1px solid #a8a8a8",
      fontSize: "16px",
      fontFamily: "DaimlerCS-Regular",
      borderSpacing: "100px",
      color: isSelected ? "#ffffff" : "#000000",
      opacity: isDisabled ? "0.55" : "1",
      fontStyle: isDisabled ? "italic" : "regular",
    }),

    placeholder: (styles: any, { isDisabled }) => ({
      ...styles,
      fontSize: "16px",
      fontFamily: "DaimlerCS-Regular",
      color: isDisabled ? "#e1dddd" : "black",
    }),
  };

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: "en_GB",
    value: 1,
  });
  const handleLangChange = (selectedOption) => {
    // console.log("selected Option", selectedOption);
    setSelectedLanguage(selectedOption);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(true);
  console.log("popup", isPopupVisible, state.isVINAvailable);
  return (
    <IonPage>
      <IonContent fullscreen ref={contentRef} scrollEvents={true}>
        <LoadingOverlay active={isLoaderActive} spinner>
          {!state.isVINAvailable && <CommonHeaderComponent />}
          <DesktopBreakpoint>
            {/* <div className="tiled-header">
              {state.isVINAvailable ? (
                <div className="header-left-common-hmi"></div>
              ) : (
                <div className="header-left-desktop">
                  <img
                    className="benz-tiled-page-logo-desktop"
                    // src="assets/images/Image2.svg"
                    // src="assets/images/Wortmarke_Stern-3.svg"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Wortmarke_Stern-3.svg"
                    }
                    alt="Logo"
                  />
                </div>
              )}
              <div className="header-right-desktop">
                <div className="">
                  {/* <Select
                    maxMenuHeight={200}
                    menuPlacement="auto"
                    className="react-select"
                    isDisabled={false}
                    
                    isSearchable={false}
                    
                    onChange={(event) => handleLangChange(event)}
                    value={selectedLanguage}
                    options={[
                      { label: "en_GB", value: 1 },
                      { label: "de_DE", value: 2 },
                    ]}
                   
                  /> */}
            {/* </div> */}
            {/* <a
                  className="homelink-mercedes-tiled-page"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span className="homelink-name-tiled-page">
                    {translationTexts.homeScreen}
                  </span>
                </a> */}
            {/* <a
                  href="https://www.mercedes-benz-trucks.com/"
                  className="homelink-mercedes"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    // src="assets/images/info_icon.png"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/info_icon.png"
                    }
                    className="link-image-tiled-page"
                    alt="privacy-icon"
                  ></img>
                </a>
                <a
                  onClick={() => {
                    state.isVINAvailable
                      ? history.push("/truckownermanuals/tiled-items")
                      : history.push("/truckownermanuals");
                  }}
                >
                  <img
                    // src="assets/images/to_home.png"
                    src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                    // className="link-image-in-manual link-image-phone"
                    className={`link-image-in-manual link-image-phone ${
                      state.isVINAvailable ? "hmi-navigation-icons" : ""
                    }`}
                    alt="home-icon"
                  ></img>
                </a>
              </div>
            </div>*/}
            {state.isVINAvailable ? null : (
              <figure className="mb-banner">
                <img className="banner-img" src={imgLocation} alt="truck img" />
              </figure>
            )}
            {state.isVINAvailable && isPopupVisible && (
              <HmiPopup
                setIsPopupVisible={setIsPopupVisible}
                lang={props?.match?.params?.lang}
              />
            )}
            <div className="search-bookmark" id="search-bookmark"></div>
            <SearchBox
              searchPlaceholder={translationTexts.searchPlaceholder}
              noResultsFoundText={translationTexts.noResultsFound}
              tiledItems={tiledMenuItems}
              searchLoader={SearchLoading}
            />
            <TileParentContainer
              numberOfRows={4}
              menuItems={tiledMenuItems}
              parentCallback={handleCallback}
            />
            <div className="version-label">
              <p className="version-text">{localStorage.getItem("version")}</p>
            </div>
          </DesktopBreakpoint>
          <TabletBreakpoint>
            {/* <TabletPhoneHeaderComponent /> */}
            {state.isVINAvailable ? null : (
              <figure className="mb-banner">
                <img className="banner-img" src={imgLocation} alt="truck img" />
              </figure>
            )}
            {state.isVINAvailable && isPopupVisible && (
              <HmiPopup
                setIsPopupVisible={setIsPopupVisible}
                lang={props?.match?.params?.lang}
              />
            )}
            <SearchBox
              searchPlaceholder={translationTexts.searchPlaceholder}
              noResultsFoundText={translationTexts.noResultsFound}
              tiledItems={tiledMenuItems}
              searchLoader={SearchLoading}
            />
            <TileParentContainer
              numberOfRows={3}
              menuItems={tiledMenuItems}
              parentCallback={handleCallback}
            />
            <div className="version-label">
              <p className="version-text">{localStorage.getItem("version")}</p>
            </div>
          </TabletBreakpoint>
          <PhoneBreakpoint>
            {/* <TabletPhoneHeaderComponent /> */}
            {state.isVINAvailable ? null : (
              <figure className="mb-banner">
                <img className="banner-img" src={imgLocation} alt="truck img" />
              </figure>
            )}
            {state.isVINAvailable && isPopupVisible && (
              <HmiPopup
                setIsPopupVisible={setIsPopupVisible}
                lang={props?.match?.params?.lang}
              />
            )}
            <SearchBox
              searchPlaceholder={translationTexts.searchPlaceholder}
              noResultsFoundText={translationTexts.noResultsFound}
              tiledItems={tiledMenuItems}
              searchLoader={SearchLoading}
            />
            <TileParentContainer
              numberOfRows={1}
              menuItems={tiledMenuItems}
              parentCallback={handleCallback}
            />
            <div className="version-label">
              <p className="version-text">{localStorage.getItem("version")}</p>
            </div>
          </PhoneBreakpoint>
          {/* <p style={{ color: "white", fontSize: "12px" }}>
            URL param : {props?.match?.params?.lang}
          </p>
          <p style={{ color: "white", fontSize: "12px" }}>
            HMI Langugage : {HMILangugageCode}
          </p> */}
          {/* <p style={{ color: "white" }}>{isnativePlatform}</p> */}
          {/* <p style={{ color: "white" }}>{browser}</p> */}
          {/* <p style={{ color: "white" }}>{findFromWhere}</p> */}
          {/* <p style={{ color: "white" }}>{navigator.language}</p> */}

          {/* {state.isVINAvailable ? (
            <>
              <p style={{ color: "white" }}>
                platform: {info?.platform}- osVersion: {info?.osVersion}-
                webViewVersion: {info?.webViewVersion}- operatingSystem:
                {info?.operatingSystem}- model: {info?.model}- manufacturer:{" "}
                {info?.manufacturer}
              </p>
            </>
          ) : ( */}
            {/* // <p style={{ color: "white" }}>
            //   platform: {info?.platform}- osVersion: {info?.osVersion}-
            //   webViewVersion: {info?.webViewVersion}- operatingSystem:
            //   {info?.operatingSystem}- model: {info?.model}- manufacturer:{" "}
            //   {info?.manufacturer}
            // </p> */}
            <IonFooter class="ionic-footer-in-tiled-items">
              <span className="tiled-items-footer-separator"></span>
              <div className="tiled-items-footer-container">
                <FooterContainer onClickScrollToTop={scrollToTop} />
              </div>
            </IonFooter>
        </LoadingOverlay>
      </IonContent>
    </IonPage>
  );
};

export default TiledItems;
