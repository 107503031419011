import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./State";
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import { vehicleVINDetails } from "./Utils/VehicleVINDetails";
import { AppContext } from "./State";
import { Device } from "@capacitor/device";
import logger from "./Utils/logger";
const queryClient = new QueryClient();

const RootComponent = () => {
  const { state, dispatch } = useContext(AppContext);
  const [hmi, setHmi] = useState(null);
  let info;
  // const logDeviceInfo = async () => {
  //   info = await Device.getInfo();
  //   logger.info("info", info);
  // };
  const logDeviceInfo = async () => {
    info = await Device.getInfo();
    console.log("info", info);
    setHmi(info);
  };
  useEffect(() => {
    logDeviceInfo();
  }, []);
  // const [VINBool, setVINBool] = useState(false);
  // const [newvehicleDetails, setNewVehicleDetails] = useState({});
  // let VINNumberFromVehicle = "W1T98300310628787";
  // let hmiValue = "arm64";

  // let VINBool = false;
  // let vehicleDetails;
  // const getVehicleInformation = () => {
  //   Object.keys(vehicleVINDetails).map((vehicle) => {
  //     if (vehicle == VINNumberFromVehicle) {
  //       vehicleDetails = vehicleVINDetails[vehicle];
  //     }
  //   });
  //   logger.info("vechileDetails", vehicleDetails);

  //   if (vehicleDetails?.vehicleVIN) {
  //     setVINBool(true);
  //     setNewVehicleDetails({ vehicleDetails });
  //   }
  // };

  // useEffect(() => {
  //   getVehicleInformation();
  // }, []);

  // useEffect(() => {}, [newvehicleDetails]);

  // let info;
  // const logDeviceInfo = async () => {
  //   info = await Device.getInfo();

  //   logger.info("info", info);
  //   // if (info.model == "Windows NT 10.0") {
  //   //   setVINBool(false);
  //   // } else {
  //   //   setVINBool(true);
  //   // }
  //   setVINBool(true);
  // };

  // useEffect(() => {
  //   logDeviceInfo();
  // }, []);

  // useEffect(() => {
  //   logger.info("NEWVIN", VINBool);
  // }, [VINBool]);
  // logger.info("VIN", VINBool);
  return (
    <AppContextProvider>
      {/* {VINBool && <App hmi={VINBool ? newvehicleDetails : ""} />}
      
      */}
      {!hmi ? <div></div> : <App h={hmi} />}
    </AppContextProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RootComponent />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
