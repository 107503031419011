import "./Manual.css";
import { IonContent, IonPage, IonFooter } from "@ionic/react";
import ManualParentContainer from "../../components/ManualParentContainer/ManualParentContainer";
import FooterContainer from "../../components/FooterContainer/FooterContainer";
import { useContext, useRef, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { AppContext } from "../../State";
import CommonHeaderComponent from "../../components/CommonHeaderComponent/CommonHeaderComponent";

const Manual: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { state, dispatch } = useContext(AppContext);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  useEffect(() => {
    dispatch({
      type: "setIsLeftMenuHiddenInPhone",
      payload: {
        isLeftMenuHiddenInPhone: true,
      },
    });
  }, []);
  return (
    <IonPage>
      <IonContent fullscreen ref={contentRef} scrollEvents={true}>
        <LoadingOverlay active={state.isManualLoaderActive} spinner>
          <CommonHeaderComponent />
          <ManualParentContainer onClickScrollToTop={scrollToTop} />
          <div className="version-label-manual">
            <p className="version-text-manual">
              {localStorage.getItem("version")}
            </p>
          </div>
          {state.isVINAvailable ? (
            ""
          ) : (
            <IonFooter class="ionic-footer-in-manual">
              <span className="footer-separator"></span>
              <div className="manual-footer-container">
                <FooterContainer onClickScrollToTop={scrollToTop} />
              </div>
            </IonFooter>
          )}
        </LoadingOverlay>
      </IonContent>
    </IonPage>
  );
};

export default Manual;
