export const poupTranslations = {
    sq: "The Interactive Owner’s Manual serves only as a supplement and may deviate from the actual specifications of your vehicle in individual cases. Therefore, always observe the printed Operator’s Manual that was supplied with your vehicle.",
    ar: "يُعد دليل التشغيل التفاعلي بمثابة ملحق فقط وقد يختلف في بعض الحالات عن المواصفات الفعلية لسيارتك. لذلك يرجى دائماً مراعاة دليل التشغيل المطبوع الذي تم تسليمه مع سيارتك.",
    bg: "Интерактивното ръководство за експлоатация служи само като допълнение и в отделни случаи може да се различава от действителните спецификации на Вашия автомобил. Затова винаги спазвайте печатното ръководство за експлоатация, което е било доставено с Вашия автомобил.",
    hr: "Interaktivne upute za rad služe samo kao dopuna i u pojedinim slučajevima mogu odstupati od stvarnih specifikacija vašeg vozila. Stoga se uvijek pridržavajte tiskanih uputa za rad koje su isporučene s vozilom.",
    cs: "Interaktivní návod k obsluze slouží pouze jako doplněk a může se v jednotlivých případech lišit od skutečných specifikací Vašeho vozidla. Proto se vždy řiďte tištěným návodem k obsluze, který byl dodán s Vaším vozidlem.",
    da: "Den interaktive instruktionsbog er kun et supplement og kan i enkelte tilfælde afvige fra din bils faktiske specifikationer. Vær derfor altid opmærksom på den trykte instruktionsbog, som fulgte med bilen.",
    nl: "De interactieve handleiding dient slechts als aanvulling en kan in individuele gevallen van de daadwerkelijke specificaties van uw auto afwijken. Daarom altijd de gedrukte handleiding in acht nemen die bij het voertuig is geleverd.",
    et: "Interaktiivne kasutusjuhend on ainult täienduseks ja võib üksikutel juhtudel teie sõiduki tegelikest spetsifikatsioonidest erineda. Järgige seepärast alati sõidukiga kaasas olevat trükitud kasutusjuhendit.",
    fi: "Interaktiivinen käyttöohjekirja on tarkoitettu vain täydennykseksi, ja se voi yksittäistapauksissa poiketa autosi todellisista teknisistä tiedoista. Noudata siksi aina auton mukana toimitettua painettua käyttöohjekirjaa.",
    de: "Die interaktive Betriebsanleitung dient lediglich als Ergänzung und kann im Einzelfall von den tatsächlichen Spezifikationen Ihres Fahrzeugs abweichen. Bitte beachten Sie daher stets die ausgedruckte Betriebsanleitung, die mit Ihrem Fahrzeug ausgeliefert wurde.",
    el: "Οι διαδραστικές Οδηγίες Χρήσης αποτελούν απλώς ένα συμπλήρωμα και ενδέχεται σε μεμονωμένες περιπτώσεις να διαφέρουν από τις πραγματικές προδιαγραφές του οχήματός σας. Λαμβάνετε πάντα υπόψη τις έντυπες Οδηγίες Χρήσης που παραδόθηκαν μαζί με το όχημά σας.",
    iw: "הוראות ההפעלה האינטראקטיביות משמשות רק כהשלמה ועשויות להיות שונות מהמפרטים בפועל של רכבך במקרים בודדים. לכן, פעל תמיד לפי הוראות ההפעלה המודפסות שסופקו עם הרכב שלך.",
    hu: "Az interaktív kezelési útmutató csupán kiegészítésként szolgál, és egyes esetekben eltérhet az Ön gépkocsijának tényleges specifikációitól. Ezért mindig vegye figyelembe a gépkocsijához mellékelt nyomtatott kezelési útmutatót.",
    it: "Le Istruzioni d'uso interattive sono solo un'integrazione e possono differire dalle specifiche effettive del veicolo in singoli casi. Si raccomanda pertanto di consultare sempre la versione cartacea delle Istruzioni d'uso in dotazione al veicolo.",
    ja: "インタラクティブ取扱説明書は補足版に過ぎず、車両の実際の仕様と異なる場合があります。 そのため、必ず車両に同梱されている印刷版の取扱説明書を確認してください。",
    ko: "인터랙티브 사용 설명서는 보충용으로만 제공되며, 경우에 따라 차량의 실제 사양과 다를 수 있습니다. 따라서 차량과 함께 제공된 사용 설명서 책자를 항상 참조하십시오.",
    lt: "Interaktyvi naudojimo instrukcija yra tik papildymas ir atskirais atvejais gali skirtis nuo faktinių Jūsų transporto priemonės specifikacijų. Todėl visada atkreipkite dėmesį į atspausdintą naudojimo instrukciją, kuri buvo pristatyta su jūsų transporto priemone.",
    mk: "Interaktivno uputstvo za upotrebu služi samo kao dodatak i u pojedinačnim slučajevima može da odstupa od zaista važećih specifikacija vašeg vozila. Uvek obraćajte pažnju na štampano uputstvo za rad koje je isporučeno sa vašim vozilom.",
    no: "Den interaktive instruksjonsboken er kun ment som et supplement og kan i enkelte tilfeller avvike fra de faktiske spesifikasjonene til bilen din. Følg derfor alltid den trykte instruksjonsboken som fulgte med bilen.",
    pl: "Interaktywna instrukcja obsługi służy jedynie jako uzupełnienie i w pojedynczych przypadkach może odbiegać od rzeczywistych specyfikacji Państwa pojazdu. W związku z tym należy zawsze przestrzegać wydrukowanej instrukcji obsługi, dostarczonej wraz z pojazdem.",
    ro: "Manualul de utilizare interactiv servește numai ca completare și poate să difere de specificațiile efective ale autovehiculului dumneavoastră în cazuri individuale. De aceea, respectați întotdeauna manualul de utilizare tipărit, care a fost livrat împreună cu autovehiculul dumneavoastră.",
    ru: 'Интерактивное руководство по эксплуатации является лишь дополнением и в отдельных случаях может отличаться от фактических спецификаций Вашего автомобиля. Поэтому всегда соблюдайте печатное "Руководство по эксплуатации", которое поставлялось вместе с Вашим автомобилем.',
    sr: "Interaktivno uputstvo za upotrebu služi samo kao dodatak i u pojedinačnim slučajevima može da odstupa od zaista važećih specifikacija vašeg vozila. Uvek obraćajte pažnju na štampano uputstvo za rad koje je isporučeno sa vašim vozilom.",
    sl: "Interaktivna navodila za uporabo so samo dopolnilo in lahko v posameznih primerih odstopajo od dejanskih specifikacij vašega vozila. Zato vedno upoštevajte tiskana navodila za uporabo, ki so bila dobavljena skupaj z vozilom.",
    sv: "Den interaktiva instruktionsboken är endast ett komplement och kan i vissa fall avvika från de faktiska specifikationerna för din bil. Observera därför alltid den tryckta instruktionsbok som medföljer bilen.",
    tr: "İnteraktif işletme talimatı sadece tamamlayıcı niteliktedir ve münferit durumlarda aracınızın gerçek teknik özelliklerinden farklılık gösterebilir. Bu nedenle lütfen her zaman aracınızla birlikte verilen basılı işletme talimatını dikkate alın.",
    uk: "Інтерактивне керівництво з експлуатації є лише доповненням і в окремих випадках може відрізнятися від фактичних характеристик вашого автомобіля. Тому завжди дотримуйтесь друкованого посібника з експлуатації, що постачається разом з автомобілем.",
    bs: "Interaktivno uputstvo za upotrebu služi samo kao dodatak i u pojedinačnim slučajevima može da odstupa od zaista važećih specifikacija vašeg vozila. Uvek obraćajte pažnju na štampano uputstvo za rad koje je isporučeno sa vašim vozilom.",
    zh: "交互式用户手册仅作为补充，在个别情况下可能与您车辆的实际规格存在偏差。 因此，请务必遵守随车提供的印刷版用户手册。",
    en: "The Interactive Owner’s Manual serves only as a supplement and may deviate from the actual specifications of your vehicle in individual cases. Therefore, always observe the printed Operator’s Manual that was supplied with your vehicle.",
    fr: "La notice d’utilisation interactive ne constitue qu’un complément et peut, dans certains cas, différer des spécifications réelles de votre véhicule. Par conséquent, consultez toujours la version imprimée de la notice d’utilisation qui a été livrée avec votre véhicule.",
    lv: "Interaktīvā lietošanas instrukcija ir tikai papildinājums un atsevišķos gadījumos var atšķirties no jūsu automobiļa faktiskajām specifikācijām. Tādēļ vienmēr ņemiet vērā drukāto lietošanas instrukciju, kas piegādāta kopā ar automobili.",
    pt: "O Manual do Condutor interativo serve apenas de complemento e, em casos individuais, pode divergir das especificações reais do seu veículo. Por essa razão, respeite sempre o Manual do Condutor impresso, que foi fornecido juntamente com o seu veículo.",
    sk: "Interaktívny návod na obsluhu slúži len ako doplnok a v jednotlivých prípadoch sa môže líšiť od skutočných špecifikácií vášho vozidla. Preto vždy dodržiavajte vytlačený návod na obsluhu, ktorý bol dodaný spolu s vozidlom.",
    es: "El manual de instrucciones interactivo sirve únicamente como complemento y puede diferir de las especificaciones reales de su vehículo en casos individuales. Por ello, le rogamos que tenga siempre en cuenta el manual de instrucciones impreso que se entrega con su vehículo.",
}