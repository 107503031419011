import axios from "axios";

export default axios.create({

  //DAL - "http://localhost:7000/daimler"
  //Retriver - "http://localhost:9000"
  //Staging - "https://digital-manuals.tsac-staging.daimlertruck.com"
  //Production - "https://manuals.daimlertruck.com"

  baseURL: "https://manuals.daimlertruck.com",

  headers: {
    "Content-type": "application/json"
  }
});
