export const tableData = [
    {
      frequency : `Frequenzbänder [Hz] \n frequency bands [Hz]`,
      power: `max. Ausgangsleistung [W] \n max. output power [W]`,
      description:
        "Antennenstellung am Fahrzeug, besondere Installations- und Gebrauchsvoraussetzungen antenna position at vehicle, specific conditions for installation and/or use",
    },
    {
      frequency: "12cm-Band",
      power: "10W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "15cm-Band",
      power: "10W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "25cm-Band",
      power: "10W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "2m-Band",
      power: "50W",
      description:
        "Fahrzeugdach Fahrerseite hinter B-Säule / Vehicle roof driver side behind B-pillar",
    },
    {
      frequency: "30cm-Band",
      power: "10W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "4m-Band",
      power: "20W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "70cm-Band",
      power: "35W",
      description: "Fahrzeugdach / carroof",
    },
    {
      frequency: "KW (<50 MHz)",
      power: "100W",
      description: "Fahrzeugdach / carroof",
    },
  ];