export const hmiErrorTranslations = {
  sq: "Something went wrong. Operating instructions are currently not available for your Vehicle. Please try again later.",
  ar: "حدث خطأ. دليل التشغيل غير متاح حالياً لسيارتك. يُرجى إعادة المحاولة لاحقًا.",
  bg: "За съжаление възникна грешка. В момента няма налично ръководство за експлоатация за Вашия автомобил. Моля, опитайте отново по-късно.",
  hr: "Nešto je pošlo krivo. Upute za rad trenutačno nisu dostupne za Vaše vozilo. Pokušajte ponovno kasnije.",
  cs: "Něco se pokazilo. Pro Vaše vozidlo není nyní k dispozici žádný návod k obsluze. Opakujte akci později.",
  da: "Der gik noget galt. Der findes p.t. ingen instruktionsbog til din bil. Prøv venligst igen senere.",
  nl: "Er is iets misgegaan. Er is momenteel geen handleiding voor uw voertuig beschikbaar. Probeert u het later nog eens.",
  et: "Midagi läks valesti. Teie sõiduki kasutusjuhendit ei ole hetkel saadaval. Proovige hiljem uuesti.",
  fi: "Jotain meni pieleen. Autoosi ei ole tällä hetkellä saatavilla käyttöohjekirjaa. Kokeile myöhemmin uudelleen.",
  de: "Ein Problem ist aufgetreten. Für Ihr Fahrzeug sind derzeit keine Betriebsanleitungen verfügbar. Bitte versuchen Sie es später erneut.",
  el: "Παρουσιάστηκε κάποιο πρόβλημα. Προς το παρόν δεν διατίθενται Οδηγίες Χρήσης για το όχημά σας. Δοκιμάστε ξανά αργότερα.",
  iw: "משהו השתבש. הוראות הפעלה אינן זמינות כרגע עבור הרכב שלך. בבקשה נסה שוב מאוחר יותר.",
  hu: "Valamilyen hiba történt. Gépkocsijához jelenleg nem áll rendelkezésre kezelési útmutató. Próbáld meg később.",
  it: "Qualcosa non ha funzionato. Le Istruzioni d'uso non sono attualmente disponibili per la tua vettura. Ti preghiamo di riprovare.",
  ja: "エラーが発生しました。 現在、この車両の取扱説明書はご利用いただけません。 後でもう一度試してください。",
  ko: "문제가 발생했습니다. 현재 해당 차량에 대한 사용 설명서를 사용할 수 없습니다. 나중에 다시 시도하십시오.",
  lt: "Kažkas nepavyko. Šiuo metu Jūsų transporto priemonei naudojimo instrukcijos nėra. Pabandykite vėliau.",
  mk: "Nešto je pošlo ne tako. Uputstvo za rad trenutno nije dostupno za vaše vozilo. Pokušajte ponovo kasnije.",
  no: "Det oppsto en feil. Det finnes for øyeblikket ingen instruksjonsbok for bilen din. Vennligst forsøk på nytt.",
  pl: "Wystąpił problem. Dla Twojego pojazdu nie ma obecnie instrukcji obsługi. Proszę spróbować później.",
  ro: "Ceva nu a mers bine. Manualul de utilizare nu este disponibil momentan pentru autovehiculul dumneavoastră. Reîncercați mai târziu.",
  ru: "Возникла неполадка. Руководство по эксплуатации для Вашего автомобиля в настоящий момент недоступно. Повторите попытку позже.",
  sr: "Nešto je pošlo ne tako. Uputstvo za rad trenutno nije dostupno za vaše vozilo. Pokušajte ponovo kasnije.",
  sl: "Prišlo je do napake. Navodila za uporabo za vaše vozilo trenutno niso na voljo. Prosimo, poskusite pozneje.",
  sv: "Något gick snett. Det finns för närvarande ingen instruktionsbok för din bil. Var god försök igen senare.",
  tr: "Bir şeyler yanlış gitti Aracınız için şu an bir işletme talimatı mevcut değil. Lütfen tekrar deneyiniz.",
  uk: "Виникла помилка. Для вашого автомобіля наразі немає посібника з експлуатації. Будь ласка, спробуйте пізніше.",
  bs: "Nešto je pošlo ne tako. Uputstvo za rad trenutno nije dostupno za vaše vozilo. Pokušajte ponovo kasnije.",
  zh: "很遗憾，出现了一个错误。 您的车辆目前没有用户手册。 请稍后再试一次。",
  en: "Something went wrong. Operating instructions are currently not available for your Vehicle. Please try again later.",
  fr: "Une erreur est survenue. Aucune notice d’utilisation n’est actuellement disponible pour votre véhicule. Réessayez plus tard.",
  lv: "Diemžēl radās problēma. Pašlaik jūsu automobilim nav pieejama lietošanas instrukcija. Lūdzu, mēģiniet vēlāk vēlreiz.",
  pt: "Ocorreu um problema. De momento, não estão disponíveis Manuais do Condutor para o seu veículo. Tente novamente mais tarde.",
  sk: "Niečo nie je v poriadku. Pre vaše vozidlo momentálne nie je k dispozícii žiadny návod na obsluhu. Skúste to znova neskôr.",
  es: "Se ha producido un error. Las Instrucciones de servicio no están disponibles actualmente para su vehículo. Inténtelo de nuevo más tarde.",
};
