import "./ManualContent.css";
import "./css/common.css";
// import "./css/common.min.css"
import "./sass/screen.scss";
// import "./rtl.css";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AppContext } from "../../State";
import { IFrame } from "./iframe";
import apiClient from "../../services/http-common";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import Collapsible from "react-collapsible";
import logger from "../../Utils/logger";
interface ContainerProps {
  printText: string;
}

type MenuContent = {
  id: number,
  contentName: string,
  content: string,
  createdAt: string,
  updatedAt: string,
  VechileMenuMasterId: number,
};

const ManualContent = forwardRef((props, ref) => {
  const { state, dispatch } = useContext(AppContext);

  const history = useHistory();

  const initialMenuContent: MenuContent = {
    id: 0,
    contentName: "",
    content: "",
    createdAt: "",
    updatedAt: "",
    VechileMenuMasterId: 0,
  };

  var styles = {
    body: {
      fontFamily: "roboto",
      fontSize: 13,
      lineHeight: 1.4,
      color: "#5e5e5e",
      backgroundColor: "#edecec",
      overflow: "auto",
    },
  };

  useEffect(() => {
    if (state.isLeftMenuClicked !== 0) {
      getRightManualContents();
    }
  }, [state.isLeftMenuClicked || state.selectedTileID]);

  useEffect(() => {
    getRightManualContents();
  }, [state.selectedTileID]);

  useEffect(() => {
    if (!state.isChildItemPresent) {
      getRightManualContents();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    printContent() {
      printIframe("manual-iframe");
    },
  }));

  const [manualContentList, setManualContentList] =
    useState(initialMenuContent);

  const getAllManualContents = async (id: number) => {
    dispatch({
      type: "setIsManualLoaderActive",
      payload: {
        isManualLoaderActive: true,
      },
    });
    try {
      // const result: any = await apiClient.get(`/getMenuContent/${id}`);

      let result: any;
      var translationLangCode = localStorage.getItem("langCode");
      if (state.isVINAvailable || state.vehicleVIN) {
        result = await apiClient.get(
          `/retrivergetMenuContent/${id}/${
            state.vehicleInfoBasedOnVIN?.vehicleName
          }/${state.vehicleInfoBasedOnVIN?.vehicleModel}/${localStorage.getItem(
            "hmiSystemLanguage"
          )}/${localStorage.getItem(
            "hmiSystemLanguage"
          ) === "de_DE" ? "de_DE" : ""}`
        );
      } else {
        // debugger;
        result = await apiClient.get(
          `/retrivergetMenuContent/${id}/${state.selectedVehicleName}/${
            state.selectedVehicleModel
          }/${state.selectedManualLanguageCode}/${
            translationLangCode === "en_GB" ? "" : translationLangCode
          }`
        );
      }

      setManualContentList(result.data[0]);
      logger.info(`Right menu content data for id ${id} -- ${result.data}`);
      dispatch({
        type: "setSelectedItemTitle",
        payload: {
          selectedLeftMenuTitle: result.data[0].contentName,
        },
      });
      dispatch({
        type: "setIsManualLoaderActive",
        payload: {
          isManualLoaderActive: false,
        },
      });
    } catch (err) {
      logger.info(`Right manual content API error ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      dispatch({
        type: "setIsManualLoaderActive",
        payload: {
          isManualLoaderActive: false,
        },
      });
      history.push("/truckownermanuals/error");
    }
  };

  const getSearchContent = async (id: number) => {
    try {
      const result: any = await apiClient.get(
        `/retrivergetSearchContent/${id}`
      );
      setManualContentList(result?.data);
      logger.info(`Right menu content data ${result?.data?.content}`);
      dispatch({
        type: "setSelectedItemTitle",
        payload: {
          selectedLeftMenuTitle: result?.data?.contentName,
        },
      });
      setSearchedParentsPath(result?.data?.path);
    } catch (err) {
      logger.info(`Right manual content API error ${err}`);
    }
  };

  function setSearchedParentsPath(parents: any) {
    var levelHierarchy = [""];
    for (var i = 0; i < parents.length; i++) {
      levelHierarchy.push(parents[parents.length - (i + 1)]);
    }
    levelHierarchy.shift();
    logger.info("Search path", levelHierarchy);
    dispatch({
      type: "setSelectedSearchedParents",
      payload: {
        selectedSearchedParents: levelHierarchy,
      },
    });
    localStorage.setItem(
      "selectedSearchedParents",
      JSON.stringify(levelHierarchy)
    );
  }

  function getRightManualContents() {
    try {
      if (state.selectedLeftMenuID !== 0) {
        if (!state.isChildItemPresent) {
          if (state.isNavigatedFromSearch) {
            getSearchContent(state.selectedSearchID);
          } else {
            getAllManualContents(state.selectedTileID);
          }
        } else {
          getAllManualContents(state.selectedLeftMenuID);
        }
      }
    } catch (err) {
      logger.info(err);
    }
  }

  const printIframe = (id) => {
    const iframe = document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    var iframeCW = iframe.contentWindow || iframe.contentDocument;

    if (iframeCW.document) iframeCW = iframeCW.document;
    logger.info(iframeCW);
    iframe.focus();
    iframeWindow.print();
    return false;
  };

  window.toggle_visibility = (titleID, contentID, boxType) => {
    var content = document.getElementById(contentID);
    if (content.className == "cos-box-content") {
      content.className = "cos-box-content cos-box-hidden";
    } else {
      content.className = "cos-box-content";
    }
    var title = document.getElementById(titleID);
    if (boxType == "box-type-env") {
      if (
        title.className == "cos-box-title cos-box-environment-title expanded"
      ) {
        title.className = "cos-box-title cos-box-environment-title";
      } else {
        title.className = "cos-box-title cos-box-environment-title expanded";
      }
    } else if (boxType == "box-type-warning") {
      if (title.className == "cos-box-title cos-box-warning-title expanded") {
        title.className = "cos-box-title cos-box-warning-title";
      } else {
        title.className = "cos-box-title cos-box-warning-title expanded";
      }
    } else if (boxType == "box-type-damage") {
      if (title.className == "cos-box-title cos-box-damage-title") {
        title.className = "cos-box-title cos-box-damage-title expanded";
      } else {
        title.className = "cos-box-title cos-box-damage-title";
      }}
      else if (boxType == "box-type-note") {
        if (title.className == "cos-box-title cos-box-note-title") {
          title.className = "cos-box-title cos-box-note-title expanded";
        } else {
          title.className = "cos-box-title cos-box-note-title";
        }
    }
  };

  window.getMoreContent = (contentName) => {
    dispatch({
      type: "setSelectedMoreContent",
      payload: {
        selectedMoreContent: contentName,
      },
    });
    localStorage.setItem("selectedMoreContent", contentName);
    history.push("/truckownermanuals/more-content");
  };

  var testString = `<div class="cos-box" onclick='toggle_visibility("6197", "13798", "box-type-damage");'>
  <div class="cos-box-title cos-box-damage-title expanded" data-content="13798" id="6197"> <span class="cos-box-title-heading">NOTE </span> <span class="cox-box-title-text">Damage to the vehicle due to driving too fast and due to impacts to the vehicle underbody or suspension components</span> </div>
  <div class="cos-box-content" id="13798">
    <div class="cos-box-source cos-box-damage-source">
      <div class="cos-ul-title">In the following situations, in particular, there is a risk of damage to the vehicle:</div>
      <ul class="cos-ul">
        <li class="cos-li">the vehicle becomes grounded, e.g. on a high kerb or an unpaved road</li>
        <li class="cos-li">the vehicle is driven too fast over an obstacle, e.g. a kerb, speed bump or pothole</li>
        <li class="cos-li">a heavy object strikes the underbody or suspension components</li>
      </ul>
    </div>
    <div class="cos-box-defense cos-box-damage-defense">
      <div class="cos-step">
        <div class="cos-action">Have the vehicle checked and repaired immediately at a qualified specialist workshop.</div>
      </div>
      <div class="cos-step">
        <div class="cos-action">If driving safety is impaired while continuing your journey, pull over and stop the vehicle immediately, while paying attention to road and traffic conditions, and contact a qualified specialist workshop.</div>
      </div>
    </div>
  </div>
</div>`;

  var testStringTwo = `<div class="cos-box" onClick='toggle_visibility("1", "2", "box-type-env");'>
  <div class="cos-box-title cos-box-environment-title expanded" data-content="idgc7333" id="1">
    <span class="cos-box-title-heading">ENVIRONMENTAL NOTE </span>
    <span class="cox-box-title-text">Environmental damage due to operating conditions and personal driving style </span>
  </div>
  <div class="cos-box-content" id="2">
    <div class="cos-box-source cos-box-environment-source">
      <p class="cos-p" id="ID_a84d618f035ba98cac190d2b237b0552-dbef2a1003574a21ac190d2b6ae6ddf8-en-GB">The pollutant emission of the vehicle is directly related to the way you operate the vehicle. </p>
    </div><div class="cos-box-defense cos-box-environment-defense"><p class="cos-p" id="ID_ecbfd36a3b85b7ca354ae3656b486058-dbef2a1003574a21ac190d2b6ae6ddf8-en-GB">Operate your vehicle in an environmentally responsible manner to help protect the environment. Please observe the following recommendations on operating conditions and personal driving style. </p>
      <p class="cos-p" id="ID_e81008a33b85b7ca354ae3656c23c896-dbef2a1003574a21ac190d2b6ae6ddf8-en-GB"><b>Operating conditions:</b></p><div class="cos-step"><div class="cos-action">Avoid driving short distances. </div></div><div class="cos-step"><div class="cos-action">Make sure that the tyre pressure is correct. </div>
      </div><div class="cos-step"><div class="cos-action">Do not carry any unnecessary weight. </div></div><div class="cos-step"><div class="cos-action">Adhere to the service intervals. </div><div class="cos-result"><p class="cos-p" id="ID_e199ff0d3b85b7d9354ae36521462751-dbef2a1003574a21ac190d2b6ae6ddf8-en-GB">A regularly serviced vehicle will contribute to environmental protection. </p></div></div><div class="cos-step"><div class="cos-action">Always have maintenance work carried out at a qualified specialist workshop. </div>
      </div><p class="cos-p" id="ID_7d26fbed3b85b7d9354ae36504bf47e8-dbef2a1003574a21ac190d2b6ae6ddf8-en-GB"><b>Personal driving style:</b></p><div class="cos-step"><div class="cos-action">Do not depress the accelerator pedal when starting the engine. </div></div><div class="cos-step"><div class="cos-action">Do not warm up the vehicle while stationary. </div></div><div class="cos-step"><div class="cos-action">Drive carefully and maintain a suitable distance from the vehicle in front. </div></div><div class="cos-step"><div class="cos-action">Avoid frequent, sudden acceleration and braking. </div></div><div class="cos-step">
        <div class="cos-action">Change gear in good time and use each gear only up to of its maximum engine speed. </div></div><div class="cos-step"><div class="cos-action">Switch the vehicle off during periods spent in traffic-related delays. </div></div><div class="cos-step">
        <div class="cos-action">Drive in a fuel-efficient manner. For fuel-efficient driving, drive in the green rpm range. </div></div></div></div>
</div>`;

  return (
    <div className="manual-parent-div" ref={ref}>
      <IFrame id="manual-iframe" className="iframe-content">
        <link href="css/ManualContent.css" rel="stylesheet" type="text/css" />
        <h1 className="manual-h1-title">{state.selectedLeftMenuTitle}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: manualContentList?.content }}
          className="manual-content-main-container"
          id="htmlContent"
        ></div>
      </IFrame>
      <div
        dangerouslySetInnerHTML={{ __html: manualContentList?.content }}
        // className="manual-content-main-container"
        className={
          state.isVINAvailable
            ? "manual-content-main-container-hmi"
            : "manual-content-main-container"
        }
        id="htmlContent"
      ></div>
    </div>
  );
});

export default ManualContent;
