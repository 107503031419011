import { useContext, useEffect, useRef, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home/Home";
import Manual from "./pages/Manual/Manual";
import TiledItems from "./pages/TiledItems/TiledItems";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Conformity from "./pages/ConformityPage/Conformity";
import MoreManualContent from "./pages/MoreManualContent/MoreManualContent";
import { vehicleVINDetails, browserLanugages } from "./Utils/VehicleVINDetails";

import { AppContext } from "./State";

import { Device } from "@capacitor/device";
import { log } from "util";

// import { useWinstonLogger } from "winston-logger";
import logger from "./Utils/logger";
setupIonicReact();

// const App: React.FC = () => (
//   <IonApp>
//     <IonReactRouter>
//       <IonRouterOutlet>
//         <Route exact path="/truckownermanuals/conformity">
//           <Conformity />
//         </Route>
//         <Route exact path="/truckownermanuals/more-content">
//           <MoreManualContent />
//         </Route>
//         <Route exact path="/truckownermanuals/manual">
//           <Manual />
//         </Route>
//         <Route exact path="/truckownermanuals/tiled-items">
//           <TiledItems />
//         </Route>
//         <Route exact path="/truckownermanuals/error">
//           <ErrorPage />
//         </Route>
//         <Route exact path="/truckownermanuals/home">
//           <Home />
//         </Route>
//         {/* <Route exact path="/">
//           <Redirect to="/truckownermanuals/home" />{" "}
//         </Route> */}
//         <Route exact path="/truckownermanuals">
//           <Redirect to="/truckownermanuals/home" />{" "}
//         </Route>
//       </IonRouterOutlet>
//     </IonReactRouter>
//   </IonApp>
// );

const App: React.FC<any> = ({ h }, ...props) => {
  // logger.info("PROPS", hmi);

  const history = useHistory();
  const [VINBool, setVINBool] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  let VINNumberFromVehicle = "00";
  // const logger = useWinstonLogger();
  // logger.info("your awesome information log!");
  // to get vehicle Information from provided VIN number

  let vehicleDetails;
  const getVehicleInformation = () => {
    console.log("vehicleVINDetails", vehicleVINDetails);
    Object.keys(vehicleVINDetails).map((vehicle) => {
      if (vehicle == VINNumberFromVehicle) {
        vehicleDetails = vehicleVINDetails[vehicle];
      }
    });
    // logger.info(browserLanugages[navigator.language]);
    vehicleDetails = {
      ...vehicleDetails,
      browserLanguage: browserLanugages[navigator.language] || "en_GB",
    };
    console.log(`vechileDetails`, vehicleDetails);
    var mobileWidth = window.innerWidth;

    if (
      // h?.operatingSystem == "windows" ||      // uncomment for HMI Web Version local setup
      (h?.operatingSystem == "android" && mobileWidth > 550) ||
      h?.model.includes("Proteus")
    ) {
      //update VIN boolean or flag
      dispatch({
        type: "setisVINAvailable",
        payload: {
          isVINAvailable: vehicleDetails?.vehicleVIN ? true : false,
        },
      });
      // storing of only VIN number
      // dispatch({
      //   type: "setVehicleVIN",
      //   payload: {
      //     vehicleVIN: vehicleDetails?.vehicleVIN
      //       ? vehicleDetails?.vehicleVIN
      //       : "",
      //   },
      // });

      // store complete vechileInformation based on VIN number provided

      console.log("vehicleDetails", vehicleDetails);
      // dispatch({
      //   type: "setVehicleInfoBasedOnVIN",
      //   payload: {
      //     vehicleInfoBasedOnVIN: vehicleDetails ? vehicleDetails : {},
      //   },
      // });
    } else {
      //update VIN boolean or flag
      dispatch({
        type: "setisVINAvailable",
        payload: {
          isVINAvailable: false,
        },
      });
    }
  };
  useEffect(() => {
    getVehicleInformation();
  }, []);

  // useEffect(() => {
  //   logDeviceInfo();
  // }, []);

  // logger.info("APPFILEEEE", state);
  // logger.info("Hi", h);
  // logger.info(`HMI`, { h });
  // console.log("HMI", h);
  const DeviceName = ["Proteus arm64", "Windows NT 10.0", "Nexus 5", "Pixel 5"];
  // console.log("DeviceName", DeviceName[h]);
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/truckownermanuals/conformity">
            <Conformity />
          </Route>
          <Route exact path="/truckownermanuals/more-content">
            <MoreManualContent />
          </Route>
          <Route exact path="/truckownermanuals/manual">
            <Manual />
          </Route>
          <Route exact path="/truckownermanuals/tiled-items">
            <TiledItems />
          </Route>
          {/* {h == "Proteus arm64" ||
            (h == "Windows NT 10.0" && (
              <Route
                exact
                path="/truckownermanuals/tiled-items/:lang"
                render={(props) => <TiledItems {...props} />}
              />
            ))} */}
          {/* {DeviceName.includes(h) && (
            <Route exact path="/truckownermanuals/tiled-items/:lang">
              <TiledItems />
            </Route>
          )} */}
          {/* {DeviceName.includes(h) && (
            <Route
              exact
              path="/truckownermanuals/tiled-items/:lang"
              render={(props) => <TiledItems {...props} />}
            />
          )} */}
          {/* Lastest Implementation - commenting the "h"*/}
          {/* {h && ( */}
          {/* <Route
            exact
            path="/truckownermanuals/tiled-items/:lang/:vehline/:drivesys"
            render={(props) => <TiledItems {...props} />}
          /> */}
          {/* )} */}
          <Route exact path="/truckownermanuals/error">
            <ErrorPage />
          </Route>
          <Route exact path="/truckownermanuals/home">
            <Home />
          </Route>
          {/* <Route exact path="/">
          <Redirect to="/truckownermanuals/home" />{" "}
        </Route> */}
          {/* only for web and mobile implementation - old implementation before HMI */}
          {/* <Route exact path="/truckownermanuals">
            <Redirect to="/truckownermanuals/home" />{" "}
          </Route> */}
          {/* Redirect directly if VIN number */}
          {/* {state.isVINAvailable ? (
            <Route exact path="/truckownermanuals">
              <Redirect to="/truckownermanuals/tiled-items" />{" "}
            </Route>
          ) : (
            <Route exact path="/truckownermanuals">
              <Redirect to="/truckownermanuals/home" />{" "}
            </Route>
          )} */}
          {h?.model == "Pixel 5" ||
          // h?.model == "Windows NT 10.0" ||       // uncomment for HMI Web Version local setup
          h?.model == "SM-G955U" ||
          h?.model == "Proteus arm64" ? (
            <Route
              exact
              path="/truckownermanuals/tiled-items/:lang/:vehline/:drivesys"
              render={(props) => <TiledItems {...props} />}
            />
          ) : (
            //              older implementation
            // <Route exact path="/truckownermanuals">
            // <Redirect to="/truckownermanuals/tiled-items" />{" "}
            // </Route>
            // <Route
            //   exact
            //   path="/truckownermanuals/tiled-items/:id/:test/:lang"
            //   render={(props) => <TiledItems {...props} />}
            // />
            <Route exact path="/truckownermanuals">
              <Redirect to="/truckownermanuals/home" />{" "}
            </Route>
          )}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
